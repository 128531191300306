/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect } from 'react';

import {
  AppraisalInformationData,
  ParkingHouseData,
} from '../../../../../../api/workOrders/types';
import { StyledTableCellBody, TableTextField } from '../../../styles';
import { NumericTextfield } from '../styles';

interface TableHouseParkingProps {
  inputs: AppraisalInformationData;
  setInputs: React.Dispatch<React.SetStateAction<AppraisalInformationData>>;
  readOnly: boolean;
}

export function TableHouseParking({
  inputs,
  setInputs,
  readOnly,
}: TableHouseParkingProps): JSX.Element {
  const parking = inputs.parking_information as ParkingHouseData;

  const handleParkingInformation = (
    field: keyof ParkingHouseData,
    value: string | number
  ): void => {
    setInputs((prev) => ({
      ...prev,
      parking_information: {
        ...prev.parking_information,
        [field]: value ?? 0,
      },
    }));
  };

  useEffect(() => {
    handleParkingInformation(
      'total',
      Number(parking.covered + parking.not_covered)
    );
  }, [parking.covered, parking.not_covered]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
          >
            <TableCell align="center" colSpan={3}>
              total de vagas vinculadas à matrícula do imóvel
            </TableCell>
          </TableRow>
          <TableRow
            sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}
          >
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              cobertas
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              descobertas
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              total
            </TableCell>
          </TableRow>
          <TableRow>
            <StyledTableCellBody>
              <NumericTextfield
                value={parking.covered}
                allowNegative={false}
                decimalScale={0}
                disabled={readOnly}
                onChange={(e) =>
                  handleParkingInformation('covered', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <NumericTextfield
                value={parking.not_covered}
                disabled={readOnly}
                allowNegative={false}
                decimalScale={0}
                onChange={(e) =>
                  handleParkingInformation(
                    'not_covered',
                    Number(e.target.value)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TableTextField type="number" value={parking.total} disabled />
            </StyledTableCellBody>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
