import { ReportFunction, ReportGoal } from '../api/enumerations';
import { SelectProps, selectReportFunction } from '../constants/selectOptions';

export const functionForMarketBuy = {
  SELECT: ReportFunction.SELECT,
  ACQUISITION: ReportFunction.ACQUISITION,
  DONATION: ReportFunction.DONATION,
  ALIENATION: ReportFunction.ALIENATION,
  GIVING_PAYMENT: ReportFunction.GIVING_PAYMENT,
  EXCHANGE: ReportFunction.EXCHANGE,
  ACCOUNTING: ReportFunction.ACCOUNTING,
  INSURANCE: ReportFunction.INSURANCE,
  ADJUDICATION: ReportFunction.ADJUDICATION,
  REPARATION: ReportFunction.REPARATION,
  JUDICIAL: ReportFunction.JUDICIAL,
};

export const functionForMarketTenancy = {
  SELECT: ReportFunction.SELECT,
  TENANCY: ReportFunction.TENANCY,
  LEASE: ReportFunction.LEASE,
  ACCOUNTING: ReportFunction.ACCOUNTING,
  JUDICIAL: ReportFunction.JUDICIAL,
};

export const functionForLiquidation = {
  SELECT: ReportFunction.SELECT,
  ACQUISITION: ReportFunction.ACQUISITION,
  ALIENATION: ReportFunction.ALIENATION,
  EXCHANGE: ReportFunction.EXCHANGE,
  ACCOUNTING: ReportFunction.ACCOUNTING,
  AUCTION: ReportFunction.AUCTION,
  ADJUDICATION: ReportFunction.PRE_AUCTION,
};

export const handleReportFunction = (goal: ReportGoal): SelectProps[] => {
  switch (goal) {
    case ReportGoal.MARKET_BUY:
      return selectReportFunction(functionForMarketBuy);
    case ReportGoal.MARKET_TENANCY:
      return selectReportFunction(functionForMarketTenancy);
    case ReportGoal.LIQUIDATION:
      return selectReportFunction(functionForLiquidation);
    default:
      return selectReportFunction();
  }
};
