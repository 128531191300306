/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { CustomTextField } from '../../../components/CustomInput';
import {
  AttachFileButon,
  AttachmentsBox,
  CancelButton,
  CloseButton,
  DialogTitle,
  FileList,
  InputBox,
  List,
  StyledDialog,
  StyledIcon,
  SubmitBox,
  SubmitButton,
  TopBox,
  UploadBox,
} from '../../../components/Dialog/AttachFilesDialog/styles';
import { RoundedButton } from '../../../components/UI/Button';
import { WhiteCircularProgress } from '../../../components/UI/Typography';
import {
  IconAttachFileMS,
  IconCloseMS,
  IconDownloadMS,
  IconEditMS,
  IconUploadMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/revision';
import useGeneral from '../../../hooks/useGeneral';
import useSnackbar from '../../../hooks/useSnackbar';

interface UploadFilesProps {
  fileType: 'report' | 'memorial';
  file: string | null;
  submitCallback: (file: File, reset: () => void) => Promise<void>;
  loading: boolean;
  readOnly: boolean;
}

export function UploadFiles({
  fileType,
  file,
  submitCallback,
  loading,
  readOnly,
}: UploadFilesProps): JSX.Element {
  const [showInput, setShowInput] = useState(false);
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { osId } = useGeneral();
  const { handleSnackbar } = useSnackbar();
  const { handleClose, open, handleOpen } = useGeneral();

  const isReportFile = fileType === 'report';

  const reset = (): void => {
    setFileName('');
    setSelectedFile(null);
    setShowInput(false);
    handleClose();
  };

  const downloadFile = (link: string): void => {
    const linkSource = link;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.click();
  };

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleIcon = (): JSX.Element => {
    if (readOnly) {
      return IconDownloadMS;
    }
    if (file) {
      return IconEditMS;
    }
    return IconUploadMS;
  };

  const handleTitle = (): string => {
    const baseText = isReportFile ? Constants.pdf : Constants.memorial;
    let actionText = '';

    if (!readOnly) {
      actionText = file ? Constants.edit : Constants.add;
    }
    return `${actionText} ${baseText}`;
  };

  const handleSubmit = useCallback(async () => {
    if (!selectedFile || !osId) {
      handleSnackbar('Selecione um arquivo para enviar', true);
      return;
    }
    submitCallback(selectedFile, reset);
  }, [osId, selectedFile]);

  useEffect(() => {
    if (file !== null) {
      setShowInput(false);
    } else {
      setShowInput(true);
    }
  }, [file]);

  return (
    <>
      <RoundedButton
        disabled={readOnly && !file}
        onClick={readOnly ? () => file && downloadFile(file) : handleOpen}
      >
        {handleIcon()}
        {handleTitle()}
      </RoundedButton>
      <StyledDialog open={open} onClose={reset}>
        <>
          <TopBox>
            <DialogTitle>
              {file ? IconEditMS : IconUploadMS}
              {handleTitle()}
            </DialogTitle>
            <CloseButton onClick={reset}>{IconCloseMS}</CloseButton>
          </TopBox>
          {showInput && (
            <UploadBox>
              <InputBox marginTop="0px !important">
                <CustomTextField
                  disabled
                  id="file-name"
                  label="arquivo"
                  value={fileName}
                  setValue={setFileName}
                />
                <label htmlFor="uploadAttachFiles">
                  <input
                    accept={
                      isReportFile
                        ? 'application/pdf'
                        : 'image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                    id="uploadAttachFiles"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                  />
                  <AttachFileButon component="span">
                    selecionar arquivo
                  </AttachFileButon>
                </label>
              </InputBox>
              <SubmitBox>
                <CancelButton onClick={reset}>cancelar</CancelButton>
                <SubmitButton disabled={loading} onClick={handleSubmit}>
                  {loading ? <WhiteCircularProgress size={22} /> : 'adicionar'}
                </SubmitButton>
              </SubmitBox>
            </UploadBox>
          )}
          {file && (
            <AttachmentsBox marginTop="21px">
              <FileList>
                <List>
                  <Box>
                    {IconAttachFileMS}
                    {isReportFile ? 'Relatório PDF' : 'Memorial de cálculo'}
                  </Box>
                  <Box>
                    <StyledIcon onClick={() => setShowInput(!showInput)}>
                      {IconEditMS}
                    </StyledIcon>
                    <StyledIcon
                      disabled={!file}
                      onClick={() => downloadFile(file)}
                    >
                      {IconDownloadMS}
                    </StyledIcon>
                  </Box>
                </List>
              </FileList>
            </AttachmentsBox>
          )}
        </>
      </StyledDialog>
    </>
  );
}
