/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';

import { workOrderAPI } from '../../../../api';
import { StatusCode } from '../../../../api/enumerations';
import { IconStickyNoteMS } from '../../../../constants/icons';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import useSnackbar from '../../../../hooks/useSnackbar';
import { MultilineTextField } from '../../../CustomInput';
import { ButtonBox } from '../../../UI/Box';
import {
  FilledButton,
  OutlinedButton,
  RoundedButton,
} from '../../../UI/Button';
import { StyledDialog } from '../../../UI/Dialog';
import { DialogTitle, WhiteCircularProgress } from '../../../UI/Typography';
import { DialogContainer, FormBox } from './styles';

interface AddObservationProps {
  osId: number;
  updateObservations: () => void;
}

export function AddObservation({
  osId,
  updateObservations,
}: AddObservationProps): JSX.Element {
  const [observation, setObservation] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const dialog = useGeneral();
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const resetData = (): void => {
    setObservation('');
    dialog.handleClose();
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (observation.length === 0) {
        handleSnackbar('A observação é obrigatória', true);
        return;
      }

      setSubmitLoading(true);

      const data = {
        observation,
      };

      try {
        const response = await workOrderAPI.addNewObservation(osId, data);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('Observação criada com sucesso!', false);
        updateObservations();
        resetData();
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      } finally {
        setSubmitLoading(false);
      }
    },
    [observation, osId]
  );

  return (
    <>
      <ButtonBox marginBottom={2}>
        <RoundedButton width="ms" onClick={dialog.handleOpen}>
          criar observação
        </RoundedButton>
      </ButtonBox>
      <StyledDialog open={dialog.open} onClose={resetData}>
        <DialogContainer>
          <DialogTitle>{IconStickyNoteMS} Observação</DialogTitle>
          <FormBox
            component="form"
            id="create-observation"
            onSubmit={handleSubmit}
          >
            <MultilineTextField
              required
              id="observation"
              placeholder="Escreva aqui uma observação"
              value={observation}
              setValue={setObservation}
            />
            <ButtonBox gap="21px">
              <FilledButton
                width="ms"
                form="create-observation"
                type="submit"
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <WhiteCircularProgress size={22} />
                ) : (
                  'criar observação'
                )}
              </FilledButton>
              <OutlinedButton width="ms" onClick={resetData}>
                cancelar
              </OutlinedButton>
            </ButtonBox>
          </FormBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
