import { checkboxClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import "@mui/material/styles/createPalette";
declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    danger: string;
    warning: string;
  }
}
declare module "@mui/material/styles" {

  interface Components {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            '& .MuiButtonBase-root': {
              width: string;
              height: string;
            };
          };
        };
      };
    };
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            '.Mui-selected:hover': {
              backgroundColor: string;
            };
            ':hover': {
              backgroundColor: string;
            };
          };
          '& .MuiList-root': {
            marginRight: string;
            ':after': {
              height: string;
            };
            '&::-webkit-scrollbar': {
              width: string;
            };
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: string;
              borderRadius: string;
              '&:hover': {
                backgroundColor: string;
              };
            };
          };
        };
      };
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      dark: '#520679',
      main: '#B038FA',
      light: '#F2F2F2',
    },
    secondary: {
      main: '#613354',
      light: '#8D9295'
    },
    common: {
      white: '#FAFCFF',
      black: '#2E383F',
      danger: '#FF334B',
      warning: '#E89710',
    },
  },
  typography: {
    fontFamily: `'Roboto'`,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          width: '280px',
          height: '48px',
          textTransform: 'none',
          backgroundColor: '#B038FA',
          color: '#F5F5F4',
          fontFamily: 'Roboto',
          boxShadow: 'none',
          fontSize: '16px',
          fontWeight: '400',
          '&:hover': {
            backgroundColor: '#B038FA',
            color: '#F5F5F4',
          },
          '&:active': {
            backgroundColor: '#B038FA',
            color: '#F5F5F4',
          },
          '&:disabled': {
            opacity: '40%',
            color: '#F5F5F4',
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            '& .MuiButtonBase-root': {
              width: '140px',
              height: '42px',
            },
          },
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiList-root': {
            marginRight: '10px',
            ':after': {
              height: '20px',
            },
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#B038FA66',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#B038FACC',
              },
            },
          },
          '& .MuiMenuItem-root': {
            '.Mui-selected:hover': {
              backgroundColor: '#520679',
            },
            ':hover': {
              backgroundColor: '#B038FA99',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
            [`&.${checkboxClasses.checked}`]: {
                color: '#613354',
            },
        },
    },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minWidth: '1280px',
          minHeight: '600px',
          height: '100%',
          margin: '0 auto',

          '@media(max-width: 1440px)': {
            fontSize: '95%',
          }
        },
        body: {
          backgroundColor: '#F2F2F2',
          height: '100%',
          fontSize: '16px',
        },
        '#root': {
          height: '100%',
        },
      },
    },
  },
});
