/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, TextField, Tooltip } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import { EvaluationSummaryData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { convertToFloatNumber } from '../../../../helpers';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface EvaluationSummaryProps {
  inputs: EvaluationSummaryData;
  setInputs: React.Dispatch<React.SetStateAction<EvaluationSummaryData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
  handleUpdate?: () => void;
}

export function EvaluationSummary({
  inputs,
  setInputs,
  handleSection,
  readOnly,
  handleUpdate,
}: EvaluationSummaryProps): JSX.Element {
  const handleInputChange = (
    field: keyof EvaluationSummaryData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <NumericFormat
          customInput={TextField}
          id="evaluation-value"
          label="valor atribuído"
          color="secondary"
          prefix="R$ "
          decimalSeparator=","
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator="."
          allowNegative={false}
          inputProps={{ maxLength: 20 }}
          value={inputs.value}
          onChange={(e) => {
            const convertedValue = convertToFloatNumber(e.target.value);
            handleInputChange('value', convertedValue);
          }}
          disabled={readOnly}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': { borderRadius: '16px' },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="o custo unitário básico da construção foi atribuída como?"
        >
          <StyledTextField
            label="o custo unitário básico da construção foi atribuída como?"
            value={inputs.basic_unitary_cost_calculus_reasoning}
            onChange={(e) =>
              handleInputChange(
                'basic_unitary_cost_calculus_reasoning',
                e.target.value
              )
            }
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="grau de fundamentação"
          value={String(inputs.reasoning_level)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('reasoning_level', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="I">I</StyledMenuItem>
          <StyledMenuItem value="II">II</StyledMenuItem>
          <StyledMenuItem value="III">III</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="o BDI utilizado foi?"
          value={String(inputs.bdi_reasoning)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('bdi_reasoning', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="calculado">calculado</StyledMenuItem>
          <StyledMenuItem value="justificado">justificado</StyledMenuItem>
          <StyledMenuItem value="arbitrado">arbitrado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="observações sobre o BDI utilizado"
          value={inputs.bdi_reasoning_observations}
          onChange={(e) =>
            handleInputChange('bdi_reasoning_observations', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="percentual de BDI utilizado"
          value={inputs.bdi_percentage}
          type="number"
          onChange={(e) => handleInputChange('bdi_percentage', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="valor total das benfeitorias depreciadas"
        >
          <Box>
            <NumericFormat
              customInput={TextField}
              id="improvements-value"
              label="valor total das benfeitorias depreciadas"
              color="secondary"
              prefix="R$ "
              decimalSeparator=","
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              allowNegative={false}
              inputProps={{ maxLength: 20 }}
              value={inputs.depreciated_improvements_value}
              onChange={(e) => {
                const convertedValue = convertToFloatNumber(e.target.value);
                handleInputChange(
                  'depreciated_improvements_value',
                  convertedValue
                );
              }}
              disabled={readOnly}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': { borderRadius: '16px' },
              }}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="fator de comercialização"
          value={inputs.commercialization_factor}
          onChange={(e) =>
            handleInputChange('commercialization_factor', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="Resultado da avaliação – Método Evolutivo"
        >
          <Box>
            <NumericFormat
              customInput={TextField}
              id="evaluation-result"
              label="Resultado da avaliação – Método Evolutivo"
              color="secondary"
              prefix="R$ "
              decimalSeparator=","
              decimalScale={2}
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              allowNegative={false}
              inputProps={{ maxLength: 20 }}
              value={inputs.evolutionary_method_evaluation_result}
              onChange={(e) => {
                const convertedValue = convertToFloatNumber(e.target.value);
                handleInputChange(
                  'evolutionary_method_evaluation_result',
                  convertedValue
                );
              }}
              disabled={readOnly}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': { borderRadius: '16px' },
              }}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="incluir observações relevantes"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
