/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { DiagnosisData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface MarketDiagnosisProps {
  inputs: DiagnosisData;
  setInputs: React.Dispatch<React.SetStateAction<DiagnosisData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
  handleUpdate?: () => void;
}

export function MarketDiagnosis({
  inputs,
  setInputs,
  handleSection,
  readOnly,
  handleUpdate,
}: MarketDiagnosisProps): JSX.Element {
  const handleInputChange = (
    field: keyof DiagnosisData,
    value: string | number
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="absorção pelo mercado"
          value={inputs.market_uptake}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) => handleInputChange('market_uptake', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="difícil">difícil</StyledMenuItem>
          <StyledMenuItem value="fácil">fácil</StyledMenuItem>
          <StyledMenuItem value="extremamente fácil">
            extremamente fácil
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="prazo de venda efetivo (meses)"
          type="number"
          value={inputs.effective_sell_deadline_in_months}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange(
              'effective_sell_deadline_in_months',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="público alvo"
          value={inputs.target_audience}
          disabled={readOnly}
          onChange={(e) => handleInputChange('target_audience', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="facilitadores na negociação"
          value={inputs.negatiation_facilitators}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('negatiation_facilitators', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="desempenho do mercado"
          value={inputs.market_perfomance}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('market_perfomance', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="aparentemente esquecido">
            aparentemente esquecido
          </StyledMenuItem>
          <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
          <StyledMenuItem value="aparentemente baixo">
            aparentemente baixo
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="número de ofertas"
          value={inputs.number_of_offers_status_level}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('number_of_offers_status_level', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="aparentemente esquecido">
            aparentemente esquecido
          </StyledMenuItem>
          <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
          <StyledMenuItem value="aparentemente baixo">
            aparentemente baixo
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="liquidez do imóvel"
          value={inputs.liquidity}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) => handleInputChange('liquidity', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="provavelmente alta">
            provavelmente alta
          </StyledMenuItem>
          <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
          <StyledMenuItem value="provavelmente baixa">
            provavelmente baixa
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="observações importantes"
          value={inputs.observations}
          disabled={readOnly}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="informações complementares de unidades autônomas"
          value={inputs.independent_unities_complementary_information}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange(
              'independent_unities_complementary_information',
              e.target.value
            )
          }
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
