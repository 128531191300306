import { convertPropertyType } from '../../../../api/enumerations';
import {
  AnnouncementData,
  HiveRequestData,
} from '../../../../api/hive/licensedCompanies/types';
import { convertDate } from '../../../../helpers';

interface PresentTableHeadValues {
  id: number;
  label: string;
  width?: string;
}

interface PresentDataValues {
  id: number;
  value: string | number;
}

export const presentNewOsTableHead = (): PresentTableHeadValues[] => {
  return [
    {
      id: 1,
      label: 'data de entrada',
      width: '20%',
    },
    {
      id: 2,
      label: 'número OS',
      width: '10%',
    },
    {
      id: 3,
      label: 'edital',
    },
    {
      id: 4,
      label: 'banco',
    },
    {
      id: 5,
      label: 'tipo do imóvel',
    },
    {
      id: 6,
      label: 'cidade',
    },
    {
      id: 7,
      label: 'estado',
      width: '10%',
    },
  ];
};

export const presentAnnouncementsTableHead = (): PresentTableHeadValues[] => {
  return [
    {
      id: 1,
      label: 'data de entrada',
    },
    {
      id: 2,
      label: 'edital',
    },
    {
      id: 3,
      label: 'assunto',
      width: '50%',
    },
  ];
};

export const presentNewOsData = (
  data: HiveRequestData
): PresentDataValues[] => {
  return [
    {
      id: 1,
      value: data.created_at ? convertDate(data?.created_at) : '-',
    },
    {
      id: 2,
      value: data.work_order.number || '-',
    },
    {
      id: 3,
      value: data.public_notice.name || '-',
    },
    {
      id: 4,
      value: data.hive_company ? data.hive_company : '-',
    },
    {
      id: 5,
      value: data.work_order.real_estate_kind
        ? convertPropertyType(data.work_order.real_estate_kind)
        : '-',
    },
    {
      id: 6,
      value: data.work_order.city ? data?.work_order.city : '-',
    },
    {
      id: 7,
      value: data.work_order.state ? data.work_order.state : '-',
    },
  ];
};

export const presentAnnouncementData = (
  data?: AnnouncementData
): PresentDataValues[] => {
  return [
    {
      id: 1,
      value: data?.created_at ? convertDate(data?.created_at) : '-',
    },
    {
      id: 2,
      value: data?.public_notice.name || '-',
    },
    {
      id: 3,
      value: data?.subject || '-',
    },
  ];
};
