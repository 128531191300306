import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '60px 0',
}));
