import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  minWidth: 650,
  padding: '2rem 3rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const FormBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 18,
}));
