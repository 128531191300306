import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SampleContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #D7D8DB',
  backgroundColor: theme.palette.common.white,
  padding: '32px 40px',
  marginTop: '40px',
}));

export const LabelBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const IdLabel = styled(Box)(() => ({
  fontWeight: '600',
  fontSize: '21px',
  backgroundColor: '#ECECEB',
  width: 180,
  height: 39,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  color: '#2B3550',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  top: -32,
  left: -40,
}));

export const LevelLabel = styled(Box)(() => ({
  fontWeight: '600',
  backgroundColor: '#2B3550',
  width: 228,
  height: 39,
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  color: '#FCFAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  top: -32,
  right: -40,
}));

interface InputTitleProps {
  error?: 'true' | undefined;
}

export const InputTitle = styled(Typography)<InputTitleProps>(
  ({ theme, error }) => ({
    color: error && theme.palette.common.danger,
    paddingBottom: '4px',
    fontSize: '14px',
  })
);

export const StyledInput = styled(Typography)<InputTitleProps>(
  ({ theme, error }) => ({
    color: error && theme.palette.common.danger,
    fontWeight: '600',
    fontSize: '18px',
    borderBottom: `1px solid ${
      error ? theme.palette.common.danger : '#8B8C8E'
    }`,
    inlineSize: 'fit-content',
    minWidth: '100px',
    padding: '0 8px',
    minHeight: '28px',
  })
);

export const SectionBox = styled(Box)(({ theme }) => ({
  margin: '40px 0',
  [theme.breakpoints.down('xl')]: {
    margin: '20px 0',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  cursor: 'pointer',
  '& span': { color: theme.palette.primary.main },
  [theme.breakpoints.down('xl')]: {
    fontSize: '18px',
  },
}));

export const StyledIcon = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '21px',
  fontWeight: '600',
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

export const DescriptionTypography = styled(Typography)(() => ({
  fontWeight: '600',
  margin: '20px 0',
}));

export const ErrorButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  width: '100%',
  color: theme.palette.common.warning,
  border: `2px solid ${theme.palette.common.warning}`,
  '&:hover': {
    backgroundColor: theme.palette.common.warning,
    '& span': {
      color: 'white',
    },
  },
  '&:active': {
    backgroundColor: theme.palette.common.warning,
  },
}));

export const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.warning,
}));

export const ElementButton = styled(Button)(({ theme }) => ({
  width: 210,
  height: 32,
  backgroundColor: theme.palette.common.white,
  fontSize: 15,
  fontWeight: '600',
  lineHeight: '20px',
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: 10,
  color: theme.palette.primary.dark,
  display: 'flex',
  gap: 8,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  '&:disabled': {
    color: theme.palette.primary.dark,
  },
}));
