import {
  ConservationState,
  convertConservationState,
  convertPropertyUse,
  convertRegistrationUf,
  convertReportGoal,
  convertStandard,
  EvaluationType,
  PropertyUse,
  RegistrationUf,
  ReportGoal,
  Standard,
} from '../../../api/enumerations';
import {
  CondominiumInformationProps,
  ImprovementData,
  StatisticalReportData,
} from '../../../api/workOrders/types';

export const InitialStateImprovements: ImprovementData[] = [
  {
    name: '',
    area: 0,
    finishing_standard: '',
    age: 0,
    preservation_state: '',
    observations: '',
  },
];

export const mainFacadeValues = [
  { label: 'sem revestimento', value: 'sem revestimento' },
  { label: 'cimentado', value: 'cimentado' },
  { label: 'pintura/ textura', value: 'pintura/ textura' },
  { label: 'grafiato', value: 'grafiato' },
  {
    label: 'cerâmica de baixa qualidade',
    value: 'cerâmica de baixa qualidade',
  },
  { label: 'ardósia ou compatível', value: 'ardósia ou compatível' },
  { label: 'COBOGO', value: 'COBOGO' },
  { label: 'vulcanizado ou fornicado', value: 'vulcanizado ou fornicado' },
  { label: 'granitina ou compatível', value: 'granitina ou compatível' },
  { label: 'cerâmica de boa qualidade', value: 'cerâmica de boa qualidade' },
  { label: 'porcelanato', value: 'porcelanato' },
  { label: 'madeira ou emadeirado', value: 'madeira ou emadeirado' },
  { label: 'granito ou compatível', value: 'granito ou compatível' },
  { label: 'mármore ou compatível', value: 'mármore ou compatível' },
  { label: 'alumínio composto', value: 'alumínio composto' },
  { label: 'pele de vidro', value: 'pele de vidro' },
  { label: 'pastilha', value: 'pastilha' },
  { label: 'outro', value: 'outro' },
  { label: 'cisterna/ poço artesiano', value: 'cisterna/ poço artesiano' },
  { label: 'depósito industrial', value: 'depósito industrial' },
];

export const infraServicesEquipmentsValues = [
  { label: 'piscina', value: 'piscina' },
  { label: 'sauna', value: 'sauna' },
  { label: 'playground', value: 'playground' },
  { label: 'interfone', value: 'interfone' },
  { label: 'pista de cooper', value: 'pista de cooper' },
  { label: 'jardins', value: 'jardins' },
  { label: 'portão eletrônico', value: 'portão eletrônico' },
  { label: 'gás canalizado', value: 'gás canalizado' },
  { label: 'poço artesiano', value: 'poço artesiano' },
  { label: 'quadra', value: 'quadra' },
  { label: 'tv a cabo', value: 'tv a cabo' },
  { label: 'pet place', value: 'pet place' },
  { label: 'salão de festas', value: 'salão de festas' },
  { label: 'churrasqueira', value: 'churrasqueira' },
  { label: 'portaria 24h', value: 'portaria 24h' },
  { label: 'CFTV', value: 'CFTV' },
  { label: 'sala de jogos', value: 'sala de jogos' },
  { label: 'coworking', value: 'coworking' },
  { label: 'gerador', value: 'gerador' },
  {
    label: 'hidrômetro individualizado',
    value: 'hidrômetro individualizado',
  },
  { label: 'academia', value: 'academia' },
  { label: 'lavanderia coletiva', value: 'lavanderia coletiva' },
  { label: 'tv satélite', value: 'tv satélite' },
  { label: 'loja de conveniência', value: 'loja de conveniência' },
  { label: 'brinquedoteca', value: 'brinquedoteca' },
  { label: 'salão gourmet', value: 'salão gourmet' },
  { label: 'vigilância eletrônica', value: 'vigilância eletrônica' },
];

export const fireProtectionValues = [
  { label: 'não possui', value: 'não possui' },
  { label: 'alarme', value: 'alarme' },
  { label: 'hidrantes', value: 'hidrantes' },
  { label: 'extintor', value: 'extintor' },
  { label: 'escada de emergência', value: 'escada de emergência' },
  { label: 'iluminação de emergência', value: 'iluminação de emergência' },
];

export const infrastructureComponents = [
  { label: 'pavimentação', value: 'pavimentação' },
  { label: 'gás canalizado', value: 'gás canalizado' },
  { label: 'energia elétrica', value: 'energia elétrica' },
  {
    label: 'drenagem das águas pluviais',
    value: 'drenagem das águas pluviais',
  },
  { label: 'rede de água potável', value: 'rede de água potável' },
  { label: 'rede de telefonia', value: 'rede de telefonia' },
  { label: 'iluminação pública', value: 'iluminação pública' },
  { label: 'esgoto sanitário', value: 'esgoto sanitário' },
];

export const publicServices = [
  { label: 'rede bancária', value: 'rede bancária' },
  { label: 'coleta de lixo', value: 'coleta de lixo' },
  { label: 'centro comercial', value: 'centro comercial' },
  { label: 'lazer', value: 'lazer' },
  { label: 'escolas', value: 'escolas' },
  { label: 'shopping center', value: 'shopping center' },
  { label: 'clínicas e hospitais', value: 'clínicas e hospitais' },
  { label: 'parque', value: 'parque' },
  { label: 'metrô', value: 'metrô' },
  { label: 'aeroporto', value: 'aeroporto' },
  { label: 'segurança pública', value: 'segurança pública' },
  {
    label: 'transporte público - outros',
    value: 'transporte público - outros',
  },
];

export const landEmptyValues = {
  allowed_uses_by_zoning: '',
  back: '',
  front: '',
  greide_quota: '',
  ideal_fraction: 0,
  implantation_type: '',
  land_fencing_type: '',
  land_position: '',
  maximum_allowed_occupation_rate_percentage: 0,
  maximum_height_allowed: 0,
  number_of_fronts: 0,
  observations: '',
  restriction_fraction_level: '',
  shape: '',
  side: '',
  surface_draining: '',
  testada_front: '',
  total_area_in_squared_meters: 0,
  utilization_coefficient_percentage: 0,
  zoning: '',
  topography: '',
};

export const condominiumEmptyValues = (
  type: string
): CondominiumInformationProps => {
  return {
    age: 0,
    appraisal_position: '',
    area: 0,
    finishing_standard: '',
    fire_protection: [],
    framing: '',
    infrastructure_services_and_equipments_components: [],
    location_status_level: '',
    main_facade_components: [],
    number_of_blocks: 0,
    number_of_elevators: 0,
    number_of_floors: 0,
    number_of_units_per_floor: 0,
    number_of_units: 0,
    other_fire_protections: '',
    preservation_state: '',
    type,
    use_type: '',
    wall_covering: '',
  };
};

export const valuingFactors = [
  { label: 'vista - mar', value: 'vista - mar' },
  { label: 'vista - parque', value: 'vista - parque' },
  { label: 'vista permanente', value: 'vista permanente' },
  { label: 'não possui', value: 'não possui' },
];

export const depreciativeFactors = [
  { label: 'favela', value: 'favela' },
  { label: 'córregos / rios', value: 'córregos/ rios' },
  { label: 'rede de alta tensão', value: 'rede de alta tensão' },
  { label: 'presídio', value: 'presídio' },
  { label: 'feira-livre', value: 'feira-livre' },
  { label: 'não possui', value: 'não possui' },
];

export const transformEmptyString = <T extends object | object[]>(
  input: T
): T => {
  if (typeof input === 'string') {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((item) =>
      typeof item === 'object' ? transformEmptyString(item) : item
    ) as T;
  }

  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, value === '' ? null : value];
      }
      if (Array.isArray(value)) {
        return [
          key,
          value.map((item) =>
            typeof item === 'object' ? transformEmptyString(item) : item
          ),
        ];
      }
      if (value && typeof value === 'object') {
        return [key, transformEmptyString(value)];
      }
      return [key, value];
    })
  ) as T;
};

export const updateSection = <T extends keyof StatisticalReportData>(
  sectionName: T,
  newData: StatisticalReportData[T] | null | undefined,
  dataToUpdate: StatisticalReportData
): StatisticalReportData => {
  const data = dataToUpdate;
  if (newData === null || newData === undefined) return dataToUpdate;

  return {
    ...data,
    [sectionName]: transformEmptyString(newData),
  };
};

export const convertMethodology = (evaluationType: EvaluationType): string => {
  if (
    evaluationType === EvaluationType.AUTOINFERENCES ||
    evaluationType === EvaluationType.SIMPINFERENCES
  ) {
    return 'estatística inferencial';
  }
  return 'fatores';
};

export const isValueLessThan = (
  value: number | null,
  comparativeNumber: number
): boolean | undefined => {
  if (!value) return undefined;

  return value < comparativeNumber;
};

export const isValueGreaterThan = (
  value: number | null,
  comparativeNumber: number
): boolean | undefined => {
  if (!value) return undefined;

  return value > comparativeNumber;
};

export const verifyPrecisionLevel = (value: string): string => {
  if (!value) return '';

  const match = value?.match(/\b(I{1,3})\b/);
  return match ? match[0] : '';
};

export const sumBedroomsAndSuites = (
  bedrooms: number | null,
  suites: number | null
): number => {
  return (bedrooms ?? 0) + (suites ?? 0);
};

export const sumSocialAndSuites = (
  social: number | null,
  suites: number | null
): number => {
  return (social ?? 0) + (suites ?? 0);
};

interface SelectProps {
  value: string;
  label: string;
}

export const conservationOptions = (): SelectProps[] => {
  const enumValues = Object.values(ConservationState).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value, index) => {
    const valueAsNumber = Number(value);
    const enumKey: ConservationState = valueAsNumber;
    return {
      value: index === 0 ? '' : convertConservationState(enumKey),
      label: convertConservationState(enumKey),
    };
  });
};

export const constructiveStandardOptions = (): SelectProps[] => {
  const enumValues = Object.values(Standard).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value, index) => {
    const valueAsNumber = Number(value);
    const enumKey: Standard = valueAsNumber;
    return {
      value: index === 0 ? '' : convertStandard(enumKey),
      label: convertStandard(enumKey),
    };
  });
};

export const propertyUseOptions = (): SelectProps[] => {
  const enumValues = Object.values(PropertyUse).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value, index) => {
    const valueAsNumber = Number(value);
    const enumKey: PropertyUse = valueAsNumber;
    return {
      value: index === 0 ? '' : convertPropertyUse(enumKey),
      label: convertPropertyUse(enumKey),
    };
  });
};

export const reportGoalOptions = (): SelectProps[] => {
  const enumValues = Object.values(ReportGoal).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value, index) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportGoal = valueAsNumber;
    return {
      value: index === 0 ? '' : convertReportGoal(enumKey),
      label: convertReportGoal(enumKey),
    };
  });
};

export const registrationUfOptions = (): SelectProps[] => {
  const enumValues = Object.values(RegistrationUf).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value, index) => {
    const valueAsNumber = Number(value);
    const enumKey: RegistrationUf = valueAsNumber;
    return {
      value: index === 0 ? '' : convertRegistrationUf(enumKey),
      label: convertRegistrationUf(enumKey),
    };
  });
};
