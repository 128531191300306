/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { PropertyType } from '../../../../../api/enumerations';
import { AppraisalInformationData } from '../../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../../constants/icons';
import {
  EditButton,
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../../styles';
import { ApartmentInputs } from './apartmentInputs';
import { infraValues } from './constants';
import { HouseInputs } from './houseInputs';
import { TableApartmentParking } from './Tables/TableApartmentParking';
import { TableArea } from './Tables/TableArea';
import { TableHouseParking } from './Tables/TableHouseParking';
import { TableInternalDivision } from './Tables/TableInternalDivision';

interface AppraisalInformationProps {
  inputs: AppraisalInformationData;
  setInputs: React.Dispatch<React.SetStateAction<AppraisalInformationData>>;
  propertyType: PropertyType;
  handleSection: () => Promise<void>;
  readOnly: boolean;
  handleUpdate?: () => void;
}

export function AppraisalInformation({
  inputs,
  setInputs,
  propertyType,
  handleSection,
  readOnly,
  handleUpdate,
}: AppraisalInformationProps): JSX.Element {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSelectCheckboxValue = (
    event: ChangeEvent<HTMLInputElement>,
    setInputs: Dispatch<SetStateAction<AppraisalInformationData>>,
    arrayName: keyof AppraisalInformationData
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      [arrayName]: checked
        ? [...(prev[arrayName] as string[]), value]
        : [...(prev[arrayName] as string[]).filter((item) => item !== value)],
    }));
  };

  const handleInputChange = (
    field: keyof AppraisalInformationData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value ?? (typeof prev[field] === 'number' ? 0 : ''),
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  useEffect(() => {
    if (readOnly) setIsDisabled(true);
  }, []);

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="tipo do imóvel"
          value={inputs.real_estate_type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="apartamento">apartamento</StyledMenuItem>
          <StyledMenuItem value="casa">casa</StyledMenuItem>
          <StyledMenuItem value="terreno">terreno</StyledMenuItem>
          <StyledMenuItem value="sala comercial">sala comercial</StyledMenuItem>
          <StyledMenuItem value="flat">flat</StyledMenuItem>
          <StyledMenuItem value="loja de rua">loja de rua</StyledMenuItem>
        </StyledSelect>
      </Grid>
      {propertyType === PropertyType.APARTMENT ? (
        <ApartmentInputs
          inputs={inputs}
          setInputs={setInputs}
          readOnly={readOnly}
        />
      ) : (
        <>
          <HouseInputs
            inputs={inputs}
            setInputs={setInputs}
            readOnly={readOnly}
          />
          <Grid item xs={12}>
            <FormLabel>infraestrutura / serviços / equipamentos</FormLabel>
          </Grid>
          {infraValues.map((item) => (
            <Grid item xs={3} key={item.label}>
              <FormGroup>
                <FormControlLabel
                  disabled={readOnly}
                  control={
                    <StyledCheckbox
                      value={item.value}
                      checked={inputs.infrastructure_services_and_equipments?.includes(
                        item.value
                      )}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleSelectCheckboxValue(
                          e,
                          setInputs,
                          'infrastructure_services_and_equipments'
                        )
                      }
                    />
                  }
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
        </>
      )}
      <Grid item xs={12}>
        <StyledTextField
          label="propósito da avaliação / finalidade do laudo / linha"
          value={inputs.evaluation_purpose}
          onChange={(e) =>
            handleInputChange('evaluation_purpose', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TableArea inputs={inputs} setInputs={setInputs} readOnly={readOnly} />
      </Grid>
      <Grid item xs={12}>
        {propertyType === PropertyType.APARTMENT ? (
          <TableApartmentParking
            inputs={inputs}
            setInputs={setInputs}
            readOnly={readOnly}
          />
        ) : (
          <TableHouseParking
            inputs={inputs}
            setInputs={setInputs}
            readOnly={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <TableInternalDivision
          inputs={inputs}
          setInputs={setInputs}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton
          type="submit"
          disabled={isDisabled}
          onClick={() => handleSendJSON()}
        >
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
