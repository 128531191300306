/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { Grid, Typography } from '@mui/material';

import { WorkOrderData } from '../../../api/workOrders/types';
import StandardMap from '../../Maps/StandardMap';
import { Constants } from './constants';
import {
  BoldTypography,
  CoordGrid,
  InputTitle,
  SmallStyledInput,
  StateStyledInput,
  StyledInput,
} from './styles';

interface PropertyAddressWithMapsProps {
  propertyData?: WorkOrderData;
  checkLocation?: boolean;
  isLocationChecked?: boolean;
  pinPlace?: google.maps.LatLngLiteral;
  setPinPlace?: (place: google.maps.LatLngLiteral) => void;
  handleCheckLocation?: (value: boolean) => Promise<void>;
  userCheck?: boolean;
  setUserCheck?: (value: boolean) => void;
}

export function PropertyAddressWithMaps({
  propertyData,
  checkLocation,
  pinPlace,
  setPinPlace,
  handleCheckLocation,
  userCheck,
  setUserCheck,
}: PropertyAddressWithMapsProps): JSX.Element {
  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <InputTitle>{Constants.address}</InputTitle>
            <StyledInput>{propertyData?.street}</StyledInput>
          </Grid>
          <Grid item xs={4}>
            <InputTitle>{Constants.addressNumber}</InputTitle>
            <SmallStyledInput>{propertyData?.number}</SmallStyledInput>
          </Grid>
          <Grid item xs={6}>
            <InputTitle>{Constants.addressComplement}</InputTitle>
            <StyledInput>{propertyData?.address_complement}</StyledInput>
          </Grid>
          <Grid item xs={6}>
            <InputTitle>{Constants.addressDistrict}</InputTitle>
            <StyledInput>{propertyData?.district}</StyledInput>
          </Grid>
          <Grid item xs={6}>
            <InputTitle>{Constants.addressCity}</InputTitle>
            <StyledInput>{propertyData?.city}</StyledInput>
          </Grid>
          <Grid item xs={2}>
            <InputTitle>{Constants.addressUF}</InputTitle>
            <StateStyledInput>{propertyData?.state}</StateStyledInput>
          </Grid>
          <Grid item xs={4}>
            <InputTitle>{Constants.addressCep}</InputTitle>
            <SmallStyledInput>{propertyData?.zip_code}</SmallStyledInput>
          </Grid>
          <CoordGrid item xs={6}>
            <BoldTypography>{Constants.lat}</BoldTypography>
            <Typography>{propertyData?.latitude}</Typography>
          </CoordGrid>
          <CoordGrid item xs={6}>
            <BoldTypography>{Constants.lng}</BoldTypography>
            <Typography>{propertyData?.longitude}</Typography>
          </CoordGrid>
        </Grid>
      </Grid>
      <Grid item xs={6} className="location-confirm">
        {propertyData?.latitude && propertyData.longitude && (
          <StandardMap
            pinPlace={
              pinPlace || {
                lat: Number(propertyData?.latitude),
                lng: Number(propertyData?.longitude),
              }
            }
            lastCoordinateEvent={propertyData.last_coordinate_event}
            checkLocation={checkLocation}
            draggable={!!pinPlace && !userCheck}
            setPinPlace={setPinPlace}
            handleCheckLocation={handleCheckLocation}
            userCheck={userCheck}
            setUserCheck={setUserCheck}
          />
        )}
      </Grid>
    </Grid>
  );
}
