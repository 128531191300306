/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, TextFieldProps, Tooltip } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { EvaluationMethodologyData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { StyledTextField } from '../../ReportInfo/ArbitraryValue/styles';
import { EditButton, StyledMenuItem, StyledSelect } from '../styles';

interface MethodologyProps {
  inputs: EvaluationMethodologyData;
  setInputs: React.Dispatch<React.SetStateAction<EvaluationMethodologyData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
  handleUpdate?: () => void;
}

export function Methodology({
  inputs,
  setInputs,
  handleSection,
  readOnly,
  handleUpdate,
}: MethodologyProps): JSX.Element {
  const [initialDate, setInitialDate] = useState<Dayjs | null>(null);
  const [finishDate, setFinishDate] = useState<Dayjs | null>(null);

  const handleInputChange = (
    field: keyof EvaluationMethodologyData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleStartDateChange = (newDate: Dayjs | null): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setInitialDate(dayjs(newDate, 'DD-MM-YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        samples_reference_research_start_date: formattedDate?.toString(),
      });
    }
  };

  const handleEndDateChange = (newDate: Dayjs | null): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setFinishDate(dayjs(formattedDate, 'DD/MM/YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        samples_reference_research_end_date: formattedDate?.toString(),
      });
    }
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [inputs]);

  useEffect(() => {
    setInitialDate(dayjs(inputs.samples_reference_research_start_date));
    setFinishDate(dayjs(inputs.samples_reference_research_end_date));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <StyledSelect
          select
          label="tratamento"
          value={String(inputs.type)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('type', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="estatística inferencial">
            inferência
          </StyledMenuItem>
          <StyledMenuItem value="fatores">fatores</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          select
          label="metodologia de avaliação"
          value={inputs.methodology}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('methodology', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="MCDDM">MCDDM</StyledMenuItem>
          <StyledMenuItem value="SMDNO">SMDNO</StyledMenuItem>
          <StyledMenuItem value="outras metodologias">
            outras metodologias
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          label="indicar metodologia"
          value={inputs.methodology_name}
          onChange={(e) =>
            handleInputChange('methodology_name', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          id="f-calculus"
          label="F calculado menor que 20?"
          value={String(inputs.f_calculus_less_than_20)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('f_calculus_less_than_20', e.target.value)
          }
          disabled={readOnly}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          id="fundamentation"
          select
          label="grau de fundamentação"
          value={inputs.reasoning_level}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('reasoning_level', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="I">I</StyledMenuItem>
          <StyledMenuItem value="II">II</StyledMenuItem>
          <StyledMenuItem value="III">III</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          id="precision"
          select
          label="grau de precisão"
          value={inputs.precision_level}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('precision_level', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="I">I</StyledMenuItem>
          <StyledMenuItem value="II">II</StyledMenuItem>
          <StyledMenuItem value="III">III</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="o coeficiente de determinação está abaixo de 0,64?"
        >
          <StyledSelect
            select
            value={String(inputs.determination_coefficient_less_than_0_64)}
            label="o coeficiente de determinação está abaixo de 0,64?"
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange(
                'determination_coefficient_less_than_0_64',
                e.target.value
              )
            }
            disabled={readOnly}
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="quantidade de variáveis independentes adotadas no modelo?"
        >
          <StyledTextField
            label="quantidade de variáveis independentes adotadas no modelo?"
            value={inputs.number_of_independent_variables}
            type="number"
            onChange={(e) =>
              handleInputChange(
                'number_of_independent_variables',
                Number(e.target.value)
              )
            }
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="quantidade de dados utilizados no modelo?"
        >
          <StyledTextField
            label="quantidade de dados utilizados no modelo?"
            value={inputs.number_of_data}
            type="number"
            onChange={(e) =>
              handleInputChange('number_of_data', Number(e.target.value))
            }
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="período inicial de referência de pesquisa das amostras?"
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="período inicial de referência de pesquisa das amostras?"
                value={initialDate}
                format="DD-MM-YYYY"
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
                onChange={handleStartDateChange}
                disabled={readOnly}
              />
            </LocalizationProvider>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="período final de referência de pesquisa das amostras?"
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="período final de referência de pesquisa das amostras?"
                value={finishDate}
                format="DD-MM-YYYY"
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
                onChange={handleEndDateChange}
                disabled={readOnly}
              />
            </LocalizationProvider>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="a significância de uma das variáveis é maior que 30%?"
        >
          <StyledSelect
            id="significance"
            label="a significância de uma das variáveis é maior que 30%?"
            value={String(
              inputs.have_variable_with_significance_level_greater_than_30_percent
            )}
            select
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange(
                'have_variable_with_significance_level_greater_than_30_percent',
                e.target.value
              )
            }
            disabled={readOnly}
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          id="micronumerosityextrapolation"
          label="houve extrapolação de alguma variável?"
          value={String(inputs.have_extrapolated_variable)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('have_extrapolated_variable', e.target.value)
          }
          disabled={readOnly}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="o modelo atende a micronumerosidade das variáveis?"
        >
          <StyledSelect
            id="micronumerosity"
            label="o modelo atende a micronumerosidade das variáveis?"
            value={String(inputs.have_micronumerosity)}
            select
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('have_micronumerosity', e.target.value)
            }
            disabled={readOnly}
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          label="o modelo possui Outliers?"
          value={String(inputs.have_outliers)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('have_outliers', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          label="O valor adotado é da estimativa Central?"
          value={String(inputs.is_value_from_central_estimate)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('is_value_from_central_estimate', e.target.value)
          }
          disabled={readOnly}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="justificativa"
          value={inputs.justification}
          onChange={(e) => handleInputChange('justification', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id="equation"
          label="indicar a equação do modelo adotada?"
          value={inputs.model_equation}
          onChange={(e) => handleInputChange('model_equation', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id="observation"
          label="observação valor adotado"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
