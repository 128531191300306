/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { workOrderAPI } from '../../api';
import { Status, StatusCode } from '../../api/enumerations';
import { handleHiveRequest } from '../../api/hive/workOrders';
import { HiveRequestData } from '../../api/hive/workOrders/types';
import { WorkOrderData } from '../../api/workOrders/types';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { MessagesDialog } from '../../components/Dialog/Messages';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { WorkOrderProgressBar } from '../../components/Sections/ProgressBar';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import { BoxContainer, LoadingBox, SectionBox } from '../../components/UI/Box';
import {
  BackButton,
  FilledButton,
  OutlinedButton,
  StandardButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import { WhiteCircularProgress } from '../../components/UI/Typography';
import { Constants } from '../../constants/entrance';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconLocationCityMS,
  IconLocationOnMS,
} from '../../constants/icons';
import { useAccordion } from '../../hooks/useAccordion';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import useSnackbar from '../../hooks/useSnackbar';
import { Attachments } from './Attachments';
import { ObservationDialog } from './Observation';
import { HeaderBox, SubmitBox } from './styles';

export default function EntranceBank(): JSX.Element {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [loadingPage, setLoadingPage] = useState(true);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [acceptWithIssuesLoading, setacceptWithIssuesLoading] = useState(false);

  const { osId, navigateHome } = useGeneral();
  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();
  const navigate = useNavigate();
  const {
    expandAll,
    setExpandAll,
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    toogleAccordions,
  } = useAccordion();

  const resetLoading = (): void => {
    setRejectLoading(false);
    setAcceptLoading(false);
    setacceptWithIssuesLoading(false);
  };

  useEffect(() => {
    if (expandOne && expandTwo && expandThree) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree, setExpandAll]);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await workOrderAPI.getWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.ENTRANCE) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (
        response.data.worker_company_id === null ||
        response.data.hive_request?.acceptance_status !== 'pending'
      ) {
        navigate(`/home/property/${osId}/edit`);
      } else {
        setPropertyData(response.data);
        setLoadingPage(false);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error, true), true);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleRequest = async (
    action: 'rejected' | 'accepted' | 'accepted_with_pendency',
    observations?: string
  ): Promise<void> => {
    if (
      (action === 'rejected' || action === 'accepted_with_pendency') &&
      observations?.length === 0
    ) {
      handleSnackbar('O preenchimento do comentário é obrigatório.', true);
      return;
    }

    let successMessage;
    if (action === 'rejected') {
      successMessage = Constants.successfullyRejected;
      setRejectLoading(true);
    }
    if (action === 'accepted') {
      successMessage = Constants.successfullyAccepted;
      setAcceptLoading(true);
    }
    if (action === 'accepted_with_pendency') {
      successMessage = Constants.successfullyRejectedWithIssues;
      setacceptWithIssuesLoading(true);
    }

    const data: HiveRequestData = {
      acceptance_status: action,
    };
    if (action !== 'accepted') data.observations = observations;

    try {
      const response = await handleHiveRequest(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar(`${successMessage}`, false);
      if (action === 'rejected') {
        navigateHome();
      } else {
        navigate(`/home/property/${osId}/edit`);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      resetLoading();
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.dataEntrance}
          createdAt={propertyData?.created_at}
        />
        {loadingPage && !propertyData ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <HeaderBox>
              <MessagesDialog />
              <Attachments propertyData={propertyData} osId={osId} />
            </HeaderBox>
            <WorkOrderProgressBar complete>
              <>
                <ClientData propertyData={propertyData} />
                <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              </>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps propertyData={propertyData} />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete lastAccordion>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
            </WorkOrderProgressBar>
            <SubmitBox>
              <Box display="flex" gap="20px">
                <ObservationDialog
                  text={Constants.reject}
                  button={
                    <OutlinedButton disabled={rejectLoading}>
                      {rejectLoading ? (
                        <CircularProgress size={22} />
                      ) : (
                        Constants.reject
                      )}
                    </OutlinedButton>
                  }
                  action="rejected"
                  modalCallback={handleRequest}
                />
                <ObservationDialog
                  text={Constants.acceptWithIssues}
                  action="accepted_with_pendency"
                  button={
                    <FilledButton disabled={acceptWithIssuesLoading}>
                      {acceptWithIssuesLoading ? (
                        <WhiteCircularProgress size={22} />
                      ) : (
                        Constants.acceptWithIssues
                      )}
                    </FilledButton>
                  }
                  modalCallback={handleRequest}
                />
              </Box>
              <ConfirmationDialog
                text={Constants.acceptWorkOrder}
                button={
                  <StandardButton disabled={acceptLoading}>
                    {acceptLoading ? (
                      <WhiteCircularProgress size={22} />
                    ) : (
                      Constants.accept
                    )}
                  </StandardButton>
                }
                modalCallback={() => handleRequest('accepted')}
              />
            </SubmitBox>
          </>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
