/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { ProfileUsers } from '../../api/enumerations';
import { CompanyData } from '../../api/login/types';
import { UserData } from '../../api/users/types';
import avatar1 from '../../assets/avatars/Avatar-1.png';
import {
  IconArchiveMS,
  IconArrowBackMS,
  IconArrowForwardMS,
  IconCalendarMonthMS,
  IconHomeMS,
  IconListAltMS,
  IconLogoutMS,
  IconSettingsMS,
  IconWarningMS,
  IconWorkspacesMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import tourInitialState from '../../helpers/driver/tourInitialState';
import {
  getLocalStorageCompany,
  removeLocalStorageCompany,
  removeLocalStorageToken,
  setLocalStorageCompany,
} from '../../helpers/localStorage';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import Snackbar from '../Snackbar';
import { AvatarMenu } from './AvatarMenu';
import { Constants } from './constants';
import { Notifications } from './Notifications';
import {
  ArrowBox,
  AvatarBox,
  AvatarContainer,
  AvatarStyled,
  BoxContainer,
  CompanyMedia,
  Drawer,
  HoverBox,
  Logo,
  LogoutButton,
  LogoutItem,
  MenuBox,
  MenuList,
  MenuListItem,
  NameBox,
  QuickTour,
  StyledSelect,
  StyledTooltip,
} from './styles';

interface MenuProps {
  userData: UserData;
  logo: string;
  bg?: boolean;
}

export default function Menu({ userData, logo, bg }: MenuProps): JSX.Element {
  const [showTitle, setShowTitle] = useState(true);
  const [companyData, setCompanyData] = useState('');
  const [companyList, setCompanyList] = useState<CompanyData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();
  const decoded = useStoragedJwt();
  const company = getLocalStorageCompany();
  const { setCompany, openSnackbar, setIsMenuOpen } = useContext(GlobalContext);
  const { navigateHome, checkPathname } = useGeneral();
  const {
    isProgressOpen,
    setProgressOpen,
    setTourCompletion,
    setTourOn,
    setTutorialStep,
    drawerOpen,
    setDrawerOpen,
  } = useTour();

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const handleDrawerOpen = (): void => {
    setDrawerOpen(!drawerOpen);
    setIsMenuOpen(!drawerOpen);
    if (!drawerOpen) {
      setTimeout(() => {
        setShowTitle(true);
      }, 150);
    } else {
      setShowTitle(false);
    }
  };

  const toogleClass = (): void => {
    setProgressOpen(!isProgressOpen);
  };

  const handleLogout = (): void => {
    removeLocalStorageToken();
    removeLocalStorageCompany();
    setTutorialStep(0);
    setTourOn(false);
    setTourCompletion(tourInitialState);
    navigate('/login');
    window.location.reload();
  };

  useEffect(() => {
    if (decoded === null) {
      handleLogout();
    } else {
      const compOptions = decoded.user.companies.filter(
        (element) =>
          element.subdomain !== 'realprice-st' &&
          element.subdomain !== 'realprice'
      );
      setCompanyList(compOptions);
    }
  }, [userData]);

  const menuList = [
    { name: 'início', icon: IconHomeMS, url: '/home' },
    { name: 'arquivados', icon: IconArchiveMS, url: '/archived' },
    { name: 'amostras', icon: IconWorkspacesMS, url: '/samples' },
    { name: 'formulários', icon: IconListAltMS, url: '/forms' },
    { name: 'configurações', icon: IconSettingsMS, url: '/configuration' },
    { name: 'agenda', icon: IconCalendarMonthMS, url: '/agenda' },
    {
      name: 'erros reportados',
      icon: IconWarningMS,
      url: '/error-report',
    },
  ];

  const menuListByUserType =
    company?.role === ProfileUsers.ADMIN
      ? menuList
      : menuList.filter((item) => item.name !== 'configurações');

  useEffect(() => {
    if (company) setCompanyData(company.subdomain);
  }, []);

  const handleChange = (event: SelectChangeEvent): void => {
    setCompanyData(event.target.value);
    const result = companyList.find(
      (element) => element.subdomain === event.target.value
    );
    if (result) {
      setCompany(result);
      setLocalStorageCompany(result);
    }
    if (checkPathname('home')) {
      navigateHome();
    }
  };

  const ref = useRef();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const listener = (event: MouseEvent | TouchEvent | WheelEvent): void => {
      if (!ref.current) {
        // eslint-disable-next-line
        return;
      }
      setProgressOpen(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('wheel', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.addEventListener('wheel', listener);
    };
  }, [ref, setProgressOpen]);

  return (
    <>
      <BoxContainer>
        <Drawer variant="permanent" open={drawerOpen} id="lateral-menu">
          <MenuBox>
            <Logo
              onClick={navigateHome}
              style={{ background: bg ? '#CC35FF' : '' }}
            >
              <CompanyMedia image={logo} title={`Logo da ${company?.name}`} />
            </Logo>
            {drawerOpen ? (
              <AvatarContainer>
                <Box display="flex">
                  <AvatarBox
                    open={drawerOpen}
                    onClick={() => setOpenModal(!openModal)}
                  >
                    <AvatarStyled
                      open={drawerOpen}
                      title={userData.name}
                      image={userData.avatar || avatar1}
                    />
                    <NameBox>
                      {userData.name ? userData.name : Constants.user}
                    </NameBox>
                  </AvatarBox>
                  {isBank && <Notifications />}
                </Box>
                <Select
                  value={companyData}
                  disableUnderline
                  variant="standard"
                  onChange={handleChange}
                  sx={StyledSelect}
                >
                  {companyList.map((option) => (
                    <MenuItem key={option.name} value={option.subdomain}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </AvatarContainer>
            ) : (
              <AvatarBox
                open={drawerOpen}
                onClick={() => setOpenModal(!openModal)}
              >
                <AvatarStyled
                  open={drawerOpen}
                  title={userData.name}
                  image={userData.avatar || avatar1}
                />
              </AvatarBox>
            )}
            <MenuList>
              <Box>
                {menuListByUserType.map((data) => (
                  <MenuListItem
                    open={drawerOpen}
                    key={data.name}
                    disablePadding
                  >
                    <NavLink
                      to={data.url}
                      className={({ isActive }) =>
                        isActive ? 'menu-link active' : ' menu-link inactive'
                      }
                    >
                      {({ isActive }) =>
                        !drawerOpen ? (
                          <StyledTooltip
                            title={data.name}
                            placement="bottom"
                            className={isActive ? 'active' : ''}
                          >
                            {data.icon}
                          </StyledTooltip>
                        ) : (
                          <>
                            {data.icon}
                            {showTitle && data.name}
                          </>
                        )
                      }
                    </NavLink>
                  </MenuListItem>
                ))}
              </Box>
              <QuickTour
                id="tour-button"
                className={isProgressOpen ? 'menu-link active-toogle' : ''}
                onClick={toogleClass}
              >
                {drawerOpen ? Constants.guidedTour : Constants.tour}
                &nbsp;
                {drawerOpen && IconArrowForwardMS}
              </QuickTour>
              <LogoutItem
                open={drawerOpen}
                disablePadding
                onClick={handleLogout}
              >
                {drawerOpen ? (
                  <LogoutButton onClick={handleLogout}>
                    {IconLogoutMS}
                    {Constants.logout}
                  </LogoutButton>
                ) : (
                  <StyledTooltip title="sair" placement="bottom">
                    <LogoutButton onClick={handleLogout}>
                      {IconLogoutMS}
                    </LogoutButton>
                  </StyledTooltip>
                )}
              </LogoutItem>
            </MenuList>
          </MenuBox>
          <ArrowBox
            open={drawerOpen}
            isHovered={isHovered}
            onClick={handleDrawerOpen}
            className="hide"
          >
            {drawerOpen ? IconArrowBackMS : IconArrowForwardMS}
          </ArrowBox>
          {/* use svg filter to round arrow box edges */}
          <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="flt_tag">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                  result="flt_tag"
                />
                <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
              </filter>
            </defs>
          </svg>
        </Drawer>
        <HoverBox
          open={drawerOpen}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </BoxContainer>
      <AvatarMenu open={openModal} handleClose={() => setOpenModal(false)} />
      {openSnackbar && <Snackbar />}
    </>
  );
}
