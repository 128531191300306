/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import { Box, Grid, Paper } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import {
  convertPropertyType,
  EvaluationType,
  StatusCode,
} from '../../../api/enumerations';
import { GalleryPictureData } from '../../../api/pictures/types';
import workOrdersAPI, { patchStatisticalReport } from '../../../api/workOrders';
import {
  AppraisalInformationData,
  AvmInferenceData,
  CondominiumInformationProps,
  ConstructionData,
  DiagnosisData,
  EvaluationMethodologyData,
  EvaluationResultData,
  EvaluationSummaryData,
  GeneralConditionsData,
  ImprovementsData,
  InspectionInfoData,
  LandData,
  MicroregionData,
  StatisticalReportData,
  VariablesData,
  WarrantyData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import { Constants } from '../../../constants/report';
import { GlobalContext } from '../../../context/global';
import { useAccordion } from '../../../hooks/useAccordion';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import {
  ReportCompletionState,
  useReportCompletion,
} from '../../../hooks/useReportCompletion';
import { SectionBox } from '../../UI/Box';
import { AccordionTitle } from '../AccordionTitle';
import { WorkOrderProgressBar } from '../ProgressBar';
import { AccordionSection } from './accordionSection';
import {
  appraisalInitialValues,
  condominiumInitialValues,
  constructionInitialValues,
  diagnosisInitialValues,
  evaluationResultInitialValues,
  evaluationSummaryInitialValues,
  generalInitialValues,
  improvementsInfoInitialValues,
  independentInitialValues,
  initialVariableValue,
  inspectionInitialValues,
  landInitialValues,
  methodologyInitialValues,
  microregionInitialValues,
  observationsInitialValues,
  statisticalReportValues,
  variablesFromAvmReport,
  variablesInitialValues,
  warrantyInitialValues,
} from './initialValue';
import { AdditionalInformation } from './InputsBySections/additional-information';
import { AppraisalInformation } from './InputsBySections/AppraisalInformation';
import { Attachments } from './InputsBySections/attachments';
import { Condominium } from './InputsBySections/condominium';
import { EvaluationResult } from './InputsBySections/evaluation-result';
import { EvaluationSummary } from './InputsBySections/evaluation-summary';
import { FinalRemarks } from './InputsBySections/final-remarks';
import { GeneralConditions } from './InputsBySections/general-conditions';
import { ImprovementsContruction } from './InputsBySections/ImprovementsConstruction';
import { InspectionInformation } from './InputsBySections/inspectionInformation';
import { MarketDiagnosis } from './InputsBySections/marketDiagnosis';
import { Methodology } from './InputsBySections/methodology';
import { Microregion } from './InputsBySections/microregion';
import { Pictures } from './InputsBySections/pictures';
import { PicturesReadOnly } from './InputsBySections/picturesReadOnly';
import { Terrain } from './InputsBySections/terrain';
import { Variables } from './InputsBySections/Variables';
import { WarrantyStatements } from './InputsBySections/warranty-statements';
import { ReportTitle } from './styles';
import { transformEmptyString, updateSection } from './utils';

interface StatisticalReportProps {
  propertyData: WorkOrderData;
  hasPictures: boolean;
  selectedPictures: GalleryPictureData[];
  removeSelectedPicture?: (id: number) => Promise<void>;
  readOnly?: boolean;
  statisticalReportData?: StatisticalReportData;
  setStatisticalReportData?: (value: StatisticalReportData) => void;
}

export function StatisticalReport({
  propertyData,
  hasPictures,
  selectedPictures,
  removeSelectedPicture,
  readOnly = false,
  statisticalReportData,
  setStatisticalReportData,
}: StatisticalReportProps): JSX.Element {
  const propertyType =
    (propertyData && convertPropertyType(propertyData.real_estate_kind)) || '';
  const isFactor =
    propertyData.evaluation_type === EvaluationType.AUTOFACTORS ||
    propertyData.evaluation_type === EvaluationType.SIMPFACTORS;
  const manualReport = propertyData.manual_statistical_report;
  const landApplyValue =
    manualReport && manualReport.land_information !== 'não se aplica' ? 2 : 1;
  const condoApplyValue =
    manualReport && manualReport.condominium_information !== 'não se aplica'
      ? 2
      : 1;
  const improvementsApplyValue =
    manualReport && manualReport.improvements_information !== 'não se aplica'
      ? 2
      : 1;

  const handleVariablesInitialValues = (): VariablesData[] => {
    if (manualReport?.variables && manualReport.variables.length > 0) {
      return variablesInitialValues(manualReport.variables);
    }
    if (propertyData.task_id && propertyData.avm_report && !isFactor) {
      const avmInferenceData = propertyData.avm_report as AvmInferenceData;
      return variablesFromAvmReport(avmInferenceData.hypothesis_tests);
    }
    return [initialVariableValue];
  };

  const [expandEleven, setExpandEleven] = useState(false);
  const [expandTwelve, setExpandTwelve] = useState(false);
  const [expandThirteen, setExpandThirteen] = useState(false);
  const [expandFourteen, setExpandFourteen] = useState(false);
  const [expandFifteen, setExpandFifteen] = useState(false);
  const [expandSixteen, setExpandSixteen] = useState(false);
  const [expandSeventeen, setExpandSeventeen] = useState(false);
  const [initialInputs, setInitialInputs] = useState<StatisticalReportData>(
    statisticalReportValues(propertyType, manualReport, propertyData, isFactor)
  );
  const [methodology, setMethodology] = useState<EvaluationMethodologyData>(
    methodologyInitialValues(
      manualReport?.evaluation_methodology,
      propertyData,
      isFactor
    )
  );
  const [variables, setVariables] = useState<VariablesData[]>(
    handleVariablesInitialValues()
  );
  const [evaluationSummary, setEvaluationSummary] =
    useState<EvaluationSummaryData>(
      evaluationSummaryInitialValues(
        manualReport?.evaluation_summary,
        propertyData
      )
    );
  const [evaluationResult, setEvaluationResult] =
    useState<EvaluationResultData>(
      evaluationResultInitialValues(
        manualReport?.evaluation_result,
        propertyData,
        isFactor
      )
    );
  const [appraisal, setAppraisal] = useState<AppraisalInformationData>(
    appraisalInitialValues(
      propertyType,
      manualReport?.appraisal_information,
      propertyData
    )
  );
  const [microregion, setMicroregion] = useState<MicroregionData>(
    microregionInitialValues(manualReport?.appraisal_microregion_information)
  );
  const [land, setLand] = useState<LandData>(
    landInitialValues(manualReport?.land_information)
  );
  const [initialLandDoesNotApply, setInitialLandDoesNotApply] =
    useState(landApplyValue);
  const [landDoesNotApply, setLandDoesNotApply] = useState(landApplyValue);
  const [condominium, setCondominium] = useState<CondominiumInformationProps>(
    condominiumInitialValues(
      propertyType,
      manualReport?.condominium_information
    )
  );
  const [initialCondoDoesNotApply, setInitialCondoDoesNotApply] =
    useState(condoApplyValue);
  const [condoDoesNotApply, setCondoDoesNotApply] = useState(condoApplyValue);
  const [construction, setConstruction] = useState<ConstructionData>(
    constructionInitialValues(manualReport?.construction_information)
  );
  const [improvements, setImprovements] = useState<ImprovementsData>(
    improvementsInfoInitialValues(manualReport?.improvements_information)
  );
  const [initialImprovementsDoesNotApply, setInitialImprovementsDoesNotApply] =
    useState(improvementsApplyValue);
  const [improvementsDoesNotApply, setImprovementsDoesNotApply] = useState(
    improvementsApplyValue
  );
  const [diagnosis, setDiagnosis] = useState<DiagnosisData>(
    diagnosisInitialValues(manualReport?.market_diagnosis)
  );
  const [general, setGeneral] = useState<GeneralConditionsData>(
    generalInitialValues(manualReport?.general_conditions)
  );
  const [inspection, setInspection] = useState<InspectionInfoData>(
    inspectionInitialValues(manualReport?.inspection_information, propertyData)
  );
  const [warranty, setWarranty] = useState<WarrantyData>(
    warrantyInitialValues(manualReport?.warranty_statements)
  );
  const [independent, setIndependent] = useState<string>(
    independentInitialValues(
      manualReport?.independent_unities_complementary_information
    )
  );
  const [observations, setObservations] = useState<string>(
    observationsInitialValues(manualReport?.observations)
  );

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
  } = useAccordion();

  const { osId } = useGeneral();
  const { reportCompletion, toggleCompletion } = useReportCompletion();

  const checkAttachments = useCallback(async () => {
    try {
      const response = await workOrdersAPI.GetStatisticalReportAttachment(osId);

      if (response.detail.description || !response.data) {
        throw new Error();
      }

      toggleCompletion('attachments', true);
    } catch (error) {
      toggleCompletion('attachments', false);
    }
  }, []);

  useEffect(() => {
    checkAttachments();
  }, []);

  useEffect(() => {
    if (manualReport)
      Object.keys(manualReport).forEach((key) => {
        if (key as keyof ReportCompletionState) {
          toggleCompletion(
            key as keyof ReportCompletionState,
            !!manualReport?.[key as keyof StatisticalReportData]
          );
        }
      });

    if (hasPictures) toggleCompletion('images', true);
  }, [propertyData, hasPictures]);

  const handleSendJSON = useCallback(
    async <T extends keyof StatisticalReportData>(
      section: T,
      editedSection: StatisticalReportData[T],
      skipCheck: boolean,
      setInitialApply?: (value: number) => void,
      doesNotApply = false,
      initialApply = false
    ) => {
      let data: StatisticalReportData;

      if (doesNotApply) {
        if (initialApply && skipCheck) return;

        data = { [section]: 'não se aplica' };
      } else {
        const initialSection = initialInputs[section];

        if (editedSection === null || editedSection === undefined) return;

        const isEqual =
          JSON.stringify(initialSection) === JSON.stringify(editedSection);

        if (isEqual && skipCheck && !initialApply) return;

        data = {
          [section]: transformEmptyString<typeof editedSection>(editedSection),
        };
      }

      try {
        const response = await patchStatisticalReport(osId, data);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setSnackbarMessage('Informações salvas!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        toggleCompletion(section, true);

        if (!doesNotApply) {
          setInitialInputs((prev) => ({
            ...prev,
            [section]: editedSection,
          }));
          if (setInitialApply) setInitialApply(2);
        } else if (setInitialApply) setInitialApply(1);
      } catch (error) {
        setErrorMessage(true);
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
      }
    },
    [initialInputs, condoDoesNotApply, initialCondoDoesNotApply]
  );

  const handleUpdateState = <T extends keyof StatisticalReportData>(
    sectionName: T,
    state: StatisticalReportData[T],
    doesNotApply = false
  ): void => {
    if (setStatisticalReportData && statisticalReportData) {
      if (doesNotApply) {
        const dataToUpdate = {
          ...statisticalReportData,
          [sectionName]: 'não se aplica',
        };
        setStatisticalReportData(dataToUpdate);
      } else {
        const dataToUpdate = updateSection(
          sectionName,
          state,
          statisticalReportData
        );
        setStatisticalReportData(dataToUpdate);
      }
    }
  };

  return (
    <Grid container rowGap={1.5} component={Paper} sx={{ padding: '54px' }}>
      <Grid item xs={12} sx={{ marginBottom: '40px' }}>
        <ReportTitle>{Constants.reportTitle}</ReportTitle>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.evaluation_methodology}
        >
          <SectionBox>
            <AccordionSection
              title="Metodologia da avaliação"
              openAccordion={expandOne}
              setOpenAccordion={setExpandOne}
              handleSection={() =>
                handleSendJSON('evaluation_methodology', methodology, true)
              }
            />
            {expandOne && (
              <Methodology
                inputs={methodology}
                setInputs={setMethodology}
                handleSection={() =>
                  handleSendJSON('evaluation_methodology', methodology, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('evaluation_methodology', methodology)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.variables}>
          <SectionBox>
            <AccordionSection
              title="Variáveis"
              openAccordion={expandTwo}
              setOpenAccordion={setExpandTwo}
              handleSection={() => handleSendJSON('variables', variables, true)}
            />
            {expandTwo && (
              <Variables
                inputs={variables}
                setInputs={setVariables}
                handleSection={() =>
                  handleSendJSON('variables', variables, false)
                }
                readOnly={readOnly}
                handleUpdate={() => handleUpdateState('variables', variables)}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.evaluation_summary}>
          <SectionBox>
            <AccordionSection
              title="Resumo da avaliação"
              openAccordion={expandThree}
              setOpenAccordion={setExpandThree}
              handleSection={() =>
                handleSendJSON('evaluation_summary', evaluationSummary, true)
              }
            />
            {expandThree && (
              <EvaluationSummary
                inputs={evaluationSummary}
                setInputs={setEvaluationSummary}
                handleSection={() =>
                  handleSendJSON('evaluation_summary', evaluationSummary, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('evaluation_summary', evaluationSummary)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.evaluation_result}>
          <SectionBox>
            <AccordionSection
              title="Resultado da avaliação"
              openAccordion={expandFour}
              setOpenAccordion={setExpandFour}
              handleSection={() =>
                handleSendJSON('evaluation_result', evaluationResult, true)
              }
            />
            {expandFour && (
              <EvaluationResult
                inputs={evaluationResult}
                setInputs={setEvaluationResult}
                handleSection={() =>
                  handleSendJSON('evaluation_result', evaluationResult, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('evaluation_result', evaluationResult)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.attachments}>
          <SectionBox>
            <AccordionTitle
              title="Anexos"
              openAccordion={expandFive}
              setOpenAccordion={setExpandFive}
            />
            {expandFive && <Attachments readOnly={readOnly} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.images}>
          <SectionBox>
            <AccordionTitle
              title="Imagens"
              openAccordion={expandSix}
              setOpenAccordion={setExpandSix}
            />
            {expandSix && (
              <Box>
                {readOnly ? (
                  <PicturesReadOnly selectedPictures={selectedPictures} />
                ) : (
                  removeSelectedPicture && (
                    <Pictures
                      propertyData={propertyData}
                      hasPictures={hasPictures}
                      selectedPictures={selectedPictures}
                      removeSelectedPicture={removeSelectedPicture}
                    />
                  )
                )}
              </Box>
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.appraisal_information}>
          <SectionBox>
            <AccordionSection
              title="Imóvel avaliando"
              openAccordion={expandSeven}
              setOpenAccordion={setExpandSeven}
              handleSection={() =>
                handleSendJSON('appraisal_information', appraisal, true)
              }
            />
            {expandSeven && (
              <AppraisalInformation
                inputs={appraisal}
                setInputs={setAppraisal}
                propertyType={propertyData.real_estate_kind}
                handleSection={() =>
                  handleSendJSON('appraisal_information', appraisal, false)
                }
                readOnly={readOnly}
                // handleUpdate={() =>
                //   handleUpdateState('appraisal_information', appraisal)
                // }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.appraisal_microregion_information}
        >
          <SectionBox>
            <AccordionSection
              title="Microrregião do avaliando"
              openAccordion={expandEight}
              setOpenAccordion={setExpandEight}
              handleSection={() =>
                handleSendJSON(
                  'appraisal_microregion_information',
                  microregion,
                  true
                )
              }
            />
            {expandEight && (
              <Microregion
                inputs={microregion}
                setInputs={setMicroregion}
                handleSection={() =>
                  handleSendJSON(
                    'appraisal_microregion_information',
                    microregion,
                    false
                  )
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState(
                    'appraisal_microregion_information',
                    microregion
                  )
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.land_information}>
          <SectionBox>
            <AccordionSection
              title="Terreno"
              openAccordion={expandNine}
              setOpenAccordion={setExpandNine}
              handleSection={() =>
                handleSendJSON(
                  'land_information',
                  land,
                  true,
                  setInitialLandDoesNotApply,
                  landDoesNotApply === 1,
                  initialLandDoesNotApply === 1
                )
              }
            />
            {expandNine && (
              <Terrain
                initialInputs={initialInputs.land_information}
                inputs={land}
                setInputs={setLand}
                doesNotApply={landDoesNotApply}
                setDoesNotApply={setLandDoesNotApply}
                handleSection={() =>
                  handleSendJSON(
                    'land_information',
                    land,
                    false,
                    setInitialLandDoesNotApply,
                    landDoesNotApply === 1,
                    initialLandDoesNotApply === 1
                  )
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState(
                    'appraisal_microregion_information',
                    microregion,
                    landDoesNotApply === 1
                  )
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.condominium_information}
        >
          <SectionBox>
            <AccordionSection
              title="Condomínio"
              openAccordion={expandTen}
              setOpenAccordion={setExpandTen}
              handleSection={() =>
                handleSendJSON(
                  'condominium_information',
                  condominium,
                  true,
                  setInitialCondoDoesNotApply,
                  condoDoesNotApply === 1,
                  initialCondoDoesNotApply === 1
                )
              }
            />
            {expandTen && (
              <Condominium
                initialInputs={initialInputs.condominium_information}
                inputs={condominium}
                setInputs={setCondominium}
                doesNotApply={condoDoesNotApply}
                setDoesNotApply={setCondoDoesNotApply}
                propertyType={propertyData.real_estate_kind}
                handleSection={() =>
                  handleSendJSON(
                    'condominium_information',
                    condominium,
                    false,
                    setInitialCondoDoesNotApply,
                    condoDoesNotApply === 1,
                    initialCondoDoesNotApply === 1
                  )
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState(
                    'condominium_information',
                    condominium,
                    condoDoesNotApply === 1
                  )
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.improvements_information}
        >
          <SectionBox>
            <AccordionSection
              title="Benfeitorias / Construção"
              openAccordion={expandEleven}
              setOpenAccordion={setExpandEleven}
              handleSection={() => {
                handleSendJSON('construction_information', construction, true);
                handleSendJSON(
                  'improvements_information',
                  improvements,
                  true,
                  setInitialImprovementsDoesNotApply,
                  improvementsDoesNotApply === 1,
                  initialImprovementsDoesNotApply === 1
                );
              }}
            />
            {expandEleven && (
              <ImprovementsContruction
                constructionInputs={construction}
                setConstructionInputs={setConstruction}
                improvementsInputs={improvements}
                setImprovementsInputs={setImprovements}
                initialImprovementsInputs={
                  initialInputs.improvements_information
                }
                doesNotApply={improvementsDoesNotApply}
                setDoesNotApply={setImprovementsDoesNotApply}
                handleSection={() => {
                  handleSendJSON(
                    'construction_information',
                    construction,
                    false
                  );
                  handleSendJSON(
                    'improvements_information',
                    improvements,
                    false,
                    setInitialImprovementsDoesNotApply,
                    improvementsDoesNotApply === 1,
                    initialImprovementsDoesNotApply === 1
                  );
                }}
                readOnly={readOnly}
                handleUpdateImprovements={() =>
                  handleUpdateState(
                    'improvements_information',
                    improvements,
                    improvementsDoesNotApply === 1
                  )
                }
                handleUpdateConstruction={() =>
                  handleUpdateState('construction_information', construction)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.market_diagnosis}>
          <SectionBox>
            <AccordionSection
              title="Diagnóstico de mercado"
              openAccordion={expandTwelve}
              setOpenAccordion={setExpandTwelve}
              handleSection={() =>
                handleSendJSON('market_diagnosis', diagnosis, true)
              }
            />
            {expandTwelve && (
              <MarketDiagnosis
                inputs={diagnosis}
                setInputs={setDiagnosis}
                handleSection={() =>
                  handleSendJSON('market_diagnosis', diagnosis, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('market_diagnosis', diagnosis)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.general_conditions}>
          <SectionBox>
            <AccordionSection
              title="Condições gerais"
              openAccordion={expandThirteen}
              setOpenAccordion={setExpandThirteen}
              handleSection={() =>
                handleSendJSON('general_conditions', general, true)
              }
            />
            {expandThirteen && (
              <GeneralConditions
                inputs={general}
                setInputs={setGeneral}
                handleSection={() =>
                  handleSendJSON('general_conditions', general, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('general_conditions', general)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.inspection_information}
        >
          <SectionBox>
            <AccordionSection
              title="Vistoria"
              openAccordion={expandFourteen}
              setOpenAccordion={setExpandFourteen}
              handleSection={() =>
                handleSendJSON('inspection_information', inspection, true)
              }
            />
            {expandFourteen && (
              <InspectionInformation
                inputs={inspection}
                setInputs={setInspection}
                handleSection={() =>
                  handleSendJSON('inspection_information', inspection, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('inspection_information', inspection)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.warranty_statements}>
          <SectionBox>
            <AccordionSection
              title="Manifestações de garantia"
              openAccordion={expandFifteen}
              setOpenAccordion={setExpandFifteen}
              handleSection={() =>
                handleSendJSON('warranty_statements', warranty, true)
              }
            />
            {expandFifteen && (
              <WarrantyStatements
                inputs={warranty}
                setInputs={setWarranty}
                handleSection={() =>
                  handleSendJSON('warranty_statements', warranty, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('warranty_statements', warranty)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={
            reportCompletion.independent_unities_complementary_information
          }
        >
          <SectionBox>
            <AccordionSection
              title="Informações complementares de unidades autônomas incluídas na transação (exclusivo para fins cartorários)"
              openAccordion={expandSixteen}
              setOpenAccordion={setExpandSixteen}
              handleSection={() =>
                handleSendJSON(
                  'independent_unities_complementary_information',
                  independent,
                  true
                )
              }
            />
            {expandSixteen && (
              <AdditionalInformation
                inputs={independent}
                setInputs={setIndependent}
                handleSection={() =>
                  handleSendJSON(
                    'independent_unities_complementary_information',
                    independent,
                    false
                  )
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState(
                    'independent_unities_complementary_information',
                    independent
                  )
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.observations}
          lastAccordion
        >
          <SectionBox>
            <AccordionSection
              title="Observações finais"
              openAccordion={expandSeventeen}
              setOpenAccordion={setExpandSeventeen}
              handleSection={() =>
                handleSendJSON('observations', observations, true)
              }
            />
            {expandSeventeen && (
              <FinalRemarks
                inputs={observations}
                setInputs={setObservations}
                handleSection={() =>
                  handleSendJSON('observations', observations, false)
                }
                readOnly={readOnly}
                handleUpdate={() =>
                  handleUpdateState('observations', observations)
                }
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
    </Grid>
  );
}
