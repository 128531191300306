/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import { Box, CircularProgress, ToggleButton, Typography } from '@mui/material';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { sampleAPI } from '../../../api';
import {
  EvaluationType,
  PropertyType,
  StatusCode,
} from '../../../api/enumerations';
import { ElementData } from '../../../api/sample/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { ExportSample } from '../../../components/ExportSample';
import ElementsMap from '../../../components/Maps/ElementsMap';
import { CardPagination } from '../../../components/Sections/Sample/CardPagination';
import { DashboardIcon } from '../../../components/Sections/Sample/DashboardIcon';
import { UpButton } from '../../../components/Sections/Sample/UpButton';
import {
  IconAddMS,
  IconViewArrayMS,
  IconViewListMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useSnackbar from '../../../hooks/useSnackbar';
import useSample from '../hooks';
import { DeleteElements } from './DeleteElements';
import { ElementCard } from './ElementCard';
import {
  DashboardContainer,
  LevelButton,
  MissingBox,
  SampleText,
  SectionBox,
  StyledBadge,
  StyledDivider,
  StyledToggleButtonGroup,
  StyledTooltip,
} from './styles';

type LatLngLiteral = google.maps.LatLngLiteral;

interface SampleProps {
  propertyData: WorkOrderData;
  sampleData: ElementData[];
  completeElements: ElementData[];
  incompleteElements: ElementData[];
  pinPlace: LatLngLiteral | undefined;
  showMap: boolean;
  updateSample: () => Promise<void>;
  minElements: string;
  disapprovedElements: number[];
  setDisapprovedElements: (value: number[]) => void;
  page: number;
  setPage: (value: number) => void;
  setLastElementEdited: (value: number | undefined) => void;
  setLoading: (value: boolean) => void;
}

export function Sample({
  propertyData,
  sampleData,
  completeElements,
  incompleteElements,
  pinPlace,
  showMap,
  updateSample,
  minElements,
  disapprovedElements,
  setDisapprovedElements,
  page,
  setPage,
  setLastElementEdited,
  setLoading,
}: SampleProps): JSX.Element {
  const {
    view,
    handleView,
    complete,
    setComplete,
    incomplete,
    setIncomplete,
    deactivateFilters,
    osId,
  } = useSample();
  const [activatesElementSelection, setActivatesElementSelection] =
    useState(false);
  const [selectedIcons, setSelectedIcons] = useState<number[]>([]);
  const [loadingDeleteElements, setLoadingDeleteElements] = useState(false);

  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const dashboardIcons = useMemo(() => {
    let sample = sampleData;

    if (complete) sample = completeElements;
    if (incomplete) sample = incompleteElements;

    return sample.map((element, ind) => {
      const samplePageId = ind + 1;
      const sampleId =
        complete || incomplete ? sampleData.indexOf(element) + 1 : samplePageId;
      const isSelected = selectedIcons.includes(samplePageId);
      const isApproved = (): boolean => {
        if (complete) return true;
        if (incomplete) return false;
        return (
          (element?.description?.level === 1 && element.approved) ||
          (!element.description.level && element.approved)
        );
      };

      const handleIconClick = (): void => {
        if (activatesElementSelection) {
          setSelectedIcons((prev) =>
            isSelected
              ? prev.filter((id) => id !== samplePageId)
              : [...prev, samplePageId]
          );
        } else {
          setPage(samplePageId);
        }
      };

      return (
        <Fragment key={element.id}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-block',
              opacity: activatesElementSelection ? 0.7 : 1,
            }}
            onClick={activatesElementSelection ? handleIconClick : undefined}
          >
            <StyledBadge
              color="primary"
              badgeContent={
                activatesElementSelection ? (isSelected ? '✓' : '') : null
              }
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: activatesElementSelection
                    ? 'white'
                    : 'transparent',
                  color: isSelected ? '#28C40A' : 'transparent',
                  cursor: 'pointer',
                },
              }}
            >
              <DashboardIcon
                page={samplePageId}
                setPage={
                  !activatesElementSelection ? handleIconClick : undefined
                }
                sampleId={sampleId}
                alert={!isApproved()}
              />
            </StyledBadge>
          </Box>
        </Fragment>
      );
    });
  }, [
    sampleData,
    activatesElementSelection,
    selectedIcons,
    complete,
    incomplete,
    completeElements,
    incompleteElements,
  ]);

  useEffect(() => {
    if (view === 'list') {
      document.getElementById(`${page}`)?.scrollIntoView();
    }
  }, [page, view]);

  const handleLastEditedPage = (): void => setLastElementEdited(page);

  const pageValue = (element?: ElementData): number => {
    if (element) {
      return sampleData.indexOf(element) + 1;
    }
    if (complete) {
      return sampleData.indexOf(completeElements[page - 1]) + 1;
    }
    if (incomplete) {
      return sampleData.indexOf(incompleteElements[page - 1]) + 1;
    }
    return page;
  };

  const handleMinElements = (): string => {
    if (
      propertyData.evaluation_type === EvaluationType.AUTOINFERENCES ||
      propertyData.evaluation_type === EvaluationType.SIMPINFERENCES
    ) {
      if (propertyData.real_estate_kind === PropertyType.APARTMENT) {
        return Constants.minInfeApartment;
      }
      return Constants.minInfeHouse;
    }
    return Constants.minFactors;
  };

  const handleDeleteElementsInBatch = useCallback(async (): Promise<void> => {
    if (selectedIcons.length === 0) {
      handleSnackbar('Selecione os elementos que deseja excluir', true);
      return;
    }

    setLoadingDeleteElements(true);

    let sample = sampleData;

    if (complete) sample = completeElements;
    if (incomplete) sample = incompleteElements;

    const getElementsId = selectedIcons.map((index) => sample[index - 1]?.id);

    if (getElementsId.length === 0) {
      handleSnackbar('Algo deu errado, tente novamente.', true);
      setLoadingDeleteElements(false);
      return;
    }

    const data = {
      work_order_id: osId,
      samples_ids: getElementsId,
    };

    try {
      const response = await sampleAPI.deleteElementsInBatch(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      handleSnackbar('Elementos foram excluídos com sucesso!', false);
      setPage(1);
      setActivatesElementSelection(false);
      setSelectedIcons([]);
      setLoading(true);
      updateSample();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setLoadingDeleteElements(false);
    }
  }, [selectedIcons, complete, incomplete]);

  return (
    <>
      {showMap && (
        <SampleText id="sample-length">
          {`A amostra possui ${sampleData.length} elementos`}
        </SampleText>
      )}
      {sampleData.length > 0 && (
        <>
          {pinPlace &&
            (showMap ? (
              <ElementsMap
                sampleData={sampleData}
                pinPlace={pinPlace}
                deactivateFilters={deactivateFilters}
                page={pageValue()}
                setPage={setPage}
                sampleCreationStatus
              />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ))}
          <SectionBox id="top">
            <StyledToggleButtonGroup
              value={view}
              exclusive
              onChange={handleView}
              aria-label="visualização"
            >
              <ToggleButton value="card" aria-label="individual">
                {IconViewArrayMS}
              </ToggleButton>
              <ToggleButton value="list" aria-label="lista">
                {IconViewListMS}
              </ToggleButton>
            </StyledToggleButtonGroup>
            <StyledDivider orientation="vertical" flexItem />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box>
                <LevelButton
                  complete="true"
                  value="complete"
                  selected={complete}
                  onChange={() => {
                    setPage(1);
                    setIncomplete(false);
                    setComplete(!complete);
                    setSelectedIcons([]);
                  }}
                >
                  {Constants.complete}
                </LevelButton>
                <LevelButton
                  value="incomplete"
                  selected={incomplete}
                  onChange={() => {
                    setPage(1);
                    setComplete(false);
                    setIncomplete(!incomplete);
                    setSelectedIcons([]);
                  }}
                >
                  {Constants.incomplete}
                </LevelButton>
              </Box>
              <ExportSample
                responsive
                osId={osId}
                title={Constants.exportButtonTitle}
              />
            </Box>
          </SectionBox>
          <Typography fontSize={18}>{handleMinElements()}</Typography>
          {!complete && !incomplete && (
            <>
              <DashboardContainer id="dashboard">
                {dashboardIcons}
                {minElements && (
                  <StyledTooltip title="máximo de elementos" placement="top">
                    <MissingBox>
                      {minElements}
                      {IconAddMS}
                    </MissingBox>
                  </StyledTooltip>
                )}
                <DeleteElements
                  activatesElementSelection={activatesElementSelection}
                  setActivatesElementSelection={setActivatesElementSelection}
                  setSelectedIcons={setSelectedIcons}
                  loading={loadingDeleteElements}
                  modalCallback={handleDeleteElementsInBatch}
                />
              </DashboardContainer>
              {view === 'card' ? (
                <>
                  <CardPagination
                    sampleLength={sampleData.length}
                    page={page}
                    setPage={setPage}
                  />
                  <ElementCard
                    sampleData={sampleData}
                    updateSample={updateSample}
                    disapprovedElements={disapprovedElements}
                    setDisapprovedElements={setDisapprovedElements}
                    sampleId={pageValue()}
                    elementData={sampleData[page - 1]}
                    propertyData={propertyData}
                    handleLastEditedPage={handleLastEditedPage}
                    setPage={setPage}
                    page={page}
                    goToLastPage={
                      sampleData.length > 1 && sampleData.length === page
                    }
                  />
                </>
              ) : (
                <Box>
                  {sampleData.map((element, idx) => (
                    <Box id={`${idx + 1}`} key={element.id}>
                      <ElementCard
                        sampleData={sampleData}
                        updateSample={updateSample}
                        disapprovedElements={disapprovedElements}
                        setDisapprovedElements={setDisapprovedElements}
                        sampleId={pageValue(element)}
                        elementData={element}
                        propertyData={propertyData}
                        handleLastEditedPage={handleLastEditedPage}
                        setPage={setPage}
                        page={page}
                        goToLastPage={sampleData.length > 1}
                      />
                    </Box>
                  ))}
                  <UpButton />
                </Box>
              )}
            </>
          )}
          {complete && (
            <>
              <DashboardContainer>
                {dashboardIcons}
                {minElements && (
                  <StyledTooltip title="máximo de elementos" placement="top">
                    <MissingBox>
                      {minElements}
                      {IconAddMS}
                    </MissingBox>
                  </StyledTooltip>
                )}
                {completeElements.length > 0 && (
                  <DeleteElements
                    activatesElementSelection={activatesElementSelection}
                    setActivatesElementSelection={setActivatesElementSelection}
                    setSelectedIcons={setSelectedIcons}
                    loading={loadingDeleteElements}
                    modalCallback={handleDeleteElementsInBatch}
                  />
                )}
              </DashboardContainer>
              {!!completeElements && completeElements.length > 0 && (
                <>
                  {view === 'card' ? (
                    <>
                      <CardPagination
                        sampleLength={completeElements.length}
                        page={page}
                        setPage={setPage}
                      />
                      <ElementCard
                        sampleData={sampleData}
                        updateSample={updateSample}
                        disapprovedElements={disapprovedElements}
                        setDisapprovedElements={setDisapprovedElements}
                        sampleId={pageValue()}
                        elementData={completeElements[page - 1]}
                        propertyData={propertyData}
                        handleLastEditedPage={handleLastEditedPage}
                        setPage={setPage}
                        page={page}
                        goToLastPage={
                          completeElements.length > 1 &&
                          completeElements.length === page
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Box>
                        {completeElements.map((element, idx) => (
                          <Box id={`${idx + 1}`} key={element.id}>
                            <ElementCard
                              sampleData={sampleData}
                              updateSample={updateSample}
                              disapprovedElements={disapprovedElements}
                              setDisapprovedElements={setDisapprovedElements}
                              sampleId={pageValue(element)}
                              elementData={element}
                              propertyData={propertyData}
                              handleLastEditedPage={handleLastEditedPage}
                              setPage={setPage}
                              page={page}
                              goToLastPage={sampleData.length > 1}
                            />
                          </Box>
                        ))}
                        <UpButton />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {incomplete && (
            <>
              <DashboardContainer>
                {dashboardIcons}
                {minElements && (
                  <StyledTooltip title="máximo de elementos" placement="top">
                    <MissingBox>
                      {minElements}
                      {IconAddMS}
                    </MissingBox>
                  </StyledTooltip>
                )}
                {incompleteElements.length > 0 && (
                  <DeleteElements
                    activatesElementSelection={activatesElementSelection}
                    setActivatesElementSelection={setActivatesElementSelection}
                    setSelectedIcons={setSelectedIcons}
                    loading={loadingDeleteElements}
                    modalCallback={handleDeleteElementsInBatch}
                  />
                )}
              </DashboardContainer>
              {!!incompleteElements && incompleteElements.length > 0 && (
                <>
                  {view === 'card' ? (
                    <>
                      <CardPagination
                        sampleLength={incompleteElements.length}
                        page={page}
                        setPage={setPage}
                      />
                      <ElementCard
                        sampleData={sampleData}
                        updateSample={updateSample}
                        disapprovedElements={disapprovedElements}
                        setDisapprovedElements={setDisapprovedElements}
                        sampleId={pageValue()}
                        elementData={incompleteElements[page - 1]}
                        propertyData={propertyData}
                        handleLastEditedPage={handleLastEditedPage}
                        setPage={setPage}
                        page={page}
                        deactivateFilters={deactivateFilters}
                        goToLastPage={
                          incompleteElements.length > 1 &&
                          incompleteElements.length === page
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Box>
                        {incompleteElements.map((element, idx) => (
                          <Box id={`${idx + 1}`} key={element.id}>
                            <ElementCard
                              sampleData={sampleData}
                              updateSample={updateSample}
                              disapprovedElements={disapprovedElements}
                              setDisapprovedElements={setDisapprovedElements}
                              sampleId={pageValue(element)}
                              elementData={element}
                              propertyData={propertyData}
                              handleLastEditedPage={handleLastEditedPage}
                              setPage={setPage}
                              page={page}
                              deactivateFilters={deactivateFilters}
                              goToLastPage={sampleData.length > 1}
                            />
                          </Box>
                        ))}
                        <UpButton />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
