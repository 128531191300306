/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';

import { sampleAPI, workOrderAPI } from '../../../api';
import { WebsocketTypes } from '../../../api/enumerations';
import { RoomData } from '../../../api/rooms/types';
import { ElementData } from '../../../api/sample/types';
import { AvmStatusData, WorkOrderData } from '../../../api/workOrders/types';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import useGeneral from '../../../hooks/useGeneral';
import { useRooms } from '../../../hooks/useRooms';
import { useTour } from '../../../hooks/useTour';

interface IAvmStatus {
  color: string;
  text: string;
}
interface CalculationHook {
  propertyData: WorkOrderData | undefined;
  sampleData: ElementData[] | undefined;
  rooms: RoomData[];
  avmStatus: IAvmStatus | undefined;
  queuePosition: string | undefined;
  loadingPage: boolean;
}

const useCalculation = (): CalculationHook => {
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [avmStatus, setAvmStatus] = useState<IAvmStatus>();
  const [queuePosition, setQueuePosition] = useState<string>();
  const [loadingPage, setLoadingPage] = useState(true);
  const [propertyData, setPropertyData] = useState<WorkOrderData | undefined>();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  // const { propertyData } = usePropertyData({ status: Status.CALCULATION });
  const { osId, navigateHome } = useGeneral();
  const { handleRooms, rooms } = useRooms();
  const { driveIsActive } = useTour();
  const navigate = useNavigate();
  const decoded = useStoragedJwt();

  const { lastMessage } = useWebSocket(
    `${process.env.REACT_APP_API_WEBSOCKET_URL}/ws/${decoded?.user.id}`
  );

  const handleAvmStatus = (avmStatusData: AvmStatusData | null): void => {
    switch (avmStatusData?.status) {
      case 'failed':
        setAvmStatus({
          color: '#E80B25',
          text:
            avmStatusData?.failure_reason ||
            // eslint-disable-next-line max-len
            `Problema no envio para o cálculo, volte a OS e tente novamente. Se o erro persistir, entre em contato com o suporte e informe o ID: ${osId}.`,
        });
        break;
      case 'executing':
        setAvmStatus({
          color: '#28C40A',
          text: 'Calculando. Em média esse processo leva 40 minutos.',
        });
        break;
      default:
        if (
          avmStatusData?.status &&
          !Number.isNaN(Number(avmStatusData.status))
        ) {
          setQueuePosition(avmStatusData?.status);
        }
    }
  };

  const getSample = async (): Promise<void> => {
    let sample = false;

    if (propertyData) {
      if (propertyData.inspection) {
        handleRooms(propertyData.inspection.id);
      }

      if (propertyData.avm_status) {
        handleAvmStatus(propertyData.avm_status);
      }

      if (propertyData.samples) {
        sample = true;
      }

      setLoadingPage(false);
    }

    if (sample) {
      try {
        const response = await sampleAPI.getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        setSampleData(response.data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  };

  const getDataCallback = useCallback(async () => {
    try {
      const response = await workOrderAPI.getWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (
        response.data.avm_report &&
        response.data.avm_status?.failure_reason === '' &&
        !driveIsActive
      ) {
        navigate(`/home/property/${osId}/report`);
      } else {
        setPropertyData(response.data);
        setLoadingPage(false);
        getSample();
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      navigateHome();
    }
  }, []);

  useEffect(() => {
    getSample();
  }, [propertyData]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);
      if (messageData.type === WebsocketTypes.AVM_PROCESSED) {
        setSnackbarMessage(
          `OS ${messageData.payload.reference_number} finalizou o cálculo.`
        );
        setErrorMessage(false);
        setOpenSnackbar(true);
        getDataCallback();
        navigate(`/home/property/${osId}/report`);
      }
    }
  }, [lastMessage]);

  return {
    propertyData,
    sampleData,
    rooms,
    avmStatus,
    queuePosition,
    loadingPage,
  };
};

export default useCalculation;
