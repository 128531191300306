import { Box, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { buttonWidth } from '../../../components/UI/Button';
import { IRoudend } from '../../../components/UI/Button/types';

export const StyledTextField = styled(TextField)(() => ({
  width: '100px',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiInputBase-input': { padding: '14px' },
}));

export const Header = styled(Box)(() => ({
  display: 'flex',
  gap: '21px',
  alignItems: 'center',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
}));

export const EmptyBox = styled(Box)(() => ({
  width: '30%',
}));

export const SampleHeaderButton = styled(Button)<IRoudend>(
  ({ theme, width, model }) => {
    return {
      display: 'flex',
      gap: '8px',
      borderRadius: '10px',
      width: width ? buttonWidth[width] : '100%',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:disabled': {
        color: theme.palette.primary.main,
      },
      ...(model === 'filled' && {
        border: 0,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:disabled': {
          color: theme.palette.common.white,
        },
      }),
      ...(model === 'warning' && {
        border: `2px solid #E89710`,
        color: '#E89710',
        '&:hover': {
          backgroundColor: '#E89710',
        },
        '&:active': {
          backgroundColor: '#E89710',
        },
      }),
      ...(model === 'error' && {
        border: `2px solid ${theme.palette.common.danger}`,
        color: theme.palette.common.danger,
        '&:hover': {
          backgroundColor: theme.palette.common.danger,
        },
        '&:active': {
          backgroundColor: theme.palette.common.danger,
        },
      }),
      ...(model === 'dark' && {
        border: `2px solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
        },
      }),

      [theme.breakpoints.down(1520)]: {
        fontSize: '0.85rem',
        padding: '2px 4px',
        minWidth: 'auto',
        width: '142px',
      },
    };
  }
);

export const SampleHeaderDeleteButton = styled(Button)<IRoudend>(
  ({ theme }) => {
    return {
      width: 200,
      height: 48,
      background: '#E81710 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: 10,
      opacity: 1,
      display: 'flex',
      gap: 10,
      '&:hover': {
        backgroundColor: '#D0140E',
      },
      [theme.breakpoints.down(1520)]: {
        fontSize: '0.85rem',
        padding: '2px 4px',
        minWidth: 'auto',
        width: '142px',
      },
    };
  }
);
