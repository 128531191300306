/* eslint-disable react-hooks/exhaustive-deps */
import CharacterCount from '@tiptap/extension-character-count';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';

import { StatusCode, WebsocketTypes } from '../../../../api/enumerations';
import {
  getUnreadMessagesCount,
  getWorkOrderMessages,
  sendWorkOrderMessage,
  updateMessagesAsRead,
} from '../../../../api/hive/messages';
import { MessageData } from '../../../../api/hive/messages/types';
import { useStoragedJwt } from '../../../../hooks/useDecodedJwt';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import useSnackbar from '../../../../hooks/useSnackbar';

interface MessageHookProps {
  unreadCount: number;
  messagesData: MessageData[];
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
  firstLoading: boolean;
  loadingSubmit: boolean;
  loadingMore: boolean;
  open: boolean;
  editorEditable: Editor | null;
  handleSubmitMessage: (e: React.FormEvent) => Promise<void>;
  closeDialog: () => void;
  handleOpen: () => void;
}

export function useMessageHook(): MessageHookProps {
  const [reload, setReload] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [messagesData, setMessagesData] = useState<MessageData[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { osId, open, handleClose, handleOpen } = useGeneral();
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();
  const decoded = useStoragedJwt();
  const { lastMessage } = useWebSocket(
    `${process.env.REACT_APP_API_WEBSOCKET_URL}/ws/${decoded?.user.id}`
  );

  const messagesPerPage = 5;

  const editorEditable = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Digite uma mensagem',
      }),
      CharacterCount.configure({
        limit: 500,
      }),
      TextStyle,
      Color,
    ],
    content: '',
    editable: true,
  });

  const resetData = (): void => {
    setFirstLoading(true);
    setMessagesData([]);
    setPage(1);
    setLastCalledPage(0);
  };

  const getUnreadCount = useCallback(async () => {
    try {
      const response = await getUnreadMessagesCount(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setUnreadCount(response.data.count);
        setReload(true);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [osId]);

  useEffect(() => {
    getUnreadCount();
  }, [getUnreadCount]);

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);
      if (
        messageData.type === WebsocketTypes.NEW_MESSAGE &&
        messageData.payload.work_order_id === osId
      ) {
        getUnreadCount();
      }
    }
  }, [lastMessage, osId]);

  const updateUnreadMessages = useCallback(async () => {
    try {
      const response = await updateMessagesAsRead(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setUnreadCount(0);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [osId]);

  useEffect(() => {
    if (open && unreadCount > 0) {
      updateUnreadMessages();
    }
  }, [open, unreadCount]);

  const getRequestsCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      setFirstLoading(false);
      setLoadingMore(false);
      setReload(false);
      return;
    }

    try {
      const response = await getWorkOrderMessages(osId, page, messagesPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setTotalPages(response.detail.total_pages);
      }

      if (response.data) {
        setMessagesData([...messagesData, ...response.data.messages]);
        setLastCalledPage(page);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setLoadingMore(false);
      setFirstLoading(false);
      setReload(false);
    }
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      setLoadingMore(true);
      getRequestsCallback();
    }
  }, [page]);

  useEffect(() => {
    if (reload) {
      resetData();
      getRequestsCallback();
    }
  }, [reload]);

  const handleMessage = (): {
    rawText: string | undefined;
    richText: string;
  } => {
    const jsonText = editorEditable?.getJSON();
    const richText = JSON.stringify(jsonText);
    const rawText = editorEditable?.getText();
    return {
      rawText,
      richText,
    };
  };

  const handleSubmitMessage = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setLoadingSubmit(true);

      const messageText = handleMessage();

      if (!messageText.rawText) {
        setLoadingSubmit(false);
        handleSnackbar('Mensagem inválida', true);
        return;
      }

      const data = {
        message: messageText.richText,
        raw_content: messageText.rawText,
      };

      try {
        const response = await sendWorkOrderMessage(osId, data);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('Mensagem enviada com sucesso!', false);
        editorEditable?.commands.setContent('');
        resetData();
        getRequestsCallback();
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      } finally {
        setLoadingSubmit(false);
      }
    },
    [editorEditable]
  );

  const closeDialog = (): void => {
    handleClose();
    editorEditable?.commands.setContent('');
  };

  return {
    unreadCount,
    messagesData,
    page,
    setPage,
    totalPages,
    firstLoading,
    loadingSubmit,
    loadingMore,
    open,
    editorEditable,
    handleSubmitMessage,
    closeDialog,
    handleOpen,
  };
}
