import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import { QuestionData } from '../../../../api/forms/types';
import { Constants } from '../../../../constants/forms';
import {
  IconCloseMS,
  IconFactCheckMS,
  IconKeyboardDoubleArrowLeftMS,
  IconKeyboardDoubleArrowRightMS,
} from '../../../../constants/icons';
import useGeneral from '../../../../hooks/useGeneral';
import { FilledDarkButton } from '../styles';
import { Question } from './Question';
import { QuestionItem } from './QuestionItem';
import {
  CloseButton,
  NavigationBox,
  OutlinedButton,
  PaperQuestions,
  QuestionsBox,
  StyledDialog,
  StyledTitle,
  TopBox,
} from './styles';

interface ViewQuestionsProps {
  questions: QuestionData[];
}

export function ViewQuestions({ questions }: ViewQuestionsProps): JSX.Element {
  const [questionData, setQuestionData] = useState<QuestionData>();
  const [questionNum, setQuestionNum] = useState(0);
  const [previousAnswer, setPreviousAnswer] = useState<number>();

  const dialog = useGeneral();

  const navigateNext = (): void => {
    const hasJumpTo = questionData?.choices
      .filter((item) => item.jump_to !== null)
      .find((data) => data.id === previousAnswer);

    if (hasJumpTo) {
      const goalQuestion = questions.find(
        (data) => data.id === hasJumpTo.jump_to
      );

      if (goalQuestion) {
        const questionInd = questions.indexOf(goalQuestion);
        setQuestionData(questions[questionInd]);
        setQuestionNum(questionInd + 1);
      }
    } else {
      if (questions.length === questionNum) {
        setQuestionData(questions[0]);
        setQuestionNum(1);
        return;
      }
      setQuestionData(questions[questionNum]);
      setQuestionNum(questionNum + 1);
    }
    setPreviousAnswer(undefined);
  };

  const navigateLast = (): void => {
    if (questionNum === 1) {
      const totalQ = questions.length;
      setQuestionData(questions[totalQ - 1]);
      setQuestionNum(totalQ);
      return;
    }
    setQuestionData(questions[questionNum - 2]);
    setQuestionNum(questionNum - 1);
    setPreviousAnswer(undefined);
  };

  useEffect(() => {
    if (questions) {
      setQuestionData(questions[0]);
      setQuestionNum(1);
    }
  }, [questions]);

  return (
    <>
      <FilledDarkButton onClick={dialog.handleOpen} id="preview-btn">
        {Constants.view}
      </FilledDarkButton>
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="nova pergunta"
      >
        <TopBox>
          <CloseButton onClick={dialog.handleClose}>{IconCloseMS}</CloseButton>
        </TopBox>
        <StyledTitle>
          {IconFactCheckMS}
          {Constants.view}
        </StyledTitle>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <PaperQuestions>
              {questions.map((data, i) => (
                <QuestionsBox
                  key={data.id}
                  open={questionNum === i + 1}
                  onClick={() => {
                    setQuestionData(data);
                    setQuestionNum(i + 1);
                  }}
                >
                  <QuestionItem title={data.title} questionNum={i + 1} />
                </QuestionsBox>
              ))}
            </PaperQuestions>
          </Grid>
          <Grid item xs={8}>
            {questionData && (
              <>
                <Question
                  question={questionData}
                  questionNum={questionNum}
                  setPreviousAnswer={setPreviousAnswer}
                />
                <NavigationBox>
                  <OutlinedButton onClick={navigateNext}>
                    {IconKeyboardDoubleArrowRightMS}
                  </OutlinedButton>
                  <OutlinedButton onClick={navigateLast}>
                    {IconKeyboardDoubleArrowLeftMS}
                  </OutlinedButton>
                </NavigationBox>
              </>
            )}
          </Grid>
        </Grid>
      </StyledDialog>
    </>
  );
}
