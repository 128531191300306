export const Icon123MS = <span className="material-symbols-outlined">123</span>;

export const IconAccountCircleMS = (
  <span className="material-symbols-outlined">account_circle</span>
);

export const IconAddMS = <span className="material-symbols-outlined">add</span>;

export const IconAddPhotoAlternateMS = (
  <span className="material-symbols-outlined">add_photo_alternate</span>
);

export const IconApartmentMS = (
  <span className="material-symbols-outlined">apartment</span>
);

export const IconArchiveMS = (
  <span className="material-symbols-outlined">archive</span>
);

export const IconArrowBackMS = (
  <span className="material-symbols-outlined">arrow_back_ios_new</span>
);

export const IconArrowBackStandardMS = (
  <span className="material-symbols-outlined">arrow_back</span>
);

export const IconArrowCircleLeftMS = (
  <span className="material-symbols-outlined">arrow_circle_left</span>
);

export const IconArrowForwardMS = (
  <span className="material-symbols-outlined">arrow_forward_ios</span>
);
export const IconArrowForwardStandardMS = (
  <span className="material-symbols-outlined">arrow_forward</span>
);

export const IconAssignmentMS = (
  <span className="material-symbols-outlined">assignment</span>
);

export const IconAttachFileMS = (
  <span className="material-symbols-outlined">attach_file</span>
);

export const IconBlockMS = (
  <span className="material-symbols-outlined">block</span>
);

export const IconBookmarkMS = (
  <span className="material-symbols-outlined">bookmark</span>
);

export const IconCalendarMonthMS = (
  <span className="material-symbols-outlined">calendar_month</span>
);

export const IconCalendarTodayMS = (
  <span className="material-symbols-outlined">calendar_today</span>
);

export const IconCancelMS = (
  <span className="material-symbols-outlined">cancel</span>
);

export const IconCheckBoxMS = (
  <span className="material-symbols-outlined">check_box</span>
);

export const IconCheckMS = (
  <span className="material-symbols-outlined">check</span>
);

export const IconChecklistRtlMS = (
  <span className="material-symbols-outlined">checklist_rtl</span>
);

export const IconCircleMS = (
  <span className="material-symbols-outlined">circle</span>
);

export const IconCloseFullscreenMS = (
  <span className="material-symbols-outlined">close_fullscreen</span>
);

export const IconCheckCircleMS = (
  <span className="material-symbols-outlined">check_circle</span>
);

export const IconCloseMS = (
  <span className="material-symbols-outlined">close</span>
);

export const IconCreditCardMS = (
  <span className="material-symbols-outlined">credit_card</span>
);

export const IconDashboardMS = (
  <span className="material-symbols-outlined">dashboard</span>
);

export const IconDeleteMS = (
  <span className="material-symbols-outlined">delete</span>
);

export const IconDescriptionMS = (
  <span className="material-symbols-outlined">description</span>
);

export const IconDesignServicesMS = (
  <span className="material-symbols-outlined">design_services</span>
);

export const IconDoorFrontMS = (
  <span className="material-symbols-outlined">door_front</span>
);

export const IconDownloadMS = (
  <span className="material-symbols-outlined">download</span>
);

export const IconEditMS = (
  <span className="material-symbols-outlined">edit</span>
);

export const IconEditDocumentMS = (
  <span className="material-symbols-outlined">edit_document</span>
);

export const IconEditNoteMS = (
  <span className="material-symbols-outlined">edit_note</span>
);

export const IconEngineeringMS = (
  <span className="material-symbols-outlined">engineering</span>
);

export const IconErrorMS = (
  <span className="material-symbols-outlined">error</span>
);

export const IconExpandLessMS = (
  <span className="material-symbols-outlined">expand_less</span>
);

export const IconExpandMoreMS = (
  <span className="material-symbols-outlined">expand_more</span>
);

export const IconFactCheckMS = (
  <span className="material-symbols-outlined">fact_check</span>
);

export const IconFolderOpenMS = (
  <span className="material-symbols-outlined">folder_open</span>
);

export const IconFormatBoldMS = (
  <span className="material-symbols-outlined">format_bold</span>
);

export const IconFormatItalicMS = (
  <span className="material-symbols-outlined">format_italic</span>
);

export const IconFormatListBulletedMS = (
  <span className="material-symbols-outlined">format_list_bulleted</span>
);

export const IconFullscreenMS = (
  <span className="material-symbols-outlined">fullscreen</span>
);

export const IconFullscreenExitMS = (
  <span className="material-symbols-outlined">fullscreen_exit</span>
);

export const IconHomeMS = (
  <span className="material-symbols-outlined">home</span>
);

export const IconHourglassTopMS = (
  <span className="material-symbols-outlined">hourglass_top</span>
);

export const IconInfoMS = (
  <span className="material-symbols-outlined">info</span>
);

export const IconInventoryMS = (
  <span className="material-symbols-outlined">inventory</span>
);

export const IconKeyMS = <span className="material-symbols-outlined">key</span>;

export const IconKeyboardArrowLeftMS = (
  <span className="material-symbols-outlined">keyboard_arrow_left</span>
);

export const IconKeyboardArrowRightMS = (
  <span className="material-symbols-outlined">keyboard_arrow_right</span>
);

export const IconKeyboardDoubleArrowUpMS = (
  <span className="material-symbols-outlined">keyboard_double_arrow_up</span>
);

export const IconKeyboardDoubleArrowLeftMS = (
  <span className="material-symbols-outlined">keyboard_double_arrow_left</span>
);

export const IconKeyboardDoubleArrowRightMS = (
  <span className="material-symbols-outlined">keyboard_double_arrow_right</span>
);

export const IconKeyboardDoubleArrowDownMS = (
  <span className="material-symbols-outlined">keyboard_double_arrow_down</span>
);

export const IconListAltMS = (
  <span className="material-symbols-outlined">list_alt</span>
);

export const IconLocationCityMS = (
  <span className="material-symbols-outlined">location_city</span>
);

export const IconLocationOnMS = (
  <span className="material-symbols-outlined">location_on</span>
);

export const IconLogoutMS = (
  <span className="material-symbols-outlined">logout</span>
);

export const IconMailMS = (
  <span className="material-symbols-outlined">mail</span>
);

export const IconMapMS = <span className="material-symbols-outlined">map</span>;

export const IconMessageMS = (
  <span className="material-symbols-outlined">message</span>
);

export const IconMoreHorizMS = (
  <span className="material-symbols-outlined">more_horiz</span>
);

export const IconMoreVertMS = (
  <span className="material-symbols-outlined">more_vert</span>
);

export const IconNotificationsMS = (
  <span className="material-symbols-outlined">notifications</span>
);

export const IconNorthdMS = (
  <span className="material-symbols-outlined">north</span>
);

export const IconNotesMS = (
  <span className="material-symbols-outlined">notes</span>
);

export const IconOpenInFullMS = (
  <span className="material-symbols-outlined">open_in_full</span>
);

export const IconPendingMS = (
  <span className="material-symbols-outlined">pending</span>
);

export const IconPersonAddMS = (
  <span className="material-symbols-outlined">person_add</span>
);

export const IconPersonMS = (
  <span className="material-symbols-outlined">person</span>
);

export const IconPersonPinPlace = (
  <span className="material-symbols-outlined">person_pin_circle</span>
);

export const IconPhotoLibraryMS = (
  <span className="material-symbols-outlined">photo_library</span>
);

export const IconPlaylistAddMS = (
  <span className="material-symbols-outlined">playlist_add</span>
);

export const IconRadioButtonCheckedMS = (
  <span className="material-symbols-outlined">radio_button_checked</span>
);

export const IconRefreshMS = (
  <span className="material-symbols-outlined">refresh</span>
);

export const IconRunningWithErrorsMS = (
  <span className="material-symbols-outlined">running_with_errors</span>
);

export const IconSearchMS = (
  <span className="material-symbols-outlined">search</span>
);

export const IconSendMS = (
  <span className="material-symbols-outlined">send</span>
);

export const IconSettingsMS = (
  <span className="material-symbols-outlined">settings</span>
);

export const IconStickyNoteMS = (
  <span className="material-symbols-outlined">sticky_note</span>
);

export const IconSouthdMS = (
  <span className="material-symbols-outlined">south</span>
);

export const IconSwapVerthdMS = (
  <span className="material-symbols-outlined">swap_vert</span>
);

export const IconTableViewMS = (
  <span className="material-symbols-outlined">table_view</span>
);

export const IconTaskAltMS = (
  <span className="material-symbols-outlined">task_alt</span>
);

export const IconTroubleshootMS = (
  <span className="material-symbols-outlined">troubleshoot</span>
);

export const IconUploadMS = (
  <span className="material-symbols-outlined">upload</span>
);

export const IconVerifiedMS = (
  <span className="material-symbols-outlined">verified</span>
);

export const IconViewArrayMS = (
  <span className="material-symbols-outlined">view_array</span>
);

export const IconViewWeekMS = (
  <span className="material-symbols-outlined">view_week</span>
);

export const IconViewListMS = (
  <span className="material-symbols-outlined">view_list</span>
);

export const IconVisibilityMS = (
  <span className="material-symbols-outlined">visibility</span>
);

export const IconVisibilityOffMS = (
  <span className="material-symbols-outlined">visibility_off</span>
);

export const IconWarningMS = (
  <span className="material-symbols-outlined">warning</span>
);

export const IconWorkspacesMS = (
  <span className="material-symbols-outlined">workspaces</span>
);
export const IconSaveMS = (
  <span className="material-symbols-outlined">save</span>
);
