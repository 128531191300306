import { Button, IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { ICloseIcon, IFilled, IOutlined, IRoudend, IStandard } from './types';

export const buttonWidth = {
  sm: '150px',
  ms: '200px',
  md: '250px',
  lg: '500px',
};

export const OutlinedButton = styled(Button)<IOutlined>(
  ({ theme, model, width = 'md' }) => {
    const isError = model === 'error';
    const isMain = model === 'main';
    return {
      width: width && buttonWidth[width],
      textTransform: 'lowercase',
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',
      border: `1.5px solid ${theme.palette.primary.dark}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&:disabled': {
        color: theme.palette.primary.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.dark,
      },
      ...(isMain && {
        color: isError
          ? theme.palette.common.danger
          : theme.palette.primary.main,
        border: `1.5px solid ${theme.palette.primary.main}`,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.main,
        },
      }),
      ...(isError && {
        color: theme.palette.common.danger,
        border: `1.5px solid ${theme.palette.common.danger}`,
        '&:hover': {
          backgroundColor: theme.palette.common.danger,
        },
        '&:active': {
          backgroundColor: theme.palette.common.danger,
        },
      }),
      ...(width === 'md' && {
        [theme.breakpoints.down('lg')]: {
          minWidth: '180px',
          maxWidth: '200px',
        },
      }),
    };
  }
);

export const FilledButton = styled(Button)<IFilled>(
  ({ theme, model, width = 'md' }) => {
    return {
      width: width && buttonWidth[width],
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
        backgroundColor: '',
        filter: 'brightness(85%)',
      },
      ...(width === 'md' && {
        [theme.breakpoints.down('lg')]: {
          minWidth: '180px',
          maxWidth: '200px',
        },
      }),

      ...(model === 'warning' && {
        backgroundColor: theme.palette.common.warning,
        '&:hover': {
          backgroundColor: theme.palette.common.warning,
          filter: 'brightness(85%)',
        },
        '&:active': {
          backgroundColor: theme.palette.common.warning,
        },
      }),
      ...(model === 'error' && {
        backgroundColor: theme.palette.common.danger,
        '&:hover': {
          backgroundColor: theme.palette.common.danger,
          filter: 'brightness(85%)',
        },
        '&:active': {
          backgroundColor: theme.palette.common.danger,
        },
      }),
      ...(model === 'main' && {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          filter: 'brightness(85%)',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.main,
        },
      }),
    };
  }
);

export const StandardButton = styled(Button)<IStandard>(
  ({ theme, component, resize }) => ({
    display: 'flex',
    gap: '10px',
    width: '250px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('xl')]: {
      width: '280px',
    },
    component,
    ...(resize === 'true' && {
      [theme.breakpoints.down('lg')]: {
        minWidth: '180px',
        maxWidth: '200px',
      },
    }),
  })
);

export const StandardButtonOutlined = styled(Button)<IStandard>(
  ({ theme, component, resize }) => ({
    display: 'flex',
    gap: '10px',
    width: '300px',
    border: '1px solid #914FB2',
    background: 'transparent',
    color: '#914FB2',
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.7),
    },
    [theme.breakpoints.down('xl')]: {
      width: '280px',
    },
    component,
    ...(resize === 'true' && {
      [theme.breakpoints.down('lg')]: {
        minWidth: '180px',
        maxWidth: '200px',
      },
    }),
  })
);

export const RoundedButton = styled(Button)<IRoudend>(
  ({ theme, width, model }) => {
    return {
      display: 'flex',
      gap: '8px',
      borderRadius: '10px',
      width: width ? buttonWidth[width] : '100%',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:disabled': {
        color: theme.palette.primary.main,
      },
      ...(model === 'filled' && {
        border: 0,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:disabled': {
          color: theme.palette.common.white,
        },
      }),
      ...(model === 'warning' && {
        border: `2px solid #E89710`,
        color: '#E89710',
        '&:hover': {
          backgroundColor: '#E89710',
        },
        '&:active': {
          backgroundColor: '#E89710',
        },
      }),
      ...(model === 'error' && {
        border: `2px solid ${theme.palette.common.danger}`,
        color: theme.palette.common.danger,
        '&:hover': {
          backgroundColor: theme.palette.common.danger,
        },
        '&:active': {
          backgroundColor: theme.palette.common.danger,
        },
      }),
      ...(model === 'dark' && {
        border: `2px solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
    };
  }
);

export const BackButton = styled(IconButton)(({ theme }) => ({
  '& span': { fontSize: '40px', color: theme.palette.primary.main },
}));

export const CloseIcon = styled(IconButton)<ICloseIcon>(({ theme, hover }) => ({
  right: '20px',
  top: '20px',
  position: 'absolute',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  ...(hover && {
    '& span': {
      color: theme.palette.common.white,
    },
    backgroundColor: '#414141',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

export const CancelOSButton = styled(Button)(({ theme }) => ({
  width: 210,
  height: '100%',
  backgroundColor: theme.palette.common.white,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  border: `2px solid ${theme.palette.common.danger}`,
  borderRadius: 10,
  color: theme.palette.common.danger,
  display: 'flex',
  gap: 4,
  '&:hover': {
    backgroundColor: theme.palette.common.danger,
  },
  '&:active': {
    backgroundColor: theme.palette.common.danger,
  },
  [theme.breakpoints.down('lg')]: {
    width: '180px',
  },
}));

export const DeleteCircleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  margin: '-8px -8px 0',
  backgroundColor: '#414141',
  padding: '4px',
  color: theme.palette.common.white,
  '&:hover': {
    padding: '5px',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const SampleHeaderButton = styled(Button)<IRoudend>(
  ({ theme, width, model }) => {
    return {
      display: 'flex',
      gap: '8px',
      borderRadius: '10px',
      width: width ? buttonWidth[width] : '100%',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:disabled': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down(1400)]: {
        fontSize: '0.8rem',
        padding: '4px 8px',
        minWidth: 'auto',
        width: width ? `calc(${buttonWidth[width]} * 0.8)` : '100%',
      },
      ...(model === 'filled' && {
        border: 0,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:disabled': {
          color: theme.palette.common.white,
        },
      }),
      ...(model === 'warning' && {
        border: `2px solid #E89710`,
        color: '#E89710',
        '&:hover': {
          backgroundColor: '#E89710',
        },
        '&:active': {
          backgroundColor: '#E89710',
        },
      }),
      ...(model === 'error' && {
        border: `2px solid ${theme.palette.common.danger}`,
        color: theme.palette.common.danger,
        '&:hover': {
          backgroundColor: theme.palette.common.danger,
        },
        '&:active': {
          backgroundColor: theme.palette.common.danger,
        },
      }),
      ...(model === 'dark' && {
        border: `2px solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
        },
      }),

      [theme.breakpoints.down(1400)]: {
        fontSize: '0.85rem',
        padding: '2px 4px',
        minWidth: 'auto',
        width: '142px',
      },
    };
  }
);

export const SampleHeaderDeleteButton = styled(Button)<IRoudend>(
  ({ theme }) => {
    return {
      width: 200,
      height: 48,
      background: '#E81710 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: 10,
      opacity: 1,
      display: 'flex',
      gap: 10,
      '&:hover': {
        backgroundColor: '#D0140E',
      },
      [theme.breakpoints.down(1400)]: {
        fontSize: '0.85rem',
        padding: '2px 4px',
        minWidth: 'auto',
        width: '142px',
      },
    };
  }
);
