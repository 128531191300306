import { Box, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

interface ExtrapolationProps {
  isError?: 'true' | 'false';
}

export const ExtrapolationsErrors = styled(Box)<ExtrapolationProps>(
  ({ theme, isError = 'true' }) => ({
    width: '100%',
    padding: '8px 0',
    margin: '50px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    background: '#F5F7FB',
    border: `1px solid ${
      isError === 'true'
        ? theme.palette.common.danger
        : theme.palette.common.warning
    }`,
    color:
      isError === 'true'
        ? theme.palette.common.danger
        : theme.palette.common.warning,
    fontSize: 21,
    fontWeight: 600,

    '& p': {
      fontSize: 14,
      fontWeight: 400,
    },
  })
);

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#d6d6d6be',
    color: '#494848',
    fontSize: 14,
    zIndex: 1,
    textAlign: 'center',
  },
}));
