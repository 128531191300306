/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ButtonBase,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { workOrderAPI } from '../../../api';
import { StatusCode } from '../../../api/enumerations';
import { ObservationData } from '../../../api/workOrders/types';
import { IconDeleteMS, IconStickyNoteMS } from '../../../constants/icons';
import { formatDateToReadableBR } from '../../../helpers';
import { useAccordion } from '../../../hooks/useAccordion';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import useSnackbar from '../../../hooks/useSnackbar';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog';
import { SectionBox } from '../../UI/Box';
import { AccordionTitle } from '../AccordionTitle';
import { AddObservation } from './AddObservation';
import {
  ActionBox,
  ContainerBox,
  ObservationAccordion,
  ObservationInfo,
  ObservationsContainer,
  ObservationSummary,
  ObservationText,
  TextBox,
} from './styles';

export function Observations(): JSX.Element {
  const [observationsData, setObservationsData] = useState<ObservationData[]>(
    []
  );
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [reload, setReload] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const { osId } = useGeneral();
  const { expandOne, setExpandOne } = useAccordion();
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const observationsPerPage = 6;

  useEffect(() => {
    setExpandOne(true);
  }, []);

  const getObservationsCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      setLoadingMore(false);
      setReload(false);
      return;
    }

    try {
      const response = await workOrderAPI.getWOrkOrderObservations(
        osId,
        page,
        observationsPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setTotalPages(response.detail.total_pages);
      }

      if (response.data) {
        setObservationsData([...observationsData, ...response.data]);
        setLastCalledPage(page);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setLoadingMore(false);
      setReload(false);
    }
  }, [page, osId]);

  const updateObservations = (): void => {
    setObservationsData([]);
    setPage(1);
    setLastCalledPage(0);
    setReload(true);
  };

  useEffect(() => {
    if (page !== 1) {
      setLoadingMore(true);
      getObservationsCallback();
    }
  }, [getObservationsCallback]);

  useEffect(() => {
    if (reload) {
      getObservationsCallback();
    }
  }, [reload]);

  const handleRemoveObservation = useCallback(async (observationId: number) => {
    if (!observationId) {
      handleSnackbar('Algo deu errado, tente novamente.', true);
      return;
    }

    try {
      const response = await workOrderAPI.removeObservation(observationId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Observação excluída com sucesso!', false);
      updateObservations();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, []);

  return (
    <ContainerBox>
      <AccordionTitle
        title="Observações"
        icon={IconStickyNoteMS}
        openAccordion={expandOne}
        setOpenAccordion={setExpandOne}
      />
      {expandOne && (
        <SectionBox>
          <AddObservation osId={osId} updateObservations={updateObservations} />
          {reload && observationsData.length === 0 && (
            <Box width="100%" height="200px" textAlign="center">
              <LinearProgress />
            </Box>
          )}
          {observationsData.length > 0 ? (
            <ObservationsContainer id="scrollableDiv">
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={observationsData.length}
                next={() => setPage(page + 1)}
                hasMore={page < totalPages}
                loader={
                  loadingMore && (
                    <Box width="100%" textAlign="center">
                      <CircularProgress size={22} />
                    </Box>
                  )
                }
              >
                {observationsData?.map((obs) => (
                  <ObservationAccordion key={obs.id}>
                    <ObservationSummary
                      sx={{
                        pointerEvents: 'none',
                      }}
                    >
                      <ObservationInfo>
                        <TextBox>
                          <strong>Observação</strong> por {obs.user.name || ''}
                        </TextBox>
                        <ActionBox>
                          {formatDateToReadableBR(obs.created_at)}

                          <ConfirmationDialog
                            text="excluir esta observação"
                            button={<ButtonBase>{IconDeleteMS}</ButtonBase>}
                            modalCallback={() =>
                              handleRemoveObservation(obs.id)
                            }
                          />
                        </ActionBox>
                      </ObservationInfo>
                    </ObservationSummary>
                    <ObservationText>{obs.observation}</ObservationText>
                  </ObservationAccordion>
                ))}
              </InfiniteScroll>
            </ObservationsContainer>
          ) : (
            !reload && 'Nenhuma observação salva'
          )}
        </SectionBox>
      )}
    </ContainerBox>
  );
}
