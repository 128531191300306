import { Slider, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import { PropertyType } from '../../../api/enumerations';
import { ElementData } from '../../../api/sample/types';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { WhiteCircularProgress } from '../../../components/UI/Typography';
import {
  IconDeleteMS,
  IconEditMS,
  IconSearchMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import { convertFloatDot } from '../../../helpers';
import { StyledTooltip } from '../styles';
import {
  EmptyBox,
  FlexBox,
  Header,
  SampleHeaderButton,
  SampleHeaderDeleteButton,
  StyledTextField,
} from './styles';

interface SampleHeaderProps {
  propertyType: PropertyType;
  supplyFactor: number;
  setSupplyFactor: (value: number) => void;
  isSupplyEditionDisabled: boolean;
  setIsSupplyEditionDisabled: (value: boolean) => void;
  sampleData: ElementData[] | undefined;
  handleNewSupplyFactor: () => Promise<void>;
  loadingDelete: boolean;
  maxRadius: number;
  setMaxRadius: (value: number) => void;
  evaluationTypeIsFactors: boolean;
  factorsMax: number;
  inferenceMax: number;
  handleSearchSample(): Promise<void>;
  handleDeleteSample: () => Promise<void>;
  loading: boolean;
}

export function SampleHeader({
  propertyType,
  supplyFactor,
  setSupplyFactor,
  isSupplyEditionDisabled,
  setIsSupplyEditionDisabled,
  sampleData,
  handleNewSupplyFactor,
  loadingDelete,
  maxRadius,
  setMaxRadius,
  evaluationTypeIsFactors,
  factorsMax,
  inferenceMax,
  handleSearchSample,
  handleDeleteSample,
  loading,
}: SampleHeaderProps): JSX.Element {
  const isLotType = propertyType === PropertyType.LOT;
  const showCompleteSampleButton = (): boolean => {
    if (!sampleData) return false;

    const lotPropertyTypeMax = 50;
    const isBelowMaxLimit = (max: number): boolean => sampleData.length < max;

    if (!evaluationTypeIsFactors) {
      if (isLotType && isBelowMaxLimit(lotPropertyTypeMax)) {
        return true;
      }
      if (!isLotType && isBelowMaxLimit(inferenceMax)) {
        return true;
      }
    } else if (isBelowMaxLimit(factorsMax)) {
      return true;
    }
    return false;
  };

  const valuesSlider = [0.5, 1, 1.5, 2, 2.5, 10, 25, 50];
  const marks = valuesSlider.map((value, index) => ({
    value: index,
    label: `${value} km`,
  }));

  const handleChange = (_: Event, value: number | number[]): void => {
    setMaxRadius(valuesSlider[Array.isArray(value) ? value[0] : value]);
  };

  return (
    <Header>
      <FlexBox>
        <NumericFormat
          customInput={StyledTextField}
          label="fator oferta"
          id="supply-offer"
          className="supply-offer"
          color="secondary"
          suffix=" %"
          decimalSeparator="."
          decimalScale={2}
          allowNegative={false}
          inputProps={{ maxLength: 7 }}
          value={supplyFactor}
          onChange={(e) => {
            setSupplyFactor(convertFloatDot(e.target.value));
          }}
          disabled={isSupplyEditionDisabled}
        />
      </FlexBox>
      {sampleData ? (
        <>
          <FlexBox>
            {isSupplyEditionDisabled ? (
              <SampleHeaderButton
                id="edit-supply-factor"
                model="dark"
                width="ms"
                onClick={() => setIsSupplyEditionDisabled(false)}
              >
                {IconEditMS}
                {Constants.editSupply}
              </SampleHeaderButton>
            ) : (
              <SampleHeaderButton
                id="save-supply-factor"
                model="filled"
                width="ms"
                onClick={handleNewSupplyFactor}
              >
                {Constants.save}
              </SampleHeaderButton>
            )}
          </FlexBox>
          {!isLotType ? (
            <StyledTooltip
              title="Quantidade máxima de elementos alcançada"
              placement="top"
              disableHoverListener={showCompleteSampleButton()}
            >
              <FlexBox flex={1}>
                <Slider
                  aria-label="raio"
                  getAriaValueText={(v) => `${v} km`}
                  value={valuesSlider.indexOf(maxRadius)}
                  marks={marks}
                  step={null}
                  min={0}
                  valueLabelDisplay="off"
                  max={valuesSlider.length - 1}
                  onChange={handleChange}
                  disabled={!showCompleteSampleButton()}
                  sx={{
                    marginBottom: '0px',
                    '.MuiSlider-markLabel': {
                      display: 'none',
                    },
                  }}
                />
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    marginLeft: '16px',
                    marginTop: '4px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {maxRadius} km
                </Typography>
              </FlexBox>
            </StyledTooltip>
          ) : (
            <EmptyBox sx={{ flex: 1 }} />
          )}
          <FlexBox justifyContent="flex-end" gap="21px" flex={1}>
            {showCompleteSampleButton() ? (
              <SampleHeaderButton
                width="ms"
                model="filled"
                onClick={handleSearchSample}
                disabled={loading}
              >
                {Constants.completeSample}
              </SampleHeaderButton>
            ) : (
              <EmptyBox />
            )}
            <ConfirmationDialog
              id="delete-sample"
              text={Constants.deleteSampleText}
              button={
                <SampleHeaderDeleteButton disabled={loadingDelete}>
                  {loadingDelete ? (
                    <WhiteCircularProgress size={22} />
                  ) : (
                    <>
                      {IconDeleteMS}
                      {Constants.deleteSample}
                    </>
                  )}
                </SampleHeaderDeleteButton>
              }
              model="error"
              modalCallback={handleDeleteSample}
            />
          </FlexBox>
        </>
      ) : (
        <>
          {!isLotType && (
            <FlexBox width="30%">
              <Slider
                aria-label="raio"
                getAriaValueText={(v) => `${v} km`}
                value={valuesSlider.indexOf(maxRadius)}
                marks={marks}
                step={null}
                min={0}
                max={valuesSlider.length - 1}
                onChange={handleChange}
                valueLabelDisplay="off"
                sx={{
                  marginBottom: '0px',
                  '.MuiSlider-markLabel': {
                    display: 'none',
                  },
                }}
              />
              <Typography
                style={{
                  fontSize: '14px',
                  marginLeft: '22px',
                  fontWeight: 500,
                  marginTop: '4px',
                  whiteSpace: 'nowrap',
                }}
              >
                {maxRadius} km
              </Typography>
            </FlexBox>
          )}
          <FlexBox>
            <SampleHeaderButton
              id="search-sample"
              className="search-sample"
              model="filled"
              width="md"
              onClick={handleSearchSample}
            >
              {IconSearchMS} {Constants.searchSamples}
            </SampleHeaderButton>
          </FlexBox>
          <EmptyBox />
        </>
      )}
    </Header>
  );
}
