/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect } from 'react';

import {
  AppraisalInformationData,
  ParkingApartmentData,
  ParkingSpotsData,
} from '../../../../../../api/workOrders/types';
import {
  StyledTableCellBody,
  StyledTextField,
  TableTextField,
} from '../../../styles';
import { NumericTextfield } from '../styles';

interface TableApartmentParkingProps {
  inputs: AppraisalInformationData;
  setInputs: React.Dispatch<React.SetStateAction<AppraisalInformationData>>;
  readOnly: boolean;
}

export function TableApartmentParking({
  inputs,
  setInputs,
  readOnly,
}: TableApartmentParkingProps): JSX.Element {
  const parking = inputs.parking_information as ParkingApartmentData;

  const handleLinkedSpots = (
    field: keyof ParkingSpotsData,
    value: string | number
  ): void => {
    setInputs((prev) => ({
      ...prev,
      parking_information: {
        ...prev.parking_information,
        linked_spots: {
          ...(prev.parking_information as ParkingApartmentData).linked_spots,
          [field]: value,
        },
      },
    }));
  };

  const handleIndependentSpots = (
    field: keyof ParkingSpotsData,
    value: string | number
  ): void => {
    setInputs((prev) => ({
      ...prev,
      parking_information: {
        ...prev.parking_information,
        independent_spots: {
          ...(prev.parking_information as ParkingApartmentData)
            .independent_spots,
          [field]: value,
        },
      },
    }));
  };

  const handleParkingInformation = (
    field: keyof ParkingApartmentData,
    value: string | number
  ): void => {
    setInputs((prev) => ({
      ...prev,
      parking_information: {
        ...prev.parking_information,
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    handleLinkedSpots(
      'total_covered_and_not_covered',
      Number(parking.linked_spots.covered + parking.linked_spots.not_covered)
    );
  }, [parking.linked_spots.covered, parking.linked_spots.not_covered]);

  useEffect(() => {
    handleLinkedSpots(
      'total_free_and_locked',
      parking.linked_spots.free + parking.linked_spots.locked
    );
  }, [parking.linked_spots.free, parking.linked_spots.locked]);

  useEffect(() => {
    handleIndependentSpots(
      'total_covered_and_not_covered',
      Number(
        parking.independent_spots.covered +
          parking.independent_spots.not_covered
      )
    );
  }, [
    parking.independent_spots.covered,
    parking.independent_spots.not_covered,
  ]);

  useEffect(() => {
    handleIndependentSpots(
      'total_free_and_locked',
      parking.independent_spots.free + parking.independent_spots.locked
    );
  }, [parking.independent_spots.free, parking.independent_spots.locked]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ border: '1px solid #ccc' }}>
              <TableCell rowSpan={2} align="center">
                estacionamento
              </TableCell>
              <TableCell
                colSpan={6}
                align="center"
                sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
              >
                quantidade
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}
            >
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                cobertas
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                descobertas
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                total
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                livres
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                presa
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                total
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                vaga(s) vinculada(s)
              </TableCell>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.linked_spots.covered}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleLinkedSpots('covered', Number(e.target.value))
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.linked_spots.not_covered}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleLinkedSpots('not_covered', Number(e.target.value))
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={parking.linked_spots.total_covered_and_not_covered}
                  disabled
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.linked_spots.free}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleLinkedSpots('free', Number(e.target.value))
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.linked_spots.locked}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleLinkedSpots('locked', Number(e.target.value))
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={parking.linked_spots.total_free_and_locked}
                  disabled
                />
              </StyledTableCellBody>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                vaga(s) autônoma(s)
              </TableCell>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.independent_spots.covered}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleIndependentSpots('covered', Number(e.target.value))
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.independent_spots.not_covered}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleIndependentSpots(
                      'not_covered',
                      Number(e.target.value)
                    )
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={
                    parking.independent_spots.total_covered_and_not_covered
                  }
                  disabled
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.independent_spots.free}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleIndependentSpots('free', Number(e.target.value))
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <NumericTextfield
                  allowNegative={false}
                  decimalScale={0}
                  value={parking.independent_spots.locked}
                  disabled={readOnly}
                  onChange={(e) =>
                    handleIndependentSpots('locked', Number(e.target.value))
                  }
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={parking.independent_spots.total_free_and_locked}
                  disabled
                />
              </StyledTableCellBody>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={6}>
          <StyledTextField
            type="number"
            label="total vagas de uso comum"
            value={parking.total_public_spots}
            disabled={readOnly}
            onChange={(e) =>
              handleParkingInformation(
                'total_public_spots',
                Number(e.target.value)
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            type="number"
            label="quantidade total de vagas privativas"
            value={parking.total_private_spots}
            disabled={readOnly}
            onChange={(e) =>
              handleParkingInformation(
                'total_private_spots',
                Number(e.target.value)
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
