import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectButton = styled(Button)(({ theme }) => ({
  width: '216px',
  height: '46px',
  borderRadius: '8px',
  fontSize: '14px',
  textTransform: 'none',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  transition: 'all 0.3s ease',
  '&.selected': {
    width: '226px',
    fontSize: '14px',
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  width: '100px',
  height: '46px',
  borderRadius: '8px',
  fontSize: '14px',
  marginRight: '10px',
  textTransform: 'none',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.primary.dark}`,
  color: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
