import { useCallback, useEffect, useState } from 'react';

import {
  getNoticeAnnouncementsUnreadCount,
  getNotVisualizedCount,
} from '../../../api/companies';
import { StatusCode } from '../../../api/enumerations';
import { hiveRequestsVisualize } from '../../../api/hive/licensedCompanies';
import {
  IconCloseMS,
  IconMailMS,
  IconNotificationsMS,
} from '../../../constants/icons';
import useGeneral from '../../../hooks/useGeneral';
import { CloseIcon } from '../../UI/Button';
import { StyledDialog } from '../../UI/Dialog';
import { DialogTitle } from '../../UI/Typography';
import { Constants } from '../constants';
import {
  DialogContainer,
  NotificationsButton,
  NotificationsNumber,
  TablesContainer,
} from './styles';
import { AnnouncementsTable } from './Table/Announcements';
import { RequestsTable } from './Table/Requests';

export function Notifications(): JSX.Element {
  const [expandedTable, setExpandedTable] = useState<
    'announcements' | 'newOs' | null
  >(null);
  const [announcementsUnread, setAnnouncementsUnread] = useState(0);
  const [notVisualizedUnreadCount, setNotVisualizedUnreadCount] = useState(0);
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);

  const dialog = useGeneral();

  const reset = (): void => {
    dialog.handleClose();
    setExpandedTable(null);
  };

  const toggleTable = (tableId: 'announcements' | 'newOs'): void => {
    setExpandedTable((prev) => (prev === tableId ? null : tableId));
  };

  const getUnreadCount = useCallback(async () => {
    const { data, detail } = await getNoticeAnnouncementsUnreadCount();

    if (detail.status_code !== StatusCode.OK) {
      throw new Error('Algo deu errado, tente novamente.');
    }

    if (detail.description) {
      throw new Error(detail.description);
    }

    if (data) {
      setAnnouncementsUnread(data.count);
    }
  }, []);

  const notVisualizedCount = useCallback(async () => {
    const { data, detail } = await getNotVisualizedCount();

    if (detail.status_code !== StatusCode.OK) {
      throw new Error('Algo deu errado, tente novamente.');
    }

    if (detail.description) {
      throw new Error(detail.description);
    }

    if (data) {
      setNotVisualizedUnreadCount(data.count);
    }
  }, []);

  const markHiveRequestAsViewed = useCallback(async () => {
    const response = await hiveRequestsVisualize();

    if (response.detail.status_code !== StatusCode.OK) {
      throw new Error('Algo deu errado, tente novamente.');
    }
  }, []);

  useEffect(() => {
    if (dialog.open) {
      markHiveRequestAsViewed();
    }
  }, [dialog.open, markHiveRequestAsViewed]);

  useEffect(() => {
    getUnreadCount();
    notVisualizedCount();
  }, [getUnreadCount, notVisualizedCount]);

  useEffect(() => {
    if (notVisualizedUnreadCount > 0 || announcementsUnread > 0) {
      setTotalUnreadMessages(notVisualizedUnreadCount + announcementsUnread);
    }
  }, [notVisualizedUnreadCount, announcementsUnread]);

  return (
    <>
      <NotificationsButton onClick={dialog.handleOpen}>
        {IconMailMS}
        {totalUnreadMessages > 0 && (
          <NotificationsNumber>{totalUnreadMessages}</NotificationsNumber>
        )}
      </NotificationsButton>
      <StyledDialog open={dialog.open} onClose={reset}>
        <DialogContainer>
          <CloseIcon onClick={reset}>{IconCloseMS}</CloseIcon>
          <DialogTitle>
            {IconNotificationsMS}
            {Constants.notifications}
          </DialogTitle>
          <TablesContainer>
            {expandedTable !== 'newOs' && (
              <RequestsTable
                tableId="announcements"
                expand={expandedTable === 'announcements'}
                toggleTable={toggleTable}
                handleClose={reset}
              />
            )}
            {expandedTable !== 'announcements' && (
              <AnnouncementsTable
                tableId="newOs"
                expand={expandedTable === 'newOs'}
                toggleTable={toggleTable}
              />
            )}
          </TablesContainer>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
