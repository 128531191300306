import { handleUrl, myHeadersWithToken } from '../../global';
import {
  CompanyNoticesProps,
  DetailProps,
  HiveRequestsProps,
  ListCompanyLicensesResponse,
  NoticesAnnouncementsProps,
} from './types';

export const listCompanyLicenses = (): Promise<ListCompanyLicensesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/licensed-companies`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getNoticeAnnouncements = (
  page: number,
  size: number
): Promise<NoticesAnnouncementsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/notice-announcements?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getHiveRequests = (
  page: number,
  size: number
): Promise<HiveRequestsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/hive-requests?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const hiveRequestsVisualize = (): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/hive-requests/visualize-all`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getCompanyNotices = (
  page: number,
  size: number
): Promise<CompanyNoticesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/public-notices?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const removeAssociationCompanyToNotice = (
  noticeId: number,
  licensedCompanyId: number
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/public-notices/${noticeId}/licensed-companies/${licensedCompanyId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const removeAccreditation = (
  licensedCompanyId: number
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${licensedCompanyId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const announcementsRead = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/notice-announcements/${id}/read`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
