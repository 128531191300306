/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { IconSaveMS } from '../../../../constants/icons';
import { CustomTextField } from '../../../CustomInput';
import { EditButton } from '../styles';

interface AdditionalInformationProps {
  inputs: string;
  setInputs: React.Dispatch<React.SetStateAction<string>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
  handleUpdate?: () => void;
}

export function AdditionalInformation({
  inputs,
  setInputs,
  handleSection,
  readOnly,
  handleUpdate,
}: AdditionalInformationProps): JSX.Element {
  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTextField
          id="independent-value"
          label=""
          value={inputs}
          setValue={(e) => setInputs(e)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item paddingRight={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
