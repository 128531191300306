/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { WarrantyData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface WarrantyProps {
  inputs: WarrantyData;
  setInputs: React.Dispatch<React.SetStateAction<WarrantyData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
  handleUpdate?: () => void;
}

export function WarrantyStatements({
  inputs,
  setInputs,
  handleSection,
  readOnly,
  handleUpdate,
}: WarrantyProps): JSX.Element {
  const handleInputChange = (
    field: keyof WarrantyData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          select
          label=""
          value={String(inputs.can_be_accepted)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) => {
            handleInputChange('can_be_accepted', e.target.value);
            if (e.target.value === 'true') {
              handleInputChange('unaccepted_reason', '');
            }
          }}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={9}>
        {String(inputs.can_be_accepted) === 'false' && (
          <StyledTextField
            label=""
            required
            placeholder="justifique*"
            value={inputs.unaccepted_reason}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange('unaccepted_reason', e.target.value)
            }
          />
        )}
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={handleSendJSON}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
