/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import {
  convertPropertyType,
  PropertyType,
  YesOrNo,
} from '../../../../api/enumerations';
import { CondominiumInformationProps } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { selectYesOrNo } from '../../../../constants/selectOptions';
import { SelectTextField } from '../../../CustomInput';
import {
  EditButton,
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import {
  fireProtectionValues,
  infraServicesEquipmentsValues,
  condominiumEmptyValues,
  mainFacadeValues,
} from '../utils';

interface CondominiumProps {
  doesNotApply: number;
  setDoesNotApply: (value: number) => void;
  initialInputs: CondominiumInformationProps | string | undefined;
  inputs: CondominiumInformationProps;
  setInputs: React.Dispatch<React.SetStateAction<CondominiumInformationProps>>;
  propertyType: PropertyType;
  handleSection: () => Promise<void>;
  readOnly: boolean;
  handleUpdate?: () => void;
}

export function Condominium({
  doesNotApply,
  setDoesNotApply,
  initialInputs,
  inputs,
  setInputs,
  propertyType,
  handleSection,
  readOnly,
  handleUpdate,
}: CondominiumProps): JSX.Element {
  const [condominiumIsDisabled, setCondominiumIsDisabled] = useState(false);

  const handleSelectCheckboxValue = (
    event: ChangeEvent<HTMLInputElement>,
    arrayName: keyof CondominiumInformationProps
  ): void => {
    const { value, checked } = event.target;
    setInputs((prev) => ({
      ...prev,
      [arrayName]: checked
        ? [...(prev[arrayName] as string[]), value]
        : [...(prev[arrayName] as string[]).filter((item) => item !== value)],
    }));
  };

  const handleInputChange = (
    field: keyof CondominiumInformationProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value ?? (typeof prev[field] === 'number' ? 0 : ''),
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [doesNotApply, inputs]);

  useEffect(() => {
    if (doesNotApply === YesOrNo.YES) {
      setInputs(condominiumEmptyValues(convertPropertyType(propertyType)));
      setCondominiumIsDisabled(true);
    } else {
      setInputs(initialInputs as CondominiumInformationProps);
      setCondominiumIsDisabled(false);
    }
    if (readOnly) {
      setCondominiumIsDisabled(true);
    }
  }, [doesNotApply]);

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [doesNotApply, inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectTextField
          id="select-apply"
          label="não se aplica"
          selectOptions={selectYesOrNo()}
          value={doesNotApply}
          setValue={setDoesNotApply}
          disable={readOnly}
        />
      </Grid>
      {propertyType === PropertyType.HOUSE ? (
        <>
          <Grid item xs={3}>
            <Tooltip
              arrow
              placement="top"
              title="número de unidades de condomínio"
            >
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="número de unidades de condomínio"
                value={inputs.number_of_units}
                onChange={(e) =>
                  handleInputChange('number_of_units', e.target.value)
                }
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              type="number"
              label="área do condomínio"
              value={inputs.area}
              onChange={(e) => handleInputChange('area', e.target.value)}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={condominiumIsDisabled}
              label="esquadrias"
              value={inputs.framing}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) => handleInputChange('framing', e.target.value)}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="alumínio">alumínio</StyledMenuItem>
              <StyledMenuItem value="ferro">ferro</StyledMenuItem>
              <StyledMenuItem value="madeira">madeira</StyledMenuItem>
              <StyledMenuItem value="PVC">PVC</StyledMenuItem>
              <StyledMenuItem value="vidro temperado">
                vidro temperado
              </StyledMenuItem>
              <StyledMenuItem value="outros">outros</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={condominiumIsDisabled}
              label="revestimento"
              value={inputs.wall_covering}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handleInputChange('wall_covering', e.target.value)
              }
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="pintura">pintura</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={condominiumIsDisabled}
              label="localização"
              value={inputs.location_status_level}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handleInputChange('location_status_level', e.target.value)
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="boa">boa</StyledMenuItem>
              <StyledMenuItem value="regular">regular</StyledMenuItem>
              <StyledMenuItem value="ruim">ruim</StyledMenuItem>
              <StyledMenuItem value="ótima">ótima</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              type="number"
              label="número de pavimentos"
              value={inputs.number_of_floors}
              onChange={(e) =>
                handleInputChange('number_of_floors', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              type="number"
              label="número de unidades por andar"
              value={inputs.number_of_units_per_floor}
              onChange={(e) =>
                handleInputChange('number_of_units_per_floor', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={condominiumIsDisabled}
              label="posição"
              value={inputs.appraisal_position}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handleInputChange('appraisal_position', e.target.value)
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="isolada/ frente do terreno">
                isolada/ frente do terreno
              </StyledMenuItem>
              <StyledMenuItem value="isolada/ centro do terreno">
                isolada/ centro do terreno
              </StyledMenuItem>
              <StyledMenuItem value="geminada em uma lateral">
                geminada em uma lateral
              </StyledMenuItem>
              <StyledMenuItem value="geminada nas laterais">
                geminada nas laterais
              </StyledMenuItem>
              <StyledMenuItem value="juntos aos fundos">
                juntos aos fundos
              </StyledMenuItem>
              <StyledMenuItem value="junto a uma lateral">
                junto a uma lateral
              </StyledMenuItem>
              <StyledMenuItem value="junto às laterais">
                junto às laterais
              </StyledMenuItem>
              <StyledMenuItem value="ocupa todo o terreno">
                ocupa todo o terreno
              </StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              type="number"
              label="idade estimada"
              value={inputs.age}
              onChange={(e) => handleInputChange('age', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              type="number"
              label="quantidade de blocos"
              value={inputs.number_of_blocks}
              onChange={(e) =>
                handleInputChange('number_of_blocks', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              type="number"
              label="número de elevadores"
              value={inputs.number_of_elevators}
              onChange={(e) =>
                handleInputChange('number_of_elevators', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={condominiumIsDisabled}
              label="uso"
              value={inputs.use_type}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) => handleInputChange('use_type', e.target.value)}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="comercial">comercial</StyledMenuItem>
              <StyledMenuItem value="residencial">residencial</StyledMenuItem>
              <StyledMenuItem value="misto">misto</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              type="number"
              label="número de unidades"
              value={inputs.number_of_units}
              onChange={(e) =>
                handleInputChange('number_of_units', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={condominiumIsDisabled}
              label="padrão de acabamento"
              value={inputs.finishing_standard}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handleInputChange('finishing_standard', e.target.value)
              }
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="alto">alto</StyledMenuItem>
              <StyledMenuItem value="baixo">baixo</StyledMenuItem>
              <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
              <StyledMenuItem value="normal/ médio">
                normal / médio
              </StyledMenuItem>
            </StyledSelect>
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={condominiumIsDisabled}
          label="estado de conservação"
          value={inputs.preservation_state}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('preservation_state', e.target.value)
          }
        >
          <StyledMenuItem>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="bom">bom</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
          <StyledMenuItem value="em implantação">em implantação</StyledMenuItem>
        </StyledSelect>
      </Grid>
      {propertyType === PropertyType.HOUSE ? (
        <>
          <Grid item xs={12}>
            <FormLabel>proteção contra incêndio</FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={condominiumIsDisabled}
              control={
                <StyledCheckbox
                  value="não possui"
                  checked={inputs.fire_protection?.includes('não possui')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleSelectCheckboxValue(e, 'fire_protection')
                  }
                />
              }
              label="não possui"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={condominiumIsDisabled}
              control={
                <StyledCheckbox
                  value="alarme"
                  checked={inputs.fire_protection?.includes('alarme')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleSelectCheckboxValue(e, 'fire_protection')
                  }
                />
              }
              label="alarme"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <FormLabel>fachada principal</FormLabel>
          </Grid>
          {mainFacadeValues.map((item) => (
            <Grid item xs={3} key={item.label}>
              <FormGroup>
                <FormControlLabel
                  disabled={condominiumIsDisabled}
                  control={
                    <StyledCheckbox
                      value={item.value}
                      checked={inputs.main_facade_components?.includes(
                        item.value
                      )}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleSelectCheckboxValue(e, 'main_facade_components')
                      }
                    />
                  }
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
          <Grid item xs={12}>
            <FormLabel>proteção contra incêndio</FormLabel>
          </Grid>
          {fireProtectionValues.map((item) => (
            <Grid item xs={3} key={item.label}>
              <FormGroup>
                <FormControlLabel
                  disabled={condominiumIsDisabled}
                  control={
                    <StyledCheckbox
                      value={item.value}
                      checked={inputs.fire_protection?.includes(item.value)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleSelectCheckboxValue(e, 'fire_protection')
                      }
                    />
                  }
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
          <Grid item xs={3}>
            <StyledTextField
              disabled={condominiumIsDisabled}
              label="outros"
              value={inputs.other_fire_protections}
              onChange={(e) =>
                handleInputChange('other_fire_protections', e.target.value)
              }
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormLabel>infraestrutura / serviços / equipamentos</FormLabel>
      </Grid>
      {infraServicesEquipmentsValues.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              disabled={condominiumIsDisabled}
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.infrastructure_services_and_equipments_components?.includes(
                    item.value
                  )}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleSelectCheckboxValue(
                      e,
                      'infrastructure_services_and_equipments_components'
                    )
                  }
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton
          type="submit"
          disabled={readOnly}
          onClick={() => handleSendJSON()}
        >
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
