import {
  Box,
  Button,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
  Badge,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
  display: 'flex',
  gap: '10px',
}));

export const SampleText = styled(Typography)(({ theme }) => ({
  width: '100%',
  margin: '20px 0 -10px',
  textAlign: 'center',
  fontSize: '21px',
  fontWeight: '600',
  color: theme.palette.primary.dark,
}));

export const DashboardContainer = styled(Box)(() => ({
  margin: '20px 0 0',
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    height: '45px',
    border: 'none',
    '& :hover': {
      color: theme.palette.primary.main,
    },
    '& .MuiButtonBase-root.MuiToggleButton-root': {
      '&.Mui-selected': {
        color: '#FAFCFF',
        backgroundColor: theme.palette.primary.dark,
        border: 'none',
        borderRadius: '4px',
        '& :hover': {
          color: '#FAFCFF',
          backgroundColor: theme.palette.primary.dark,
          opacity: '80%',
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  })
);

export const StyledDivider = styled(Divider)(() => ({
  margin: '0 10px',
  borderRightWidth: 'medium',
}));

interface LevelButtonProps {
  complete?: string;
}

export const LevelButton = styled(ToggleButton)<LevelButtonProps>(
  ({ theme, complete }) => ({
    width: '140px',
    height: '45px',
    borderRadius: '8px',
    fontSize: '18px',
    textTransform: 'none',
    marginRight: '15px',
    color: complete ? '#58147F' : theme.palette.common.warning,
    backgroundColor: theme.palette.common.white,
    border: `1px solid`,
    borderColor: complete ? '#58147F' : theme.palette.common.warning,
    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: complete ? '#58147F' : theme.palette.common.warning,
      border: 'none',
      '& :hover': {
        color: '#FAFCFF',
        backgroundColor: complete ? '#58147F' : theme.palette.common.warning,
        opacity: '80%',
      },
      '&:hover': {
        backgroundColor: complete ? '#58147F' : theme.palette.common.warning,
        opacity: '80%',
      },
    },
  })
);

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#7E7E7E',
    color: '#FAFCFF',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 6px',
    marginBottom: '2px !important',
  },
}));

export const MissingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  fontSize: '18px',
  color: theme.palette.common.white,
  backgroundColor: '#ADADAD',
  borderRadius: '4px',
  width: '132px',
  height: '32px',
}));

export const UpButton = styled(Button)(({ theme }) => ({
  width: '52px',
  height: '52px',
  minWidth: '50px',
  borderRadius: '50%',
  position: 'sticky',
  float: 'right',
  bottom: '20px',
  margin: '-26px -26px 20px 0',
  border: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '& span': {
    fontSize: '32px',
  },
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.primary.main}`,
    width: '22px',
    fontSize: '16px',
    fontWeight: 600,
    paddingTop: '1px',
    height: '22px',
    top: '4px',
    right: '5px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
