import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  AppraisalInformationData,
  AreaInformationData,
  RegisteredData,
} from '../../../../../../api/workOrders/types';
import { StyledTableCellBody, StyledTableRowBody } from '../../../styles';
import { NumericTextfield } from '../styles';

interface TableAreaProps {
  inputs: AppraisalInformationData;
  setInputs: React.Dispatch<React.SetStateAction<AppraisalInformationData>>;
  readOnly: boolean;
}

export function TableArea({
  inputs,
  setInputs,
  readOnly,
}: TableAreaProps): JSX.Element {
  const handleRegisteredInput = (
    field: keyof RegisteredData,
    value: number | string | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      area_information: {
        ...prev.area_information,
        registered: {
          ...prev.area_information.registered,
          [field]: value,
        },
      },
    }));
  };

  const handleInput = (
    field: keyof AreaInformationData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      area_information: {
        ...prev.area_information,
        [field]: value,
      },
    }));
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: '#E9E1ED',
              border: '1px solid #ccc',
            }}
          >
            <TableCell
              rowSpan={3}
              align="center"
              sx={{ backgroundColor: 'white', border: '1px solid #ccc' }}
            >
              área(m²)
            </TableCell>
            <TableCell
              colSpan={3}
              align="center"
              sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
            >
              área averbada
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              área não averbada
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              total área privativa (averbada + não averbada)
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              área total do imóvel (averbadas + não averbadas)
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              backgroundColor: '#E9E1ED',
              border: '1px solid #ccc',
            }}
          >
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              área privativa
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              área comum (m²)
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              total área averbada
            </TableCell>
          </TableRow>
          <StyledTableRowBody>
            <StyledTableCellBody>
              <NumericTextfield
                allowNegative={false}
                value={inputs.area_information.registered.private}
                onChange={(e) =>
                  handleRegisteredInput('private', parseFloat(e.target.value))
                }
                disabled={readOnly}
                decimalScale={2}
                decimalSeparator=","
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <NumericTextfield
                allowNegative={false}
                value={inputs.area_information.registered.public}
                onChange={(e) =>
                  handleRegisteredInput('public', parseFloat(e.target.value))
                }
                disabled={readOnly}
                decimalScale={2}
                decimalSeparator=","
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <NumericTextfield
                allowNegative={false}
                value={inputs.area_information.registered.total}
                onChange={(e) =>
                  handleRegisteredInput('total', parseFloat(e.target.value))
                }
                disabled={readOnly}
                decimalScale={2}
                decimalSeparator=","
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <NumericTextfield
                allowNegative={false}
                value={inputs.area_information.not_registered}
                onChange={(e) =>
                  handleInput('not_registered', parseFloat(e.target.value))
                }
                disabled={readOnly}
                decimalScale={2}
                decimalSeparator=","
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <NumericTextfield
                allowNegative={false}
                value={inputs.area_information.total_private_area}
                onChange={(e) =>
                  handleInput('total_private_area', parseFloat(e.target.value))
                }
                disabled={readOnly}
                decimalScale={2}
                decimalSeparator=","
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <NumericTextfield
                allowNegative={false}
                value={inputs.area_information.total_area}
                onChange={(e) =>
                  handleInput('total_area', parseFloat(e.target.value))
                }
                disabled={readOnly}
                decimalScale={2}
                decimalSeparator=","
              />
            </StyledTableCellBody>
          </StyledTableRowBody>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
