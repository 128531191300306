import { PlansData } from '../../../../../api/plans/types';
import { Constants } from '../../../../../constants/plans';
import { convertStorageSpace, formatDate } from '../../../../../helpers';
import {
  BoldTypography,
  LeftBox,
  ListItem,
  PlanBox,
  PlanText,
  PlanTitle,
  SubtitleTypography,
} from './styles';

interface CurrentPlanProps {
  plan: PlansData;
}

export default function CurrentPlan({ plan }: CurrentPlanProps): JSX.Element {
  return plan.current_price !== null ? (
    <PlanBox>
      <PlanTitle>
        {Constants.plan}
        {plan.name}
      </PlanTitle>
      <PlanText>
        {plan.subscription_started_at &&
          Constants.startPlanText.replace(
            '**',
            `${formatDate(plan.subscription_started_at)}`
          )}
        {Constants.paymentPlanText
          .replace('**', `${plan.payment_frequency}`)
          .replace(
            '***',
            `${
              plan.charging_details &&
              formatDate(plan.charging_details?.next_payment)
            }`
          )}
      </PlanText>
      <LeftBox>
        <BoldTypography>{Constants.planIncludes}</BoldTypography>
        <ListItem>
          <span>
            {plan.user_count}
            {Constants.users}
          </span>
        </ListItem>
        <ListItem>
          <span>
            {plan.reports_per_month}
            {Constants.reports}
          </span>
        </ListItem>
        <ListItem>
          <span>
            {plan.samples_per_month}
            {Constants.samples}
          </span>
        </ListItem>
        <ListItem>
          <span>
            {plan.storage_space}
            {Constants.space}
          </span>
        </ListItem>
        <SubtitleTypography>
          {Constants.spaceSubtitle.replace(
            '**',
            `${convertStorageSpace(plan.storage_space)}`
          )}
        </SubtitleTypography>
        <ListItem>
          <span>{Constants.access}</span>
        </ListItem>
        <SubtitleTypography>{Constants.accessSubtitle}</SubtitleTypography>
      </LeftBox>
    </PlanBox>
  ) : (
    <PlanBox>
      <PlanTitle>Avaliação gratuita</PlanTitle>
      <PlanText sx={{ fontWeight: '600' }}>
        Você tem{' '}
        <span style={{ color: '#914FB2' }}>{plan.remaining_days} </span>
        dias de licença gratuita ao plano ProtoPro
      </PlanText>
      <LeftBox>
        <BoldTypography>A avaliação gratuita inclui:</BoldTypography>
        <ListItem>
          <span>
            {plan.user_count} {Constants.users}
          </span>
        </ListItem>
        <ListItem>
          <span>
            {plan.reports_per_month} {Constants.reports}
          </span>
        </ListItem>
        <ListItem>
          <span>
            {plan.samples_per_month} {Constants.samples}
          </span>
        </ListItem>
        <ListItem>
          <span>
            {plan.storage_space}
            {Constants.space}
          </span>
        </ListItem>
        <SubtitleTypography>
          {Constants.spaceSubtitle.replace(
            '**',
            `${convertStorageSpace(plan.storage_space)}`
          )}
        </SubtitleTypography>
        <ListItem>
          <span>{Constants.access}</span>
        </ListItem>
        <SubtitleTypography>{Constants.accessSubtitle}</SubtitleTypography>
      </LeftBox>
    </PlanBox>
  );
}
