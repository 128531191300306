export const Constants = {
  reportedErrors: 'erros reportados',
  noreports: 'Não existem erros reportados no momento',
  reportDate: 'data do reporte',
  reportedBy: 'reportado por',
  osNumber: 'número OS',
  client: 'cliente',
  goal: 'objetivo',
  source: 'tipo do erro',
  errorDescription: 'descrição do erro',
  reportedError: 'Erro Reportado',
  creation: 'criação',
  findings: 'observações',
  save: 'salvar alterações',
  finish: 'finalizar',
  revision: 'revisão e aprovação',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  sample: 'Amostra',
  report: 'Sugestão de Cálculo',
  noReport: 'Relatório não pode ser criado',
  pdfReport: 'Relatório',
  element: 'elemento',
  privateArea: 'área privativa',
  landArea: 'área do terreno',
  constructedArea: 'área construída:',
  squareMeters: ' m²',
  parkingLots: 'vagas de garagem',
  propertyType: 'tipo de imóvel',
  cep: 'CEP',
  city: 'cidade',
  district: 'bairro',
  address: 'logradouro',
  number: 'número',
  sellingPrice: 'preço de venda',
  real: 'R$ ',
  propertyLink: 'link do imóvel',
  age: 'idade',
  conservation: 'estado de conservação',
  standard: 'padrão construtivo',
  movePin: 'Mova o pin e salve caso a localização esteja incorreta',
  lat: 'latitude:',
  lng: 'longitude:',
  searchMap: 'pesquisar no mapa',
  pictures: 'Fotos',
  propertyDescription: 'Descrição do imóvel',
  moreImg: 'Mais imagens',
  acceptCheck:
    'Estou ciente que sou responsável pelos dados acima e os declaro como verdadeiros.',
  noSample: 'esta OS não possui amostra',
};
