/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { Box, Checkbox } from '@mui/material';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useLocation } from 'react-router-dom';

import { CoordinateEventData } from '../../../api/workOrders/types';
import { IconLocationOnMS, IconTaskAltMS } from '../../../constants/icons';
import { formatDateAndTime } from '../../../helpers';
import CustomMap from './CustomMap';
import {
  BoxContainer,
  CheckboxFormControl,
  PinText,
  StyledTypography,
} from './styles';

interface StandardMapProps {
  searchMap?: boolean;
  address?: string;
  pinPlace: google.maps.LatLngLiteral;
  setPinPlace?: (place: google.maps.LatLngLiteral) => void;
  lastCoordinateEvent?: CoordinateEventData | null;
  checkLocation?: boolean;
  handleCheckLocation?: (value: boolean) => Promise<void>;
  userCheck?: boolean;
  setUserCheck?: (value: boolean) => void;
  draggable?: boolean;
}

export default function StandardMap({
  searchMap,
  address,
  pinPlace,
  setPinPlace,
  lastCoordinateEvent,
  checkLocation,
  handleCheckLocation,
  userCheck,
  setUserCheck,
  draggable = true,
}: StandardMapProps): JSX.Element {
  const location = useLocation();

  return (
    <BoxContainer id="pin">
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        libraries={['places', 'marker']}
      >
        <CustomMap
          searchMap={searchMap}
          address={address}
          pinPlace={pinPlace}
          setPinPlace={setPinPlace}
          draggable={draggable}
        />
      </APIProvider>
      {setPinPlace && (
        <Box>
          {location.pathname.includes('inspection') && userCheck ? (
            <Box />
          ) : (
            <PinText>Mova o pin caso a localização esteja incorreta</PinText>
          )}
        </Box>
      )}
      {checkLocation && (
        <Box>
          {location.pathname.includes('inspection') && setUserCheck ? (
            <Box display="flex" justifyContent="space-between">
              <CheckboxFormControl
                id="location-check"
                label={
                  userCheck ? 'Localização atualizada' : 'confirmar localização'
                }
                control={
                  <Checkbox
                    color="primary"
                    checked={userCheck}
                    onChange={(event) => {
                      setUserCheck(event.target.checked);
                      if (handleCheckLocation)
                        handleCheckLocation(event.target.checked);
                    }}
                  />
                }
              />
              {!userCheck && (
                <StyledTypography>
                  {IconLocationOnMS}
                  Localização pendente de confirmação
                </StyledTypography>
              )}
            </Box>
          ) : (
            lastCoordinateEvent && (
              <StyledTypography error="true">
                {IconTaskAltMS}
                Localização atualizada por {
                  lastCoordinateEvent?.user.name
                } em: {formatDateAndTime(lastCoordinateEvent?.created_at)}
              </StyledTypography>
            )
          )}
        </Box>
      )}
    </BoxContainer>
  );
}
