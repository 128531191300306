import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';

import { AppraisalInformationData } from '../../../../../api/workOrders/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../../styles';
import {
  conservationOptions,
  constructiveStandardOptions,
  registrationUfOptions,
  reportGoalOptions,
} from '../../utils';

interface ApartmentInputsProps {
  inputs: AppraisalInformationData;
  setInputs: React.Dispatch<React.SetStateAction<AppraisalInformationData>>;
  readOnly: boolean;
}

export function ApartmentInputs({
  inputs,
  setInputs,
  readOnly,
}: ApartmentInputsProps): JSX.Element {
  const handleInputChange = (
    field: keyof AppraisalInformationData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value ?? (typeof prev[field] === 'number' ? 0 : ''),
    }));
  };

  return (
    <>
      <Grid item xs={3}>
        <StyledTextField
          label="número da proposta"
          value={inputs.proposal_number}
          onChange={(e) => handleInputChange('proposal_number', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="número da matrícula"
          value={inputs.subscription_number}
          onChange={(e) =>
            handleInputChange('subscription_number', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="nome do proprietário do imóvel"
          value={inputs.owner_name}
          onChange={(e) => handleInputChange('owner_name', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="uso do imóvel avaliando"
          value={inputs.real_estate_use}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('real_estate_use', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="residencial unifamiliar">
            residencial unifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="residencial multifamiliar">
            residencial multifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="comercial">comercial</StyledMenuItem>
          <StyledMenuItem value="industrial">industrial</StyledMenuItem>
          <StyledMenuItem value="institucional">institucional</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="imóvel habitado anteriormente"
          value={String(inputs.has_been_used_before)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('has_been_used_before', Boolean(e.target.value))
          }
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="qual tipo de ocupação"
          value={inputs.real_estate_use_type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('real_estate_use_type', e.target.value)
          }
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="desocupado">desocupado</StyledMenuItem>
          <StyledMenuItem value="cedido/ comodado">
            cedido / comodado
          </StyledMenuItem>
          <StyledMenuItem value="invadido">invadido</StyledMenuItem>
          <StyledMenuItem value="locado/ arrendado">
            locado / arrendado
          </StyledMenuItem>
          <StyledMenuItem value="ocupado">ocupado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="andar"
          value={inputs.address_floor}
          onChange={(e) => handleInputChange('address_floor', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="logradouro"
          value={inputs.address_street}
          onChange={(e) => handleInputChange('address_street', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="número"
          value={inputs.address_number}
          onChange={(e) => handleInputChange('address_number', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="complemento"
          value={inputs.address_complement}
          onChange={(e) =>
            handleInputChange('address_complement', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="cidade"
          value={inputs.address_city}
          onChange={(e) => handleInputChange('address_city', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="UF"
          value={inputs.address_state}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('address_state', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          {registrationUfOptions().map((uf) => (
            <StyledMenuItem key={uf.value} value={uf.value}>
              {uf.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="latitude"
          value={inputs.latitude}
          onChange={(e) => {
            handleInputChange('latitude', e.target.value);
          }}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="longitude"
          value={inputs.longitude}
          onChange={(e) => handleInputChange('longitude', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="localização"
          value={inputs.location_status_level}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('location_status_level', e.target.value)
          }
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="boa">boa</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
          <StyledMenuItem value="ótima">ótima</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="posição do avaliando no prédio"
          value={inputs.position_into_building}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('position_into_building', e.target.value)
          }
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="frente/ canto">frente / canto</StyledMenuItem>
          <StyledMenuItem value="frente/ meio">frente / meio</StyledMenuItem>
          <StyledMenuItem value="fundos/ meio">fundos / meio</StyledMenuItem>
          <StyledMenuItem value="fundos/ canto">fundos/ canto</StyledMenuItem>
          <StyledMenuItem value="lateral">lateral</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="fechamento das paredes"
          value={inputs.wall_closure}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('wall_closure', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alvenaria">alvenaria</StyledMenuItem>
          <StyledMenuItem value="dry wall">dry wall</StyledMenuItem>
          <StyledMenuItem value="madeira">madeira</StyledMenuItem>
          <StyledMenuItem value="misto">misto</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="nº de pavimentos da unidade"
          value={inputs.number_of_floors}
          onChange={(e) =>
            handleInputChange('number_of_floors', Number(e.target.value))
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="estado de conservação"
          value={inputs.preservation_state}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('preservation_state', e.target.value)
          }
          disabled={readOnly}
        >
          {conservationOptions().map((option) => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="tipo de implantação"
          value={inputs.deployment_type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('deployment_type', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="condomínio">condomínio</StyledMenuItem>
          <StyledMenuItem value="isolado">isolado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="idade aparente do imóvel"
          value={inputs.age}
          onChange={(e) => handleInputChange('age', Number(e.target.value))}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="tipo"
          value={inputs.type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('type', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="convencional">convencional</StyledMenuItem>
          <StyledMenuItem value="cobertura">cobertura</StyledMenuItem>
          <StyledMenuItem value="duplex">duplex</StyledMenuItem>
          <StyledMenuItem value="garden">garden</StyledMenuItem>
          <StyledMenuItem value="kitnet">kitnet</StyledMenuItem>
          <StyledMenuItem value="loft">loft</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="padrão construtivo"
          value={inputs.constructive_standard}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('constructive_standard', e.target.value)
          }
          disabled={readOnly}
        >
          {constructiveStandardOptions().map((option) => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="número de dormitórios"
          value={inputs.number_of_bedrooms}
          onChange={(e) =>
            handleInputChange('number_of_bedrooms', Number(e.target.value))
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="número de suítes"
          value={inputs.number_of_suites}
          onChange={(e) =>
            handleInputChange('number_of_suites', Number(e.target.value))
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="dormitórios + suítes"
          value={inputs.number_of_bedrooms_and_suites}
          onChange={(e) =>
            handleInputChange(
              'number_of_bedrooms_and_suites',
              Number(e.target.value)
            )
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="nº de banheiros sociais (não contar das suítes)"
          value={inputs.number_of_social_bathrooms}
          onChange={(e) =>
            handleInputChange(
              'number_of_social_bathrooms',
              Number(e.target.value)
            )
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="nº banheiros (sociais + suítes)"
          value={inputs.number_of_social_and_suite_bathrooms}
          onChange={(e) =>
            handleInputChange(
              'number_of_social_and_suite_bathrooms',
              Number(e.target.value)
            )
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="abastecimento de água na unidade"
          value={inputs.water_supply}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('water_supply', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="rede de água potável">
            rede de água potável
          </StyledMenuItem>
          <StyledMenuItem value="poço">poço</StyledMenuItem>
          <StyledMenuItem value="não possui">não possui</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="solução de esgoto sanitário"
          value={inputs.sewage_solution}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('sewage_solution', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="rede de esgoto">rede de esgoto</StyledMenuItem>
          <StyledMenuItem value="fossa séptica/ sumidouro">
            fossa séptica/ sumidouro
          </StyledMenuItem>
          <StyledMenuItem value="não possui">não possui</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        <StyledSelect
          select
          label="objetivo da avaliação"
          value={inputs.evaluation_objective}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('evaluation_objective', e.target.value)
          }
          disabled={readOnly}
        >
          {reportGoalOptions().map((option) => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          type="number"
          label="nº lavabos (não deve ser considerado na soma total de banheiros)"
          value={inputs.number_of_lavatories}
          onChange={(e) =>
            handleInputChange('number_of_lavatories', Number(e.target.value))
          }
          disabled={readOnly}
        />
      </Grid>
    </>
  );
}
