/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Tooltip } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { YesOrNo } from '../../../../../api/enumerations';
import {
  ConstructionData,
  ImprovementData,
  ImprovementsData,
} from '../../../../../api/workOrders/types';
import { IconDeleteMS, IconSaveMS } from '../../../../../constants/icons';
import { selectYesOrNo } from '../../../../../constants/selectOptions';
import { SelectTextField } from '../../../../CustomInput';
import {
  improvementsEmptyValues,
  initialImprovementValue,
} from '../../initialValue';
import {
  EditButton,
  OutlinedButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../../styles';
import { Improvement } from './improvement';

interface ImprovementsContructionProps {
  constructionInputs: ConstructionData;
  setConstructionInputs: React.Dispatch<React.SetStateAction<ConstructionData>>;
  improvementsInputs: ImprovementsData;
  setImprovementsInputs: React.Dispatch<React.SetStateAction<ImprovementsData>>;
  initialImprovementsInputs: ImprovementsData | string | undefined;
  doesNotApply: number;
  setDoesNotApply: (value: number) => void;
  handleSection: () => void;
  readOnly: boolean;
  handleUpdateImprovements?: () => void;
  handleUpdateConstruction?: () => void;
}

export function ImprovementsContruction({
  constructionInputs,
  setConstructionInputs,
  doesNotApply,
  setDoesNotApply,
  initialImprovementsInputs,
  improvementsInputs,
  setImprovementsInputs,
  handleSection,
  readOnly,
  handleUpdateImprovements,
  handleUpdateConstruction,
}: ImprovementsContructionProps): JSX.Element {
  const [improvementsIsDisabled, setImprovementsIsDisabled] = useState(false);

  const handleTemporaryChange = (
    updatedItem: ImprovementData,
    index: number
  ): void => {
    const updatedArray = [...improvementsInputs.improvements];
    updatedArray[index] = updatedItem;
    setImprovementsInputs((prev) => ({
      ...prev,
      improvements: updatedArray,
    }));
  };

  const handleAddImprovement = (): void => {
    setImprovementsInputs((prev) => ({
      ...prev,
      improvements: [...prev.improvements, initialImprovementValue],
    }));
  };

  const removeImprovementInputs = (index: number): void => {
    const filteredImprovements = improvementsInputs.improvements.filter(
      (_, i) => i !== index
    );
    setImprovementsInputs((prev) => ({
      ...prev,
      improvements: filteredImprovements,
    }));
  };

  const handleImprovementsChange = (
    field: keyof ImprovementsData,
    value: string | number | boolean
  ): void => {
    setImprovementsInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleConstructionChange = (
    field: keyof ConstructionData,
    value: string | number | boolean
  ): void => {
    setConstructionInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [improvementsInputs, constructionInputs]);

  useEffect(() => {
    if (doesNotApply === YesOrNo.YES) {
      setImprovementsInputs(improvementsEmptyValues);
      setImprovementsIsDisabled(true);
    } else {
      setImprovementsInputs(initialImprovementsInputs as ImprovementsData);
      setImprovementsIsDisabled(false);
    }
    if (readOnly) {
      setImprovementsIsDisabled(true);
    }
  }, [doesNotApply]);

  useEffect(() => {
    if (handleUpdateConstruction) {
      handleUpdateConstruction();
    }
  }, [constructionInputs]);

  useEffect(() => {
    if (handleUpdateImprovements) {
      handleUpdateImprovements();
    }
  }, [doesNotApply, improvementsInputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectTextField
          id=""
          label="não se aplica"
          value={doesNotApply}
          setValue={setDoesNotApply}
          selectOptions={selectYesOrNo()}
          disable={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={improvementsIsDisabled}
          label="estado de conservação imóvel avaliando"
          value={improvementsInputs.appraisal_preservation_state}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleImprovementsChange(
              'appraisal_preservation_state',
              e.target.value
            )
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="bom">bom</StyledMenuItem>
          <StyledMenuItem value="em construção">em construção</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6} />
      {improvementsInputs.improvements.map((item, index) => (
        <Fragment key={item.name}>
          <Improvement
            input={item}
            onBlur={handleTemporaryChange}
            index={index}
            readOnly={improvementsIsDisabled}
          />
          <Grid item xs={1}>
            <OutlinedButton
              disabled={
                improvementsInputs.improvements.length === 1 || readOnly
              }
              onClick={() => removeImprovementInputs(index)}
            >
              {IconDeleteMS}
            </OutlinedButton>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={1}>
        <OutlinedButton
          onClick={handleAddImprovement}
          disabled={
            improvementsIsDisabled ||
            improvementsInputs.improvements.some((item) => item.name === '')
          }
        >
          <Add />
        </OutlinedButton>
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="imóvel ocupado no momento da vistoria"
        >
          <StyledSelect
            select
            label="imóvel ocupado no momento da vistoria"
            value={constructionInputs.appraisal_occupied_during_inspection}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            disabled={readOnly}
            onChange={(e) =>
              handleConstructionChange(
                'appraisal_occupied_during_inspection',
                e.target.value
              )
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="solução esgoto sanitário"
          value={constructionInputs.sewage_solution}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleConstructionChange('sewage_solution', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="rede de esgoto">rede de esgoto</StyledMenuItem>
          <StyledMenuItem value="fossa séptica">fossa séptica</StyledMenuItem>
          <StyledMenuItem value="sumidouro">sumidouro</StyledMenuItem>
          <StyledMenuItem value="não possui">não possui</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="esquadrias"
          value={constructionInputs.framing}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) => handleConstructionChange('framing', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alumínio">alumínio</StyledMenuItem>
          <StyledMenuItem value="ferro">ferro</StyledMenuItem>
          <StyledMenuItem value="madeira">madeira</StyledMenuItem>
          <StyledMenuItem value="PVC">PVC</StyledMenuItem>
          <StyledMenuItem value="vidro temperado">
            vidro temperado
          </StyledMenuItem>
          <StyledMenuItem value="outros">outros</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="área construída/ privativa total (m²)"
          value={constructionInputs.total_constructed_or_private_area}
          disabled={readOnly}
          onChange={(e) =>
            handleConstructionChange(
              'total_constructed_or_private_area',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="solução de abastecimento de água"
          value={constructionInputs.water_supply}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleConstructionChange('water_supply', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="rede de água potável">
            rede de água potável
          </StyledMenuItem>
          <StyledMenuItem value="poço">poço</StyledMenuItem>
          <StyledMenuItem value="não possui">não possui</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="total de vagas"
          value={constructionInputs.number_of_parking_spaces}
          disabled={readOnly}
          onChange={(e) =>
            handleConstructionChange(
              'number_of_parking_spaces',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="área averbada (m²)"
          value={constructionInputs.registered_area}
          disabled={readOnly}
          onChange={(e) =>
            handleConstructionChange('registered_area', Number(e.target.value))
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="área não averbada (m²)"
          value={constructionInputs.not_registered_area}
          disabled={readOnly}
          onChange={(e) =>
            handleConstructionChange(
              'not_registered_area',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          // eslint-disable-next-line max-len
          label="Demais Informações Relevantes (Descrição da divisão interna / benfeitoria, não descritos acima):"
          value={constructionInputs.observations}
          disabled={readOnly}
          onChange={(e) =>
            handleConstructionChange('observations', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
