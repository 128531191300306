import { Grid } from '@mui/material';

import { PropertyType } from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { NumericTextField } from '../../CustomInput';
import { InputTitle, SmallStyledInput } from '../../UI/Typography';
import { Constants } from './constants';

interface PropertyInfoEditProps {
  propertyData: WorkOrderData;
  propertyRooms: number;
  setPropertyRooms: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  suites: number;
  setSuites: (value: number) => void;
}

export function PropertyInfoEdit({
  propertyData,
  propertyRooms,
  setPropertyRooms,
  toilets,
  setToilets,
  suites,
  setSuites,
}: PropertyInfoEditProps): JSX.Element {
  const gridWidth =
    propertyData.real_estate_kind === PropertyType.APARTMENT ? 1.5 : 2;

  return (
    <Grid container spacing={4} className="conservation-state">
      {propertyData.real_estate_kind !== PropertyType.LOT ? (
        <>
          <Grid item xs={gridWidth}>
            <InputTitle>
              {propertyData.real_estate_kind === PropertyType.APARTMENT
                ? Constants.totalArea
                : Constants.landArea}
            </InputTitle>
            <SmallStyledInput>{propertyData.total_area}</SmallStyledInput>
          </Grid>
          <Grid item xs={gridWidth}>
            <InputTitle>
              {propertyData.real_estate_kind === PropertyType.APARTMENT
                ? Constants.privateArea
                : Constants.constructedArea}
            </InputTitle>
            <SmallStyledInput>{propertyData.constructed_area}</SmallStyledInput>
          </Grid>
          {propertyData?.real_estate_kind === PropertyType.APARTMENT && (
            <Grid item xs={gridWidth}>
              <InputTitle>{Constants.totalAreaSum}</InputTitle>
              <SmallStyledInput>
                {propertyData?.total_area_sum}
              </SmallStyledInput>
            </Grid>
          )}
          <Grid item xs={gridWidth}>
            <NumericTextField
              id="rooms"
              label="quartos"
              suffix=" quartos"
              maxLength={12}
              value={propertyRooms}
              setValue={setPropertyRooms}
            />
          </Grid>
          <Grid item xs={gridWidth}>
            <NumericTextField
              id="toilets"
              label="banheiros"
              suffix=" banheiros"
              maxLength={13}
              value={toilets}
              setValue={setToilets}
            />
          </Grid>
          <Grid item xs={gridWidth}>
            <NumericTextField
              id="suites"
              label="suítes"
              suffix=" suítes"
              maxLength={10}
              value={suites}
              setValue={setSuites}
            />
          </Grid>
          <Grid item xs={2}>
            <InputTitle>{Constants.parkingLots}</InputTitle>
            <SmallStyledInput>{propertyData.parking_spaces}</SmallStyledInput>
          </Grid>
        </>
      ) : (
        <Grid item xs={2}>
          <InputTitle>{Constants.landArea}</InputTitle>
          <SmallStyledInput>{propertyData.total_area}</SmallStyledInput>
        </Grid>
      )}
    </Grid>
  );
}
