import { CircularProgress, Grid, Grow } from '@mui/material';

import ConfirmationDialog from '../../../../components/Dialog/ConfirmationDialog';
import { Constants } from '../../../../constants/sampleCreation';
import { CancelButton, SelectButton } from './styles';

interface DeleteElementsProps {
  activatesElementSelection: boolean;
  setActivatesElementSelection: (value: boolean) => void;
  setSelectedIcons: (value: number[]) => void;
  loading: boolean;
  modalCallback: () => Promise<void>;
}

export function DeleteElements({
  activatesElementSelection,
  setActivatesElementSelection,
  setSelectedIcons,
  loading,
  modalCallback,
}: DeleteElementsProps): JSX.Element {
  return (
    <Grid item container justifyContent="end" marginTop="8px">
      <Grow
        in={activatesElementSelection}
        style={{ transformOrigin: '0 0 0' }}
        {...(activatesElementSelection ? { timeout: 1000 } : {})}
      >
        <CancelButton
          onClick={() => {
            setActivatesElementSelection(false);
            setSelectedIcons([]);
          }}
        >
          {Constants.cancel}
        </CancelButton>
      </Grow>
      {activatesElementSelection ? (
        <ConfirmationDialog
          text={Constants.deleteElements}
          button={
            <SelectButton
              className="selected"
              disabled={loading}
              onClick={() => setActivatesElementSelection(true)}
            >
              {loading ? (
                <CircularProgress size={22} />
              ) : (
                Constants.elementsSelectedDeletion
              )}
            </SelectButton>
          }
          modalCallback={modalCallback}
        />
      ) : (
        <SelectButton onClick={() => setActivatesElementSelection(true)}>
          {Constants.selectingElements}
        </SelectButton>
      )}
    </Grid>
  );
}
