/* eslint-disable max-len */
export const Constants = {
  inspection: 'Vistoria',
  os: 'número OS:',
  clientData: 'Dados do cliente',
  clientName: 'nome do cliente:',
  cancelOs: 'cancelar OS',
  downloadImages: 'baixar imagens dos cômodos',
  deleteImages: 'excluir imagens dos cômodos',
  deleteImagesText: 'excluir todas as imagens e cômodos desta vistoria',
  cancelOsText: 'cancelar esta OS',
  solicitor: 'solicitante',
  clientType: 'tipo do cliente:',
  evaluationType: 'tipo do cálculo:',
  goal: 'objetivo:',
  function: 'finalidade:',
  propertyUse: 'uso do imóvel:',
  propertyData: 'Dados do imóvel',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  searchMap: 'pesquisar no mapa',
  lat: 'Latitude: ',
  lng: 'Longitude:',
  movePin: 'Mova o pin caso a localização esteja incorreta',
  propertyPhotos: 'Imagens do imóvel',
  approve: 'aprovar',
  addPhotos: 'Adicionar fotos do imóvel:',
  dragText: 'solte os arquivos aqui',
  uploadText: 'arraste e solte os arquivos para adicioná-los',
  fileType: '(JPG, GIF e PNG somente)',
  selectPhotos: 'selecionar fotos',
  dividerTitle: 'Separamos umas dicas para ajudar com as fotos:',
  photoGalley: 'Galeria de fotos',
  morePhotos: 'selecionar mais fotos',
  sendPhotos: 'enviar fotos para análise',
  pept: 'enviar para PEPT',
  rooms: 'Cômodos',
  addRoom: 'adicionar cômodo',
  newRoom: 'novo cômodo',
  add: 'adicionar',
  form: 'Formulário',
  noPhotos: 'esta OS não possui imagens',
  selectQuestion: 'selecione uma pergunta ao lado',
  noForm: 'esta OS não possui formulário',
  required: '*obrigatória',
  ok: 'ok',
  delete: 'deletar',
  deleteRoomText: 'Você gostaria de deletar este cômodo?',
  no: 'Não',
  yes: 'Sim',
  deleteRoomAlert: 'Atenção! Esta ação não poderá ser desfeita.',
  editRoom: 'Editar cômodo',
  save: 'salvar',
  addFacade: 'adicionar fachada',
  changeStatus: 'alterar o status da OS ** de Vistoria para Criação de Amostra',
  previousStatus: 'voltar status',
  previousStatusName: 'Agendamento',
  previousStatusText: 'alterar o status da OS ** de Vistoria para Agendamento',
  confirmEdit: 'confirmar alterações',
  photoAndPathologies: 'esta foto e suas patologias',
  previousStatusWarning:
    'Atenção: ao retornar a OS para Agendamento, todos os dados da vistoria serão excluídos.',
};
export const InspectionTips = [
  {
    title: 'Retire objetos pessoais',
    text: 'Retire todos os objetos pessoais para que o espaço fique impessoal.',
  },
  {
    title: 'Capriche na organização',
    text: 'Prepare o ambiente, faça uma limpeza nos espaços, e foque nos detalhes.Isso pode fazer toda diferença!',
  },
  {
    title: 'Observe a iluminação',
    text: 'O ideal é aproveitar a luz natural para as fotos ficarem mais fiéis. Tente se organizar para fotografar no começo da manhã ou no fim da tarde, situações em que o sol não está forte.',
  },
  {
    title: 'Acerte o ângulo',
    text: 'A regra de ouro é simular a visão das pessoas, a posição ideal da câmera para fotos de ambientes inteiros é aquela que está na altura dos olhos.',
  },
  {
    title: 'Exagere nos cliques',
    text: 'Quanto mais fotos do imóvel, mais preciso será a análise. Em vez de 5, que tal apresentar 20 fotos? Se for necesasário, você poderá adicionar detalhes da patologia também.',
  },
  {
    title: 'Prefira a horizontal',
    text: 'Pensando no melhor aproveitamento dos espaços e em formas de mostrar mais detalhes do imóvel, procure sempre fotografar na horizontal - modo paisagem.',
  },
];
