/* eslint-disable react/require-default-props */
import React from 'react';

import useGeneral from '../../../hooks/useGeneral';
import { FilledButton, OutlinedButton } from '../../UI/Button';
import { StyledDialog } from '../../UI/Dialog';
import { WhiteCircularProgress } from '../../UI/Typography';
import {
  AlertTypography,
  ButtonBox,
  DialogContainer,
  ErrorMessage,
  TextTypography,
} from './styles';

interface ConfirmationDialogProps {
  id?: string;
  title?: string;
  text: string;
  isDisabled?: boolean;
  entrance?: boolean;
  button?: JSX.Element;
  model?: 'error';
  modalCallback: () => void;
  loading?: boolean;
  errorMessage?: string;
  width?: 'sm' | 'ms' | 'md' | 'lg';
}

export default function ConfirmationDialog({
  id,
  title,
  text,
  isDisabled,
  entrance,
  button,
  model,
  modalCallback,
  loading,
  errorMessage,
  width = 'md',
}: ConfirmationDialogProps): JSX.Element {
  const dialog = useGeneral();

  const handleConfirm = (e: React.MouseEvent): void => {
    e.stopPropagation();

    modalCallback();
    dialog.handleClose();
  };

  return (
    <div id={id}>
      {button ? (
        React.cloneElement(button, {
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
            dialog.handleOpen();
          },
        })
      ) : (
        <FilledButton
          width={width}
          onClick={dialog.handleOpen}
          disabled={isDisabled || loading}
        >
          {loading ? <WhiteCircularProgress size={22} /> : title}
        </FilledButton>
      )}
      <StyledDialog
        open={dialog.open}
        onClose={dialog.handleClose}
        aria-labelledby="confirmation dialog"
      >
        <DialogContainer>
          <TextTypography>Você gostaria de {text}?</TextTypography>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ButtonBox>
            <OutlinedButton
              width="sm"
              model={model}
              onClick={(e) => {
                e.stopPropagation();
                dialog.handleClose();
              }}
            >
              Não
            </OutlinedButton>
            {entrance ? (
              <FilledButton
                onClick={handleConfirm}
                form="entrance"
                type="submit"
                width="sm"
              >
                Sim
              </FilledButton>
            ) : (
              <FilledButton width="sm" model={model} onClick={handleConfirm}>
                Sim
              </FilledButton>
            )}
          </ButtonBox>
          <AlertTypography>
            Atenção! Esta ação não poderá ser desfeita.
          </AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </div>
  );
}
