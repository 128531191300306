/* eslint-disable complexity */
/* eslint-disable max-len */
import {
  convertConservationState,
  convertRegistrationUf,
  convertReportGoal,
  convertStandard,
} from '../../../api/enumerations';
import {
  AppraisalInformationData,
  AvmFactorsData,
  AvmInferenceData,
  CondominiumInformationProps,
  ConstructionData,
  DiagnosisData,
  EvaluationMethodologyData,
  EvaluationResultData,
  EvaluationSummaryData,
  GeneralConditionsData,
  HypothesisTestsData,
  ImprovementData,
  ImprovementsData,
  InspectionInfoData,
  LandData,
  MicroregionData,
  ParkingApartmentData,
  ParkingHouseData,
  StatisticalReportData,
  VariablesData,
  WarrantyData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import {
  convertMethodology,
  isValueGreaterThan,
  isValueLessThan,
  sumBedroomsAndSuites,
  sumSocialAndSuites,
  verifyPrecisionLevel,
} from './utils';

export const methodologyInitialValues = (
  data: EvaluationMethodologyData | undefined,
  propertyData: WorkOrderData,
  isFactor: boolean
): EvaluationMethodologyData => {
  const { avm_report: avmReport, task_id: taskId } = propertyData;

  if (isFactor) {
    const avmFactorData = avmReport as AvmFactorsData;
    return {
      type: data?.type || convertMethodology(propertyData.evaluation_type),
      methodology: data?.methodology || '',
      methodology_name: data?.methodology_name || '',
      f_calculus_less_than_20: data?.f_calculus_less_than_20 || undefined,
      reasoning_level:
        data?.reasoning_level ||
        (taskId && avmFactorData?.scores.reasoning_level) ||
        '',
      precision_level:
        data?.precision_level ||
        (taskId &&
          verifyPrecisionLevel(
            avmFactorData?.aggregated_homogenization.precision_level
          )) ||
        '',
      determination_coefficient_less_than_0_64:
        data?.determination_coefficient_less_than_0_64 || undefined,
      number_of_independent_variables:
        data?.number_of_independent_variables || 0,
      number_of_data:
        data?.number_of_data ||
        (taskId && avmFactorData?.utilized_samples_ids.length) ||
        0,
      samples_reference_research_start_date:
        data?.samples_reference_research_start_date || '',
      samples_reference_research_end_date:
        data?.samples_reference_research_end_date || '',
      have_variable_with_significance_level_greater_than_30_percent:
        data?.have_variable_with_significance_level_greater_than_30_percent ||
        undefined,
      have_extrapolated_variable: data?.have_extrapolated_variable || undefined,
      have_micronumerosity: data?.have_micronumerosity || undefined,
      have_outliers: data?.have_outliers || undefined,
      is_value_from_central_estimate:
        data?.is_value_from_central_estimate || undefined,
      justification: data?.justification || '',
      model_equation: data?.model_equation || '',
      observations: data?.observations || '',
    };
  }

  const avmInferenceData = avmReport as AvmInferenceData;
  return {
    type: data?.type || convertMethodology(propertyData.evaluation_type),
    methodology: data?.methodology || '',
    methodology_name: data?.methodology_name || '',
    f_calculus_less_than_20:
      data?.f_calculus_less_than_20 ||
      (taskId && isValueLessThan(avmInferenceData?.f, 20)) ||
      undefined,
    reasoning_level:
      data?.reasoning_level ||
      (taskId && avmInferenceData?.scores.reasoning_level) ||
      '',
    precision_level:
      data?.precision_level ||
      (taskId && avmInferenceData?.scores.precision_level) ||
      '',
    determination_coefficient_less_than_0_64:
      data?.determination_coefficient_less_than_0_64 ||
      (taskId && isValueLessThan(avmInferenceData?.rsquared, 0.64)) ||
      undefined,
    number_of_independent_variables:
      data?.number_of_independent_variables ||
      (taskId && avmInferenceData?.model_total_variables) ||
      0,
    number_of_data:
      data?.number_of_data ||
      (taskId && avmInferenceData?.model_data_count) ||
      0,
    samples_reference_research_start_date:
      data?.samples_reference_research_start_date || '',
    samples_reference_research_end_date:
      data?.samples_reference_research_end_date || '',
    have_variable_with_significance_level_greater_than_30_percent:
      data?.have_variable_with_significance_level_greater_than_30_percent ||
      (taskId &&
        isValueGreaterThan(avmInferenceData?.model_significance_pct, 30)) ||
      undefined,
    have_extrapolated_variable: data?.have_extrapolated_variable || undefined,
    have_micronumerosity: data?.have_micronumerosity || undefined,
    have_outliers: data?.have_outliers || undefined,
    is_value_from_central_estimate:
      data?.is_value_from_central_estimate || undefined,
    justification: data?.justification || '',
    model_equation: data?.model_equation || '',
    observations: data?.observations || '',
  };
};

export const initialVariableValue = {
  appraisal_attribute: '',
  description: '',
  growth: '',
  name: '',
  sample_extent: '',
  significance_level: 0,
  type: '',
};

export const variablesInitialValues = (
  data: VariablesData[]
): VariablesData[] => {
  return data.map((obj) => ({
    ...initialVariableValue,
    ...obj,
  }));
};

export const variablesFromAvmReport = (
  data: HypothesisTestsData[]
): VariablesData[] => {
  const variables = data.map((obj) => ({
    appraisal_attribute: '',
    description: '',
    growth: '',
    name: obj.variable,
    sample_extent: '',
    significance_level: obj.significance,
    type: '',
  }));

  return variables;
};

export const evaluationSummaryInitialValues = (
  data: EvaluationSummaryData | undefined,
  propertyData: WorkOrderData
): EvaluationSummaryData => {
  const { avm_report: avmReport, task_id: taskId } = propertyData;
  return {
    value: data?.value || 0,
    basic_unitary_cost_calculus_reasoning:
      data?.basic_unitary_cost_calculus_reasoning || '',
    reasoning_level:
      data?.reasoning_level ||
      (taskId && avmReport?.scores?.reasoning_level) ||
      '',
    bdi_reasoning: data?.bdi_reasoning || '',
    bdi_reasoning_observations: data?.bdi_reasoning_observations || '',
    bdi_percentage: data?.bdi_percentage || 0,
    depreciated_improvements_value: data?.depreciated_improvements_value || 0,
    commercialization_factor: data?.commercialization_factor || '',
    evolutionary_method_evaluation_result:
      data?.evolutionary_method_evaluation_result || '',
    observations: data?.observations || '',
  };
};

export const evaluationResultInitialValues = (
  data: EvaluationResultData | undefined,
  propertyData: WorkOrderData,
  isFactor: boolean
): EvaluationResultData => {
  const { avm_report: avmReport, task_id: taskId } = propertyData;
  if (isFactor) {
    const avmFactorData = avmReport as AvmFactorsData;
    return {
      assigned_appraisal_value:
        data?.assigned_appraisal_value ||
        (taskId && avmFactorData?.aggregated_homogenization.market_value) ||
        0,
      interval_result_lower_bound:
        data?.interval_result_lower_bound ||
        (taskId &&
          avmFactorData?.aggregated_homogenization.lower_limit.toString()) ||
        '',
      interval_result_upper_bound:
        data?.interval_result_upper_bound ||
        (taskId &&
          avmFactorData?.aggregated_homogenization.upper_limit.toString()) ||
        '',
      maximum_imediate_liquidation_value_or_minimum_term:
        data?.maximum_imediate_liquidation_value_or_minimum_term || '',
      maximum_term_in_months: data?.maximum_term_in_months || 0,
      minimum_imediate_liquidation_value_or_maximum_term:
        data?.minimum_imediate_liquidation_value_or_maximum_term || '',
      minimum_term_in_months: data?.minimum_term_in_months || 0,
      objective: data?.objective || '',
      reference_area_in_squared_meters:
        data?.reference_area_in_squared_meters ||
        (taskId && avmFactorData?.aggregated_homogenization.area) ||
        0,
      total_market_value:
        data?.total_market_value ||
        (taskId &&
          avmFactorData?.aggregated_homogenization.rounded_market_value) ||
        0,
      unuse_mean_value_reasoning: data?.unuse_mean_value_reasoning || '',
      use_mean_value: data?.use_mean_value || '',
      used_unitary_value:
        data?.used_unitary_value ||
        (taskId &&
          avmFactorData?.aggregated_homogenization.sanitized_mean.toString()) ||
        '',
    };
  }
  const avmInferenceData = avmReport as AvmInferenceData;
  return {
    assigned_appraisal_value:
      data?.assigned_appraisal_value ||
      (taskId && avmInferenceData?.mean_total_value_estimate) ||
      0,
    interval_result_lower_bound:
      data?.interval_result_lower_bound ||
      (taskId && avmInferenceData?.estimated_value_lower.toString()) ||
      '',
    interval_result_upper_bound:
      data?.interval_result_upper_bound ||
      (taskId && avmInferenceData?.estimated_value_upper.toString()) ||
      '',
    maximum_imediate_liquidation_value_or_minimum_term:
      data?.maximum_imediate_liquidation_value_or_minimum_term || '',
    maximum_term_in_months: data?.maximum_term_in_months || 0,
    minimum_imediate_liquidation_value_or_maximum_term:
      data?.minimum_imediate_liquidation_value_or_maximum_term || '',
    minimum_term_in_months: data?.minimum_term_in_months || 0,
    objective: data?.objective || '',
    reference_area_in_squared_meters:
      data?.reference_area_in_squared_meters || propertyData.total_area || 0,
    total_market_value:
      data?.total_market_value ||
      (taskId && avmInferenceData?.rounded_market_value) ||
      0,
    unuse_mean_value_reasoning: data?.unuse_mean_value_reasoning || '',
    use_mean_value: data?.use_mean_value || '',
    used_unitary_value:
      data?.used_unitary_value ||
      (taskId && avmInferenceData?.estimated_value_mean.toString()) ||
      '',
  };
};

export const initialParkingApartmentValue = {
  linked_spots: {
    covered: 0,
    not_covered: 0,
    total_covered_and_not_covered: 0,
    free: 0,
    locked: 0,
    total_free_and_locked: 0,
  },
  independent_spots: {
    covered: 0,
    not_covered: 0,
    total_covered_and_not_covered: 0,
    free: 0,
    locked: 0,
    total_free_and_locked: 0,
  },
  total_public_spots: 0,
  total_private_spots: 0,
};

export const initialParkingHouseValue = {
  covered: 0,
  not_covered: 0,
  total: 0,
};

export const checkParking = (
  propertyType: string,
  appraisalInfo: ParkingApartmentData | ParkingHouseData | undefined
): ParkingApartmentData | ParkingHouseData => {
  if (propertyType === 'casa') {
    const parking = appraisalInfo as ParkingHouseData;
    if (parking && parking.covered) {
      return parking;
    }
    return initialParkingHouseValue;
  }
  const parking = appraisalInfo as ParkingApartmentData;
  if (parking && parking.total_public_spots) {
    return parking;
  }
  return initialParkingApartmentValue;
};

export const appraisalInitialValues = (
  propertyType: string,
  appraisalInfo: AppraisalInformationData | undefined,
  propertyData: WorkOrderData
): AppraisalInformationData => {
  return {
    address_city: appraisalInfo?.address_city || propertyData.city || '',
    address_complement:
      appraisalInfo?.address_complement ||
      propertyData.address_complement ||
      '',
    address_floor: appraisalInfo?.address_floor || '',
    address_number: appraisalInfo?.address_number || propertyData.number || '',
    address_state:
      appraisalInfo?.address_state ||
      convertRegistrationUf(propertyData.uf) ||
      '',
    address_street: appraisalInfo?.address_street || propertyData.street || '',
    age: appraisalInfo?.age || propertyData.age || 0,
    area_information: appraisalInfo?.area_information || {
      registered: {
        private: propertyData.constructed_area || 0,
        public: 0,
        total: 0,
      },
      not_registered: 0,
      total_private_area: 0,
      total_area: 0,
    },
    constructive_standard:
      appraisalInfo?.constructive_standard ||
      (propertyData.constructive_standard &&
        convertStandard(propertyData.constructive_standard)) ||
      '',
    deployment_type: appraisalInfo?.deployment_type || '',
    evaluation_objective:
      appraisalInfo?.evaluation_objective ||
      convertReportGoal(propertyData.report_goal) ||
      '',
    evaluation_purpose: appraisalInfo?.evaluation_purpose || '',
    framing: appraisalInfo?.framing || '',
    has_been_used_before: appraisalInfo?.has_been_used_before || true,
    infrastructure_services_and_equipments:
      appraisalInfo?.infrastructure_services_and_equipments || [],
    internal_division: appraisalInfo?.internal_division || [
      {
        room_type: '',
        quantity: 0,
      },
    ],
    latitude: appraisalInfo?.latitude || Number(propertyData.latitude) || 0,
    location_status_level: appraisalInfo?.location_status_level || '',
    longitude: appraisalInfo?.longitude || Number(propertyData.latitude) || 0,
    number_of_bedrooms_and_suites:
      appraisalInfo?.number_of_bedrooms_and_suites ||
      sumBedroomsAndSuites(propertyData.bedrooms, propertyData.suites),
    number_of_bedrooms:
      appraisalInfo?.number_of_bedrooms || propertyData.bedrooms || 0,
    number_of_floors: appraisalInfo?.number_of_floors || 0,
    number_of_lavatories: appraisalInfo?.number_of_lavatories || 0,
    number_of_social_and_suite_bathrooms:
      appraisalInfo?.number_of_social_and_suite_bathrooms ||
      sumSocialAndSuites(propertyData.bathrooms, propertyData.suites) ||
      0,
    number_of_social_bathrooms:
      appraisalInfo?.number_of_social_bathrooms || propertyData.bathrooms || 0,
    number_of_suites:
      appraisalInfo?.number_of_suites || propertyData.suites || 0,
    owner_name: appraisalInfo?.owner_name || propertyData.client_name || '',
    parking_information: checkParking(
      propertyType,
      appraisalInfo?.parking_information
    ),
    position_into_building: appraisalInfo?.position_into_building || '',
    position_into_land: appraisalInfo?.position_into_land || '',
    preservation_state:
      appraisalInfo?.preservation_state ||
      (propertyData.preservation_state &&
        convertConservationState(propertyData.preservation_state)) ||
      '',
    proposal_number: appraisalInfo?.proposal_number || '',
    real_estate_type: propertyType,
    real_estate_use_type: appraisalInfo?.real_estate_use_type || '',
    real_estate_use: appraisalInfo?.real_estate_use || '',
    roof_covering: appraisalInfo?.roof_covering || '',
    sewage_solution: appraisalInfo?.sewage_solution || '',
    subscription_number:
      appraisalInfo?.subscription_number ||
      propertyData.registration_number ||
      '',
    type: appraisalInfo?.type || '',
    wall_closure: appraisalInfo?.wall_closure || '',
    water_supply: appraisalInfo?.water_supply || '',
  };
};

export const microregionInitialValues = (
  data: MicroregionData | undefined
): MicroregionData => {
  return {
    access_status_level: data?.access_status_level || '',
    constructive_standard: data?.constructive_standard || '',
    has_shallow_water_table_flooding_or_risk_zone_classification:
      data?.has_shallow_water_table_flooding_or_risk_zone_classification || '',
    infrastructure_components: data?.infrastructure_components || [],
    observations: data?.observations || '',
    other_public_services: data?.other_public_services || '',
    public_services: data?.public_services || [],
    region_predominant_use: data?.region_predominant_use || '',
  };
};

export const landInitialValues = (
  data: LandData | string | undefined
): LandData => {
  const isObject = typeof data === 'object' && data !== null;

  return {
    allowed_uses_by_zoning: isObject ? data.allowed_uses_by_zoning || '' : '',
    back: isObject ? data.back || '' : '',
    front: isObject ? data.front || '' : '',
    greide_quota: isObject ? data.greide_quota || '' : '',
    ideal_fraction: isObject ? data.ideal_fraction || 0 : 0,
    implantation_type: isObject ? data.implantation_type || '' : '',
    land_fencing_type: isObject ? data.land_fencing_type || '' : '',
    land_position: isObject ? data.land_position || '' : '',
    maximum_allowed_occupation_rate_percentage: isObject
      ? data.maximum_allowed_occupation_rate_percentage || 0
      : 0,
    maximum_height_allowed: isObject ? data.maximum_height_allowed || 0 : 0,
    number_of_fronts: isObject ? data.number_of_fronts || 0 : 0,
    observations: isObject ? data.observations || '' : '',
    restriction_fraction_level: isObject
      ? data.restriction_fraction_level || ''
      : '',
    shape: isObject ? data.shape || '' : '',
    side: isObject ? data.side || '' : '',
    surface_draining: isObject ? data.surface_draining || '' : '',
    testada_front: isObject ? data.testada_front || '' : '',
    topography: isObject ? data.topography || '' : '',
    total_area_in_squared_meters: isObject
      ? data.total_area_in_squared_meters || 0
      : 0,
    utilization_coefficient_percentage: isObject
      ? data.utilization_coefficient_percentage || 0
      : 0,
    zoning: isObject ? data.zoning || '' : '',
  };
};

export const condominiumInitialValues = (
  propertyType: string,
  condo: CondominiumInformationProps | string | undefined
): CondominiumInformationProps => {
  const isObject = typeof condo === 'object' && condo !== null;

  return {
    age: isObject ? condo.age || 0 : 0,
    appraisal_position: isObject ? condo.appraisal_position || '' : '',
    area: isObject ? condo.area || 0 : 0,
    finishing_standard: isObject ? condo.finishing_standard || '' : '',
    fire_protection: isObject ? condo.fire_protection || [] : [],
    framing: isObject ? condo.framing || '' : '',
    infrastructure_services_and_equipments_components: isObject
      ? condo.infrastructure_services_and_equipments_components || []
      : [],
    location_status_level: isObject ? condo.location_status_level || '' : '',
    main_facade_components: isObject ? condo.main_facade_components || [] : [],
    number_of_blocks: isObject ? condo.number_of_blocks || 0 : 0,
    number_of_elevators: isObject ? condo.number_of_elevators || 0 : 0,
    number_of_floors: isObject ? condo.number_of_floors || 0 : 0,
    number_of_units_per_floor: isObject
      ? condo.number_of_units_per_floor || 0
      : 0,
    number_of_units: isObject ? condo.number_of_units || 0 : 0,
    other_fire_protections: isObject ? condo.other_fire_protections || '' : '',
    preservation_state: isObject ? condo.preservation_state || '' : '',
    type: isObject ? condo.type || propertyType : '',
    use_type: isObject ? condo.use_type || '' : '',
    wall_covering: isObject ? condo.wall_covering || '' : '',
  };
};

export const constructionInitialValues = (
  data: ConstructionData | undefined
): ConstructionData => {
  return {
    appraisal_occupied_during_inspection:
      data?.appraisal_occupied_during_inspection || '',
    framing: data?.framing || '',
    not_registered_area: data?.not_registered_area || 0,
    number_of_parking_spaces: data?.number_of_parking_spaces || 0,
    observations: data?.observations || '',
    registered_area: data?.registered_area || 0,
    sewage_solution: data?.sewage_solution || '',
    total_constructed_or_private_area:
      data?.total_constructed_or_private_area || 0,
    water_supply: data?.water_supply || '',
  };
};

export const improvementsEmptyValues = {
  appraisal_preservation_state: '',
  improvements: [
    {
      name: '',
      area: 0,
      finishing_standard: '',
      age: 0,
      preservation_state: '',
      observations: '',
    },
  ],
};

export const initialImprovementValue = {
  name: '',
  area: 0,
  finishing_standard: '',
  age: 0,
  preservation_state: '',
  observations: '',
};

export const improvementsInitialValues = (
  data: ImprovementData[]
): ImprovementData[] => {
  return data.map((obj) => ({
    ...initialImprovementValue,
    ...obj,
  }));
};

export const improvementsInfoInitialValues = (
  data: ImprovementsData | string | undefined
): ImprovementsData => {
  const isObject = typeof data === 'object' && data !== null;

  return {
    appraisal_preservation_state: isObject
      ? data.appraisal_preservation_state || ''
      : '',
    improvements:
      isObject && data.improvements.length > 0
        ? improvementsInitialValues(data.improvements)
        : [initialImprovementValue],
  };
};

export const diagnosisInitialValues = (
  data: DiagnosisData | undefined
): DiagnosisData => {
  return {
    effective_sell_deadline_in_months:
      data?.effective_sell_deadline_in_months || 0,
    independent_unities_complementary_information:
      data?.independent_unities_complementary_information || '',
    liquidity: data?.liquidity || '',
    market_perfomance: data?.market_perfomance || '',
    market_uptake: data?.market_uptake || '',
    negatiation_facilitators: data?.negatiation_facilitators || '',
    number_of_offers_status_level: data?.number_of_offers_status_level || '',
    observations: data?.observations || '',
    target_audience: data?.target_audience || '',
  };
};

export const generalInitialValues = (
  data: GeneralConditionsData | undefined
): GeneralConditionsData => {
  return {
    affected_by_environmental_climatic_or_localization_factors:
      data?.affected_by_environmental_climatic_or_localization_factors || '',
    constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H:
      data?.constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H ||
      '',
    construction_defects_reason: data?.construction_defects_reason || '',
    construction_defects: data?.construction_defects || '',
    depreciative_factors: data?.depreciative_factors || [],
    documents_information_matches_with_inspection:
      data?.documents_information_matches_with_inspection || '',
    instability_conditions_reason: data?.instability_conditions_reason || '',
    liveability_conditions: data?.liveability_conditions || '',
    other_depreciative_factors: data?.other_depreciative_factors || '',
    other_valuing_factors: data?.other_valuing_factors || '',
    stability_conditions: data?.stability_conditions || '',
    unlivability_reason: data?.unlivability_reason || '',
    unmatched_information_reason: data?.unmatched_information_reason || '',
    valuing_factors: data?.valuing_factors || [],
  };
};

export const inspectionInitialValues = (
  data: InspectionInfoData | undefined,
  propertyData: WorkOrderData
): InspectionInfoData => {
  return {
    contact_date: data?.contact_date || '',
    contact_name: data?.contact_name || '',
    contact_phone: data?.contact_phone || '',
    inspection_date: data?.inspection_date || '',
    inspection_end_time: data?.inspection_end_time || '',
    inspection_schedule_date:
      data?.inspection_schedule_date ||
      propertyData.inspection?.schedule_at ||
      '',
    inspection_schedule_time:
      data?.inspection_schedule_time || propertyData.inspection?.start_at || '',
    inspection_start_time: data?.inspection_start_time || '',
    unsuccessful_inspection_history:
      data?.unsuccessful_inspection_history || '',
    witness_name: data?.witness_name || '',
    witness_phone: data?.witness_phone || '',
  };
};

export const warrantyInitialValues = (
  data: WarrantyData | undefined
): WarrantyData => {
  return {
    can_be_accepted: data?.can_be_accepted || '',
    unaccepted_reason: data?.unaccepted_reason || '',
  };
};

export const independentInitialValues = (data: string | undefined): string => {
  return data || '';
};

export const observationsInitialValues = (data: string | undefined): string => {
  return data || '';
};

export const statisticalReportValues = (
  propertyType: string,
  data: StatisticalReportData | null,
  propertyData: WorkOrderData,
  isFactor: boolean
): StatisticalReportData => {
  const handleVariablesInitialValues = (): VariablesData[] => {
    if (data?.variables && data.variables.length > 0) {
      return variablesInitialValues(data.variables);
    }
    if (propertyData.avm_report && !isFactor) {
      const avmInferenceData = propertyData.avm_report as AvmInferenceData;
      return variablesFromAvmReport(avmInferenceData.hypothesis_tests);
    }
    return [initialVariableValue];
  };
  return {
    evaluation_methodology: methodologyInitialValues(
      data?.evaluation_methodology,
      propertyData,
      isFactor
    ),
    variables: handleVariablesInitialValues(),
    evaluation_summary: evaluationSummaryInitialValues(
      data?.evaluation_summary,
      propertyData
    ),
    evaluation_result: evaluationResultInitialValues(
      data?.evaluation_result,
      propertyData,
      isFactor
    ),
    appraisal_information: appraisalInitialValues(
      propertyType,
      data?.appraisal_information,
      propertyData
    ),
    appraisal_microregion_information: microregionInitialValues(
      data?.appraisal_microregion_information
    ),
    land_information: landInitialValues(data?.land_information),
    condominium_information: condominiumInitialValues(
      propertyType,
      data?.condominium_information
    ),
    improvements_information: improvementsInfoInitialValues(
      data?.improvements_information
    ),
    construction_information: constructionInitialValues(
      data?.construction_information
    ),
    market_diagnosis: diagnosisInitialValues(data?.market_diagnosis),
    general_conditions: generalInitialValues(data?.general_conditions),
    inspection_information: inspectionInitialValues(
      data?.inspection_information,
      propertyData
    ),
    warranty_statements: warrantyInitialValues(data?.warranty_statements),
    independent_unities_complementary_information: independentInitialValues(
      data?.independent_unities_complementary_information
    ),
    observations: observationsInitialValues(data?.observations),
  };
};
